import { Router } from '@angular/router';
import { LoggerService } from 'farmcloud-core';
import { AuthStoreService } from './services/auth-store.service';
import { UserContextService } from './user-context.service';
var IdpOrganizationGuard = /** @class */ (function () {
    function IdpOrganizationGuard(router, authStore, logger, userContextService) {
        this.router = router;
        this.authStore = authStore;
        this.logger = logger;
        this.userContextService = userContextService;
    }
    IdpOrganizationGuard.prototype.canActivate = function (route, state) {
        return this.getOrganizationGuardResult(route, state);
    };
    IdpOrganizationGuard.prototype.canActivateChild = function (childRoute, state) {
        return this.getOrganizationGuardResult(childRoute, state);
    };
    IdpOrganizationGuard.prototype.getOrganizationGuardResult = function (route, state) {
        var requiredPermission = route.data;
        if (this.userContextService.currentOrganizationId != null) {
            // check access if user context is populated...
            return this.checkPermissions(requiredPermission);
        }
        else {
            // ...else redirect to `load-user-data` which attempts to
            // fetch user context data, provided that user is logged in
            return this.router.createUrlTree(['/load-user-data'], {
                queryParams: {
                    returnUrl: state.url,
                },
                skipLocationChange: true,
            });
        }
    };
    IdpOrganizationGuard.prototype.checkPermissions = function (requiredPermission) {
        if (!requiredPermission.componentKey) {
            // if there are no requirements defined
            // assume that the route can be freely accessed
            return true;
        }
        var userClaims = this.authStore.getUserClaims;
        if (!userClaims || userClaims.length == 0) {
            var message = '[IdpOrganizationGuard] No groups defined in user claims.';
            this.logger.trackException(new Error(message));
            return false;
        }
        return requiredPermission.permissions.some(function (c) {
            var fullPermission = requiredPermission.componentKey + '.' + c;
            return userClaims.some(function (c) { return c === fullPermission; });
        });
    };
    return IdpOrganizationGuard;
}());
export { IdpOrganizationGuard };
