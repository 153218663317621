import { Component, Input } from '@angular/core';

import { InputBaseComponent } from '../input.base';
import { InputSelectModel } from '../../models/input-select.model';

/**
 * @deprecated Use InputNgSelectComponent instead. This one is laggy and can't search inside
 */
@Component({
  selector: 'app-input-select',
  templateUrl: './input-select.component.html',
  styleUrls: ['./../input.base.scss', './input-select.component.scss'],
})
export class InputSelectComponent extends InputBaseComponent {
  @Input() public options: InputSelectModel<unknown>[] = [];
  @Input() public noLabel = false;
}
