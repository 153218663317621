import * as tslib_1 from "tslib";
import { InputBaseComponent } from '../input.base';
var InputTextComponent = /** @class */ (function (_super) {
    tslib_1.__extends(InputTextComponent, _super);
    function InputTextComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.IsReadOnly = false;
        return _this;
    }
    return InputTextComponent;
}(InputBaseComponent));
export { InputTextComponent };
