import { of } from 'rxjs';
import { ContactProxyService } from '@farm-portal/core/contact/services/contact-proxy.service';
import * as i0 from "@angular/core";
import * as i1 from "./contact-proxy.service";
import * as i2 from "../contact-core-services.module";
var ContactCoreService = /** @class */ (function () {
    function ContactCoreService(contactProxyService) {
        this.contactProxyService = contactProxyService;
    }
    ContactCoreService.prototype.getOwnerContact = function () {
        return of({
            name: 'AgriSolutions Sp. z o. o.',
            street: undefined,
            buildingNumber: '34',
            roomNumber: undefined,
            city: 'Ligota Wielka',
            postCode: '56-400',
            commune: 'Oleśnica',
            state: 'Powiat oleśnicki',
            county: 'dolnośląskie',
            country: 'Polska',
            herdRegistrationNumber: undefined,
            farmRegistrationNumber: undefined,
            taxNumber: '9112024200',
            vatNumber: undefined,
            regon: undefined,
            hasFarmPortalAccount: undefined,
            locationsJSON: undefined,
            logoSmallImage: 'https://agrisolutions.eu/assets/img/logoagri.png ',
            logoMediumImage: 'https://agrisolutions.eu/assets/img/logoagri.png',
            contactMainEmail: 'kontakt@agrisolutions.eu',
            homepage: 'https://agrisolutions.eu',
        });
    };
    ContactCoreService.prototype.sendMessage = function (message) {
        return this.contactProxyService.sendMessage(message);
    };
    ContactCoreService.prototype.removeAccount = function () {
        return this.contactProxyService.removeAccount();
    };
    ContactCoreService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ContactCoreService_Factory() { return new ContactCoreService(i0.ɵɵinject(i1.ContactProxyService)); }, token: ContactCoreService, providedIn: i2.ContactCoreServicesModule });
    return ContactCoreService;
}());
export { ContactCoreService };
