/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ng-bootstrap/ng-bootstrap";
import * as i2 from "./navbar.component";
var styles_NavbarComponent = [];
var RenderType_NavbarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NavbarComponent, data: {} });
export { RenderType_NavbarComponent as RenderType_NavbarComponent };
export function View_NavbarComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 17, "nav", [["class", "header-navbar navbar navbar-expand-sm navbar-light bg-faded"]], null, null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i1.NgbNavbar, [], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 15, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 10, "div", [["class", "navbar-header d-flex align-items-center d-lg-none justify-content-between"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 5, "button", [["class", "navbar-toggle d-none float-left"], ["data-toggle", "collapse"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleSidebar() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Toggle navigation"])), (_l()(), i0.ɵeld(7, 0, null, null, 0, "span", [["class", "icon-bar"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 0, "span", [["class", "icon-bar"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 0, "span", [["class", "icon-bar"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 0, "img", [["class", "logo-farmportal d-none"], ["mainLogo", ""]], [[8, "src", 4]], null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 2, "span", [["class", "navbar-right navbar-collapse-toggle d-none"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "a", [["aria-controls", "navbarSupportedContent"], ["class", "open-navbar-container"]], [[1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.isCollapsed = !_co.isCollapsed) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 0, "i", [["class", "ph ph-dots-three-vertical"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 3, "div", [["class", "navbar-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 2, "div", [["class", "collapse navbar-collapse d-block pb-0"], ["id", "navbarSupportedContent"]], [[2, "collapse", null], [2, "show", null]], null, null, null, null)), i0.ɵdid(16, 16384, null, 0, i1.NgbCollapse, [], { collapsed: [0, "collapsed"] }, null), i0.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.isCollapsed; _ck(_v, 16, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.logoUrl; _ck(_v, 10, 0, currVal_0); var currVal_1 = !_co.isCollapsed; _ck(_v, 12, 0, currVal_1); var currVal_2 = true; var currVal_3 = !i0.ɵnov(_v, 16).collapsed; _ck(_v, 15, 0, currVal_2, currVal_3); }); }
export function View_NavbarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-navbar", [], null, null, null, View_NavbarComponent_0, RenderType_NavbarComponent)), i0.ɵdid(1, 49152, null, 0, i2.NavbarComponent, [], null, null)], null, null); }
var NavbarComponentNgFactory = i0.ɵccf("app-navbar", i2.NavbarComponent, View_NavbarComponent_Host_0, { logoUrl: "logoUrl" }, { toggleHideSidebar: "toggleHideSidebar" }, ["*"]);
export { NavbarComponentNgFactory as NavbarComponentNgFactory };
