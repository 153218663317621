<div class="page-container">
  <div *ngIf="hasLoadedError == false">
    <div class="circle-spinner loading-icon">
      <div></div>
    </div>

    <div class="spinner-text">
      {{ 'APPLICATION.loadingData' | translate }}
    </div>
  </div>

  <div
    *ngIf="hasLoadedError"
    class="page-content">
    <div class="alert alert-dark">
      {{ 'APPLICATION.ERRORS.loadingData' | translate }}
    </div>

    <div
      class="mt-5"
      *ngIf="ownerContact$ | async as ownerContact">
      <h4 class="font-weight-bold">{{ ownerContact.name }}</h4>
      <p>
        {{ ownerContact.city }} {{ ownerContact.buildingNumber }}<br />
        {{ ownerContact.postCode }} {{ ownerContact.commune }}<br />
        +48 730 834 686<br />
        {{ ownerContact.contactMainEmail }}
      </p>
    </div>

    <button
      (click)="onLogout()"
      title="{{ 'APPLICATION.NAVBAR.logout' | translate }}"
      class="btn btn-primary mt-5 w-100">
      {{ 'APPLICATION.NAVBAR.logout' | translate }}
    </button>
  </div>
</div>
