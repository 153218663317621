import * as tslib_1 from "tslib";
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { InputBaseComponent } from '../input.base';
var InputDateTimePickerComponent = /** @class */ (function (_super) {
    tslib_1.__extends(InputDateTimePickerComponent, _super);
    function InputDateTimePickerComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return InputDateTimePickerComponent;
}(InputBaseComponent));
export { InputDateTimePickerComponent };
