import { EventEmitter } from '@angular/core';
var EmptyStateComponent = /** @class */ (function () {
    function EmptyStateComponent() {
        this.btnIcon = 'ph ph-plus';
        this.showButton = true;
        this.assetSrc = 'empty-state.png';
        this.clicked = new EventEmitter();
    }
    EmptyStateComponent.prototype.onClick = function () {
        this.clicked.emit();
    };
    EmptyStateComponent.prototype.shouldDisplayEmptyState = function () {
        if (!this.displayModel) {
            return true;
        }
        if (this.displayModel.initialDataLoading) {
            return false;
        }
        if (!this.displayModel.anyDataReturned && !this.displayModel.filtersExists) {
            return true;
        }
        return false;
    };
    EmptyStateComponent.prototype.shouldShowButton = function () {
        if (!this.showButton) {
            return false;
        }
        return !!this.btnText && this.btnText.length > 0;
    };
    return EmptyStateComponent;
}());
export { EmptyStateComponent };
