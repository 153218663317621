import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-cancel-button',
  templateUrl: './cancel-button.component.html',
})
export class CancelButtonComponent {
  @Output() cancelClick: EventEmitter<void> = new EventEmitter<void>();

  public onClick() {
    this.cancelClick.emit();
  }
}
