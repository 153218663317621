import * as tslib_1 from "tslib";
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfigurationService } from 'farmcloud-core';
import { ExternalLoginProviderType } from '../models/external-login-provider-type.enum';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "farmcloud-core";
var AuthProxyService = /** @class */ (function () {
    function AuthProxyService(http, httpBackend, config) {
        this.http = http;
        this.httpBackend = httpBackend;
        this.baseTokenAuthUrl = config.configuration.api_url + "/api/TokenAuth";
        this.baseUserAuthUrl = config.configuration.api_url + "/api/UserAuth";
        this.baseUserProfileUrl = config.configuration.api_url + "/api/UserProfile";
        this.baseInvitationUrl = config.configuration.api_url + "/api/Invitation";
    }
    AuthProxyService.prototype.loginAsl = function (token) {
        var url = this.baseTokenAuthUrl + "/AslAuthenticate";
        var headers = new HttpHeaders().set('Content-Type', 'application/json');
        headers = headers.append('AslToken', JSON.stringify(token));
        var options = { headers: headers };
        return this.http.post(url, {
            authProvider: ExternalLoginProviderType.MICROSOFT,
            host: 'farmportal',
        }, options);
    };
    AuthProxyService.prototype.token = function (tokenRequestDto) {
        var url = this.baseTokenAuthUrl + "/Authenticate";
        return this.http.post(url, tokenRequestDto);
    };
    AuthProxyService.prototype.getUserProfile = function () {
        var url = "" + this.baseUserProfileUrl;
        return this.http.get(url);
    };
    AuthProxyService.prototype.refreshToken = function (refreshTokenDto) {
        var httpClient = new HttpClient(this.httpBackend);
        var url = this.baseTokenAuthUrl + "/RefreshToken";
        return httpClient.post(url, refreshTokenDto);
    };
    AuthProxyService.prototype.register = function (input) {
        var url = this.baseUserAuthUrl + "/Register";
        return this.http.post(url, input || {});
    };
    AuthProxyService.prototype.remindPassword = function (data) {
        var url = this.baseUserAuthUrl + "/RemindPassword";
        return this.http.post(url, data || {});
    };
    AuthProxyService.prototype.resetPassword = function (data) {
        var url = this.baseUserAuthUrl + "/ResetPassword";
        return this.http.post(url, tslib_1.__assign({}, data) || {});
    };
    AuthProxyService.prototype.confirmEmail = function (input) {
        var url = this.baseUserAuthUrl + "/ConfirmEmail";
        return this.http.post(url, input || {});
    };
    AuthProxyService.prototype.sendEmailActivationLink$ = function (input) {
        var url = this.baseUserAuthUrl + "/SendEmailActivationLink";
        return this.http.post(url, input || {});
    };
    AuthProxyService.prototype.acceptInvitation = function (data) {
        var url = this.baseInvitationUrl + "/AcceptInvitation";
        return this.http.post(url, data);
    };
    AuthProxyService.prototype.getInvitation = function (invitationId) {
        var url = this.baseInvitationUrl + "/" + invitationId;
        return this.http.get(url);
    };
    AuthProxyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthProxyService_Factory() { return new AuthProxyService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i1.HttpBackend), i0.ɵɵinject(i2.AppConfigurationService)); }, token: AuthProxyService, providedIn: "root" });
    return AuthProxyService;
}());
export { AuthProxyService };
