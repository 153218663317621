import { Injectable } from '@angular/core';
import { Observable, combineLatest, timer, BehaviorSubject } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { AppConfigurationService } from 'farmcloud-core';
import { MessageCoreProxyService } from '@farm-portal/core/message/services/message-core-proxy.service';
import { MessageCoreServicesModule } from '@farm-portal/core/message/message-core-services.module';
import { MessageApiListResponse } from '@farm-portal/core/message/models/message.dto';
import { UserContextService } from '@farm-portal/core/auth/user-context.service';

@Injectable({
  providedIn: MessageCoreServicesModule,
})
export class MessageService {
  public messageData$: Observable<MessageApiListResponse>;
  public unreadMessageCount$: Observable<string>;

  private readonly refresh$ = new BehaviorSubject<void>(null);

  constructor(
    private readonly messageCoreProxyService: MessageCoreProxyService,
    private readonly config: AppConfigurationService,
    private readonly userContextService: UserContextService,
  ) {
    if (!this.config.configuration.messages_enabled) {
      return;
    }

    this.messageData$ = combineLatest([
      timer(0, this.config.configuration.messages_refresh_rate),
      this.refresh$,
      this.userContextService.currentUserOrganization$,
    ]).pipe(
      filter(([, , currentOrganization]) => !!currentOrganization),
      switchMap(() => {
        return this.messageCoreProxyService.getAllUnreadMessages();
      }),
    );

    this.unreadMessageCount$ = this.messageData$.pipe(
      map(x => x.items.length),
      map(x => {
        if (x > 9) {
          return '9+';
        }

        return x.toString();
      }),
    );
  }

  public refresh() {
    this.refresh$.next(null);
  }
}
