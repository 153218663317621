<div *ngIf="field.invalid && field.touched">
  <ng-container *ngIf="!isFile; else fileTemplate">
    <span
      class="invalid-feedback d-block"
      *ngIf="field.hasError('required')">
      {{ 'APPLICATION.VALIDATORS.required' | translate: { placeholder: displayName } }}
    </span>
    <span
      class="invalid-feedback d-block"
      *ngIf="field.hasError('email')">
      {{ 'APPLICATION.VALIDATORS.email' | translate: { placeholder: displayName } }}
    </span>
    <span
      class="invalid-feedback d-block"
      *ngIf="field.hasError('min')">
      {{
        'APPLICATION.VALIDATORS.min'
          | translate
            : {
                placeholder: displayName,
                'validator.min': field.errors.min.min
              }
      }}
    </span>
    <span
      class="invalid-feedback d-block"
      *ngIf="field.hasError('max')">
      {{
        'APPLICATION.VALIDATORS.max'
          | translate
            : {
                placeholder: displayName,
                'validator.max': field.errors.max.max
              }
      }}
    </span>
    <span
      class="invalid-feedback d-block"
      *ngIf="field.hasError('minlength')">
      {{
        'APPLICATION.VALIDATORS.minLength'
          | translate
            : {
                minLength: field.errors.minlength.requiredLength,
                placeholder: displayName
              }
      }}
    </span>
    <span
      class="invalid-feedback d-block"
      *ngIf="field.hasError('maxlength')">
      {{
        'APPLICATION.VALIDATORS.maxLength'
          | translate
            : {
                maxLength: field.errors.maxlength.requiredLength,
                placeholder: displayName
              }
      }}
    </span>
    <span
      class="invalid-feedback d-block"
      *ngIf="field.hasError('dayBefore')">
      {{
        'APPLICATION.VALIDATORS.dayBefore'
          | translate
            : {
                placeholder: displayName,
                'validator.dayBefore': field.errors.dayBefore.dayBefore
              }
      }}
    </span>
    <span
      class="invalid-feedback d-block"
      *ngIf="field.hasError('dayAfter')">
      {{
        'APPLICATION.VALIDATORS.dayAfter'
          | translate
            : {
                placeholder: displayName,
                'validator.dayAfter': field.errors.dayAfter.dayAfter
              }
      }}
    </span>
    <span
      class="invalid-feedback d-block"
      *ngIf="field.hasError('dateRangeWithinLimit')">
      {{
        'APPLICATION.VALIDATORS.dateRangeWithinLimit'
          | translate
            : {
                placeholder: displayName,
                'validator.dateRangeWithinLimit': field.errors.dateRangeWithinLimit.maxDays
              }
      }}
    </span>
    <span
      class="invalid-feedback d-block"
      *ngIf="field.errors?.ngbDate?.invalid">
      {{ 'APPLICATION.VALIDATORS.ngbDate' | translate }}
    </span>
    <span
      class="invalid-feedback d-block"
      *ngIf="field.hasError('toCurrentDay')"
      >{{
        'APPLICATION.VALIDATORS.toCurrentDay'
          | translate
            : {
                placeholder: displayName,
                'validator.toCurrentDay': field.errors.toCurrentDay.toCurrentDay
              }
      }}</span
    >
    <span
      class="invalid-feedback d-block"
      *ngIf="field.hasError('pattern')">
      {{ 'APPLICATION.VALIDATORS.pattern' | translate: { placeholder: displayName } }}
    </span>
    <span
      class="invalid-feedback d-block"
      *ngIf="field.hasError('nipInvalid')">
      {{ 'APPLICATION.VALIDATORS.nipInvalid' | translate }}
    </span>
    <span
      class="invalid-feedback d-block"
      *ngIf="field.hasError('regonInvalid')">
      {{ 'APPLICATION.VALIDATORS.regonInvalid' | translate }}
    </span>
    <span
      class="invalid-feedback d-block"
      *ngIf="field.hasError('invalidPassword')">
      {{ 'APPLICATION.VALIDATORS.invalidPassword' | translate }}
    </span>
    <span
      class="invalid-feedback d-block"
      *ngIf="field.hasError('stockOfferProductSeedingName')">
      {{ 'APPLICATION.VALIDATORS.stockOfferProductSeedingName' | translate }}
    </span>
    <span
      class="invalid-feedback d-block"
      *ngIf="field.hasError('stockOfferProductCultivationType')">
      {{ 'APPLICATION.VALIDATORS.stockOfferProductCultivationType' | translate }}
    </span>
    <span
      class="invalid-feedback d-block"
      *ngIf="field.hasError('hasToBeHigherThan')">
      {{
        'APPLICATION.VALIDATORS.hasToBeHigherThan'
          | translate
            : {
                placeholder: displayName,
                value: field.errors.hasToBeHigherThan.value
              }
      }}
    </span>
    <span
      class="invalid-feedback d-block"
      *ngIf="field.hasError('hasToBeLessThan')">
      {{
        'APPLICATION.VALIDATORS.hasToBeLessThan'
          | translate
            : {
                placeholder: displayName,
                value: field.errors.hasToBeLessThan.value
              }
      }}
    </span>
    <span
      class="invalid-feedback d-block"
      *ngIf="field.hasError('hasToBeHigherOrEqualThan')">
      {{
        'APPLICATION.VALIDATORS.hasToBeHigherOrEqualThan'
          | translate
            : {
                placeholder: displayName,
                value: field.errors.hasToBeHigherOrEqualThan.value
              }
      }}
    </span>
    <span
      class="invalid-feedback d-block"
      *ngIf="field.hasError('hasToBeLessOrEqualThan')">
      {{
        'APPLICATION.VALIDATORS.hasToBeLessOrEqualThan'
          | translate
            : {
                placeholder: displayName,
                value: field.errors.hasToBeLessOrEqualThan.value
              }
      }}
    </span>
    <span
      class="invalid-feedback d-block"
      *ngIf="field.hasError('dateRangeError')">
      {{
        'APPLICATION.VALIDATORS.dateRangeError'
          | translate
            : {
                value: field.errors.dateRangeError.dateRangeError
              }
      }}
    </span>
  </ng-container>
  <ng-template #fileTemplate>
    <span
      class="invalid-feedback d-block"
      *ngIf="field.hasError('required')">
      {{ 'APPLICATION.VALIDATORS.fileRequired' | translate }}
    </span>
  </ng-template>
</div>
