import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { InputSelectModel } from '@farm-portal/shared/modules/form-inputs/models/input-select.model';
import { SettingsCoreProxyService } from '@farm-portal/core/settings/services/settings-core-proxy.service';
import { finalize, map, tap } from 'rxjs/operators';
import { SpinnerService } from 'farmcloud-core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-ngbd-modal-share',
  templateUrl: './ngbd-modal-share.component.html',
  styleUrls: ['./ngbd-modal-share.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgbdModalShareComponent {
  @Input() titleTranslateKey: string;
  @Input() messageTranslateKey: string;
  @Input() messageParams: string[];

  public get canConfirm(): boolean {
    return this.getFromControl('partners').valid;
  }

  public partnerOptions$: Observable<InputSelectModel<string>[]>;
  public partnerForm: FormGroup;

  constructor(
    public modal: NgbActiveModal,
    private settingsService: SettingsCoreProxyService,
    private spinner: SpinnerService,
    private fb: FormBuilder,
  ) {
    this.partnerForm = this.fb.group({
      partners: [null, Validators.required],
    });

    this.partnerOptions$ = this.settingsService.getPartnerList().pipe(
      tap(() => this.spinner.display()),
      map(options => {
        return options.items.map(option => ({ value: option.id, label: option.name }));
      }),
      finalize(() => this.spinner.hide()),
    );
  }

  public getFromControl(name: string): FormControl {
    return this.partnerForm.get(name) as FormControl;
  }

  public onClose() {
    this.modal.close(this.getFromControl('partners').value);
  }
}
