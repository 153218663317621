import { ModuleWithProviders } from '@angular/core';
import { IdpAuthGuard } from './idp-auth.guard';
import { IdpOrganizationGuard } from './idp-organization.guard';
import { AuthService } from './services/auth.service';
import { AuthProxyService } from './services/auth-proxy.service';
import { AuthStoreService } from './services/auth-store.service';
import { AuthInterceptorProvider } from './services/auth-interceptor.service';
import { IdpRucipGuard } from '@farm-portal/core/auth/idp-rucip.guard';
var AuthModule = /** @class */ (function () {
    function AuthModule(parentModule) {
        if (parentModule) {
            throw new Error('AuthModule is already loaded. Import it in the AppModule only');
        }
    }
    AuthModule.forRoot = function () {
        return {
            ngModule: AuthModule,
            providers: [
                AuthService,
                AuthProxyService,
                AuthStoreService,
                AuthInterceptorProvider,
                IdpAuthGuard,
                IdpOrganizationGuard,
                IdpRucipGuard,
            ],
        };
    };
    return AuthModule;
}());
export { AuthModule };
