import { Component, Input, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-validation-message',
  templateUrl: './validation-message.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ValidationMessageComponent {
  @Input() public field: FormControl;
  @Input() public displayName: string;
  @Input() public isFile: boolean = false;

  constructor() {}
}
