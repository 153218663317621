import { OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ErrorsHandler } from 'farmcloud-core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "farmcloud-core";
var ClarityRoutingService = /** @class */ (function () {
    function ClarityRoutingService(router, errorsHandler) {
        var _this = this;
        this.router = router;
        this.errorsHandler = errorsHandler;
        this.routerSub = this.router.events.pipe(filter(function (event) { return event instanceof NavigationEnd; })).subscribe(function () {
            try {
                // clarity function comes from script that we injected in index.html
                // @ts-ignore
                clarity('set', 'pageTitle', document.title);
            }
            catch (error) {
                _this.errorsHandler.handleError(new Error('Cannot set custom tag for this page - Clarity is not installed' + error));
            }
        });
    }
    ClarityRoutingService.prototype.ngOnDestroy = function () {
        if (this.routerSub)
            this.routerSub.unsubscribe();
    };
    ClarityRoutingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ClarityRoutingService_Factory() { return new ClarityRoutingService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.ErrorsHandler)); }, token: ClarityRoutingService, providedIn: "root" });
    return ClarityRoutingService;
}());
export { ClarityRoutingService };
