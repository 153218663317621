import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { combineLatest, Subject } from 'rxjs';
import { flatMap, map, takeUntil, tap } from 'rxjs/operators';
import { UserContextService } from './../../../auth/user-context.service';
import { Router } from '@angular/router';
import { SpinnerService } from 'farmcloud-core';
var FarmNavItemComponent = /** @class */ (function () {
    function FarmNavItemComponent(userContextService, spinnerService, router, formBuilder) {
        this.userContextService = userContextService;
        this.spinnerService = spinnerService;
        this.router = router;
        this.formBuilder = formBuilder;
        this.unsubscribed = new Subject();
        this.formGroup = formBuilder.group({
            userOrganization: [null],
        });
    }
    FarmNavItemComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userOrganizations$ = this.userContextService.userOrganizations$;
        this.currentUserOrganization$ = this.userContextService.currentUserOrganization$;
        this.userOrganizations$ = combineLatest([this.userContextService.userOrganizations$, this.currentUserOrganization$]).pipe(map(function (_a) {
            var userOrganisations = _a[0], currentUserOrganization = _a[1];
            if (currentUserOrganization == null) {
                return [];
            }
            var selectedOrganizationId = currentUserOrganization.id;
            return (userOrganisations || []).map(function (x) {
                return tslib_1.__assign({}, x, { active: x.id === selectedOrganizationId });
            });
        }));
        this.organizationsSub = this.userOrganizations$.pipe(takeUntil(this.unsubscribed)).subscribe(function (res) {
            _this.initVal = res.filter(function (x) { return x.active === true; })[0];
            _this.getControl('userOrganization').setValue(res.filter(function (x) { return x.active === true; })[0], { emitEvent: false });
        });
        this.onFarmChange();
    };
    FarmNavItemComponent.prototype.onFarmChange = function () {
        var _this = this;
        this.subscription = this.getControl('userOrganization')
            .valueChanges.pipe(tap(function () { return _this.spinnerService.display(); }), map(function (selectedValue) { return selectedValue; }), flatMap(function (x) {
            if (x != null && _this.initVal !== x) {
                return _this.userContextService.selectCurrentUserOrganization(x);
            }
            else {
                return null;
            }
        }))
            .pipe(takeUntil(this.unsubscribed))
            .subscribe(function () {
            _this.spinnerService.hide();
            return _this.router.navigate(['/'], { skipLocationChange: false });
        });
    };
    FarmNavItemComponent.prototype.ngOnDestroy = function () {
        this.unsubscribed.next();
        this.unsubscribed.complete();
        if (this.subscription != null) {
            this.subscription.unsubscribe();
        }
        if (this.organizationsSub) {
            this.organizationsSub.unsubscribe();
        }
    };
    FarmNavItemComponent.prototype.getControl = function (name) {
        return this.formGroup.get(name);
    };
    return FarmNavItemComponent;
}());
export { FarmNavItemComponent };
