import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigurationService, StorageService, SupportedLanguages } from 'farmcloud-core';
import { TranslationsLoader } from './translations-loader';

@Injectable({
  providedIn: 'root',
})
export class TranslationInitializationService {
  constructor(
    private translate: TranslateService,
    private appConfigurationService: AppConfigurationService,
    private translationsLoader: TranslationsLoader,
    private storageService: StorageService,
  ) {}

  initializeTranslations(): Promise<any> {
    return new Promise((resolve, reject) => {
      const checkConfigLoaded = () => {
        if (this.appConfigurationService.configuration.api_url) {
          const lang = (this.storageService.getItem('lang') as string) || SupportedLanguages.PL;
          this.translationsLoader
            .getTranslation(lang)
            .toPromise()
            .then(translations => {
              this.translate.setTranslation(lang, translations);
              this.translate.use(lang);
              resolve(true);
            })
            .catch(reject);
        } else {
          setTimeout(checkConfigLoaded, 100);
        }
      };
      checkConfigLoaded();
    });
  }
}
