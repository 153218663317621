import { ElementRef, OnInit } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
// Fixes issue where ng-select doesn't take in account fieldset disabled status
// Fixed in ng-select > 4.x
// https://github.com/ng-select/ng-select/issues/751
var FieldsetDisabledDirective = /** @class */ (function () {
    function FieldsetDisabledDirective(el, ngSelect) {
        this.el = el;
        this.ngSelect = ngSelect;
    }
    FieldsetDisabledDirective.prototype.ngOnInit = function () {
        var fieldsetElement = this.el.nativeElement.closest('fieldset');
        if (fieldsetElement) {
            this.setState(fieldsetElement.disabled);
            this.listenToChange(fieldsetElement);
        }
    };
    FieldsetDisabledDirective.prototype.setState = function (isFieldsetDisabled) {
        var isReadonly = this.ngSelect.readonly;
        this.ngSelect.setDisabledState(isFieldsetDisabled);
        if (isFieldsetDisabled || isReadonly) {
            this.el.nativeElement.classList.add('ng-select-disabled');
            return;
        }
        this.el.nativeElement.classList.remove('ng-select-disabled');
    };
    FieldsetDisabledDirective.prototype.listenToChange = function (fieldsetElement) {
        var _this = this;
        var observer = new MutationObserver(function (mutations, observer) {
            if (mutations[0].attributeName === 'disabled') {
                _this.setState(fieldsetElement.disabled);
                observer.disconnect();
                _this.listenToChange(fieldsetElement);
            }
        });
        observer.observe(fieldsetElement, { attributes: true });
    };
    return FieldsetDisabledDirective;
}());
export { FieldsetDisabledDirective };
