import { OnDestroy, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
var SidebarComponent = /** @class */ (function () {
    function SidebarComponent(translate) {
        this.translate = translate;
        this.logoutEmit = new EventEmitter();
        this.activeTitles = [];
        this.destroy$ = new Subject();
        if (this.depth === undefined) {
            this.depth = 0;
            this.expanded = true;
        }
    }
    SidebarComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next();
        this.destroy$.complete();
    };
    SidebarComponent.prototype.logout = function () {
        this.logoutEmit.emit();
    };
    SidebarComponent.prototype.toggleSlideInOut = function () {
        this.expanded = !this.expanded;
    };
    SidebarComponent.prototype.handleToggle = function (titles) {
        this.activeTitles = titles;
    };
    SidebarComponent.prototype.hasSubmenuAndHasNotExternalLink = function (item) {
        return item.submenu.length > 0 && !item.isExternalLink && this.isMenuItemForComponent(item);
    };
    SidebarComponent.prototype.hasNotSubmenuAndExternalLink = function (item) {
        return item.submenu.length === 0 && !item.isExternalLink && this.isMenuItemForComponent(item);
    };
    SidebarComponent.prototype.hasMenuExternalLink = function (item) {
        return item.isExternalLink && this.isMenuItemForComponent(item);
    };
    SidebarComponent.prototype.isMenuItemForComponent = function (item) {
        var _this = this;
        if (item.components === undefined) {
            return true;
        }
        if (item.components.length === 0) {
            return true;
        }
        if (this.userClaims === undefined) {
            return true;
        }
        return item.components.some(function (component) {
            return _this.userClaims.some(function (claim) {
                return claim.startsWith(component);
            });
        });
    };
    SidebarComponent.prototype.isMenuGroupForComponent = function (item) {
        var _this = this;
        if (item.components === undefined) {
            return true;
        }
        if (item.components.length === 0) {
            return true;
        }
        if (this.userClaims === undefined) {
            return true;
        }
        return item.components.some(function (component) {
            return _this.userClaims.some(function (claim) {
                return claim.startsWith(component);
            });
        });
    };
    return SidebarComponent;
}());
export { SidebarComponent };
