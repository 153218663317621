<div class="row">
  <div class="col-12">
    <h5
      *ngIf="headerTranslationKey"
      class="headline-underline">
      {{ headerTranslationKey | translate }}
    </h5>
    <form
      [formGroup]="formGroup"
      (ngSubmit)="onSave()">
      <ng-content></ng-content>

      <div
        *ngIf="displayFormButtons"
        class="d-flex justify-content-end">
        <app-form-buttons
          *ngIf="!isEditVisible()"
          (cancelClick)="onCancel()"></app-form-buttons>
        <button
          (click)="onEditClick()"
          *ngIf="isEditVisible()"
          class="btn btn-primary mt-4"
          type="button">
          {{ 'APPLICATION.FORM.edit' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
