import * as tslib_1 from "tslib";
import { InputBaseComponent } from '../input.base';
var InpuRangeComponent = /** @class */ (function (_super) {
    tslib_1.__extends(InpuRangeComponent, _super);
    function InpuRangeComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.min = 0;
        _this.max = 100;
        _this.step = 1;
        _this.customLabel = null;
        return _this;
    }
    return InpuRangeComponent;
}(InputBaseComponent));
export { InpuRangeComponent };
