import { Router } from '@angular/router';
import { AuthStoreService } from './services/auth-store.service';
var IdpRucipGuard = /** @class */ (function () {
    function IdpRucipGuard(router, authStore) {
        this.router = router;
        this.authStore = authStore;
    }
    IdpRucipGuard.prototype.canActivate = function (route, state) {
        return this.getRucipGuardResult();
    };
    IdpRucipGuard.prototype.canLoad = function (route, segments) {
        return this.getRucipGuardResult();
    };
    IdpRucipGuard.prototype.getRucipGuardResult = function () {
        var claims = this.authStore.getUserClaims;
        var canEnterRucip = claims.some(function (claim) { return claim.startsWith('Organization.Partners.PFZ'); });
        if (!canEnterRucip) {
            this.router.navigate(['my-fields']);
        }
        return true;
    };
    return IdpRucipGuard;
}());
export { IdpRucipGuard };
