import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AUTH_PARTNERS } from '@farm-portal/core/auth/models/auth-partners.const';
import { AuthStoreService } from '@farm-portal/core/auth/services/auth-store.service';
import { AuthService } from '@farm-portal/core/auth/services/auth.service';
import { UserContextService } from '@farm-portal/core/auth/user-context.service';
import { ContactCoreService, IContactDto } from '@farm-portal/core/contact';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-load-user-data',
  templateUrl: './load-user-data.component.html',
  styleUrls: ['./load-user-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadUserDataComponent implements OnInit {
  hasLoadedError: boolean;
  ownerContact$: Observable<IContactDto>;

  get groupClaims(): string[] {
    return this.authStore.getUserClaims;
  }

  constructor(
    private authService: AuthService,
    private readonly authStore: AuthStoreService,
    private userContextService: UserContextService,
    private contactCoreService: ContactCoreService,
    private route: ActivatedRoute,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    const returnUrl = this.route.snapshot.queryParams.returnUrl;
    this.hasLoadedError = false;
    this.userContextService.tryGetCurrentUserData$().subscribe(
      () => {
        returnUrl == null ? this.router.navigate(['/']) : this.router.navigateByUrl(returnUrl);
      },
      _ => {
        this.hasLoadedError = true;
        this.ownerContact$ = this.contactCoreService.getOwnerContact();
        this.changeDetectorRef.detectChanges();
      },
    );
  }

  onLogout() {
    const partner = AUTH_PARTNERS.find(partner => this.hasAccess(partner.permissionComponent));
    const partnerId = partner ? partner.partnerId : undefined;

    this.userContextService.clean();
    return this.authService.navigateToLoginPage(partnerId);
  }

  private hasAccess(componentKey: string): boolean {
    return this.groupClaims.some(claim => {
      return claim.startsWith(componentKey);
    });
  }
}
