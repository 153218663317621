import { Component, ChangeDetectionStrategy } from '@angular/core';
import { MessageService } from '@farm-portal/core/layout/navbar/message-unread-notification/message.service';

@Component({
  selector: 'app-message-unread-notification',
  templateUrl: './message-unread-notification.component.html',
  styleUrls: ['./message-unread-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageUnreadNotificationComponent {
  constructor(public messageUnreadService: MessageService) {}
}
