import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { LoggerService, SpinnerService, TranslateService } from 'farmcloud-core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  public isLoading$ = this.spinnerService.status$;
  constructor(
    private readonly translateService: TranslateService,
    private readonly loggerService: LoggerService,
    private readonly spinnerService: SpinnerService,
  ) {}

  ngOnInit(): void {
    this.translateService.initialize();
    this.loggerService.initialize();
  }
}
