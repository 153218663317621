<div
  ngbDropdown
  [placement]="'bottom-right'">
  <button
    *ngIf="notificationService.unreadMessageCount$ | async; let count"
    class="notification-btn btn btn-icon"
    id="dropdownNotification"
    ngbDropdownToggle>
    <i class="notification-btn-icon ph-duotone ph-bell-ringing color-primary"></i>
    <span
      class="notification-badge badge badge-number"
      *ngIf="count != 0"
      >{{ count }}</span
    >
  </button>
  <div
    aria-labelledby="dropdownNotification"
    ngbDropdownMenu
    class="notification-modal">
    <app-empty-state
      [subtitle]="'APPLICATION.NOTIFICATIONS.noData' | translate"
      [displayModel]="notificationService.emptyStateDisplayModel"
      [assetSrc]="'empty_state_notification_messages.svg'">
      <div class="mx-2 mb-1">
        <h5 class="mt-2 mb-1">{{ 'APPLICATION.NOTIFICATIONS.header' | translate }}</h5>
        <button
          class="btn btn-link"
          (click)="onMarkAllNotificationsAsReadClick()">
          {{ 'APPLICATION.NOTIFICATIONS.markAsRead' | translate }}
        </button>
      </div>

      <a
        *ngFor="let notification of notificationService.messages$ | async"
        (click)="onNotificationClick(notification)"
        [class.notification-item-unread]="!notification.isRead"
        class="notification-item">
        <div class="d-flex align-items-center justify-content-between color-text-tetritary">
          <div class="notification-time">{{ notification.creationDate | longDateTime }}</div>
        </div>
        <h3 class="notification-title">{{ notification.title }}</h3>
        <p class="notification-details color-text-secondary">
          {{ notification.body }}
        </p>
      </a>
    </app-empty-state>
  </div>
</div>
