import { Component, Input } from '@angular/core';

import { InputBaseComponent } from '../input.base';

@Component({
  selector: 'app-input-range',
  templateUrl: './input-range.component.html',
  styleUrls: ['./../input.base.scss'],
})
export class InpuRangeComponent extends InputBaseComponent {
  @Input() public min: number | null = 0;
  @Input() public max: number | null = 100;
  @Input() public step: number = 1;
  @Input() public customLabel: string | null = null;
}
