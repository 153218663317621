import { BehaviorSubject, forkJoin, of } from 'rxjs';
import { AuthStorageService } from './auth-storage.service';
import { StorageKey } from '../models/storage-key.enum';
import { map, switchMap, tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./auth-storage.service";
var AuthStoreService = /** @class */ (function () {
    function AuthStoreService(storageService) {
        this.storageService = storageService;
        this.userLogged = new BehaviorSubject(false);
        this.userClaims = new BehaviorSubject(null);
        this.userOrganizationId = new BehaviorSubject(null);
        this.userFullName = new BehaviorSubject(null);
        this.initUserState();
    }
    Object.defineProperty(AuthStoreService.prototype, "getUserClaims", {
        get: function () {
            if (this.userClaims && this.userClaims.value != null) {
                return this.userClaims.value;
            }
            return [];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthStoreService.prototype, "getUserOrganizationId", {
        get: function () {
            if (this.userOrganizationId && this.userOrganizationId.value != null) {
                return this.userOrganizationId.value;
            }
            return;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthStoreService.prototype, "isAuthenticated", {
        get: function () {
            return this.userLogged.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthStoreService.prototype, "getUserFullName", {
        get: function () {
            if (this.userFullName && this.userFullName.value != null) {
                return this.userFullName.value;
            }
            throw new Error('Unable to get user full name.');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthStoreService.prototype, "getAccessToken", {
        get: function () {
            return this.storageService.getItemSync(StorageKey.ACCESS_TOKEN_KEY);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthStoreService.prototype, "getRefreshToken", {
        get: function () {
            return this.storageService.getItemSync(StorageKey.REFRESH_TOKEN_KEY);
        },
        enumerable: true,
        configurable: true
    });
    AuthStoreService.prototype.initUserState = function () {
        var _this = this;
        forkJoin({
            accessToken: this.storageService.getItem(StorageKey.ACCESS_TOKEN_KEY),
            userProfile: this.storageService.getItem(StorageKey.USER_PROFILE),
        }).subscribe(function (res) {
            var tokenIsValid = res.accessToken !== null && res.accessToken !== undefined && res.accessToken !== '';
            var userProfileExists = res.userProfile !== null;
            if (tokenIsValid && userProfileExists) {
                _this.userLogged.next(true);
                _this.updateUserDataInternal(res.userProfile);
            }
        }, function () {
            _this.updateUserNotLogged();
        });
    };
    AuthStoreService.prototype.updateAuthTokens = function (accessToken, refreshToken) {
        var _this = this;
        var accessTokenObs = this.storageService.setItem(StorageKey.ACCESS_TOKEN_KEY, accessToken);
        var refreshTokenObs = this.storageService.setItem(StorageKey.REFRESH_TOKEN_KEY, refreshToken);
        return forkJoin([accessTokenObs, refreshTokenObs]).pipe(tap(function () { return _this.userLogged.next(accessToken !== null && refreshToken !== null); }), switchMap(function () { return of(null); }));
    };
    AuthStoreService.prototype.updateUserData = function (userProfile) {
        var _this = this;
        return this.storageService.setItem(StorageKey.USER_PROFILE, userProfile).pipe(tap(function () {
            _this.updateUserDataInternal(userProfile);
        }), map(function () { return null; }));
    };
    AuthStoreService.prototype.updateUserDataInternal = function (userProfile) {
        this.userClaims.next(userProfile.permissions);
        this.userOrganizationId.next(userProfile.organizationId);
        this.userFullName.next(userProfile.fullName);
    };
    AuthStoreService.prototype.updateUserNotLogged = function () {
        this.updateAuthTokens(null, null);
        this.updateUserData(null);
    };
    AuthStoreService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthStoreService_Factory() { return new AuthStoreService(i0.ɵɵinject(i1.AuthStorageService)); }, token: AuthStoreService, providedIn: "root" });
    return AuthStoreService;
}());
export { AuthStoreService };
