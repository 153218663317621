import { Component, Input } from '@angular/core';
import { InputBaseComponent } from '../input.base';
import { InputRadioItem } from './models/radio-item.model';

@Component({
  selector: 'app-input-radio-button-group',
  templateUrl: 'input-radio-button-group.component.html',
  styleUrls: ['input-radio-button-group.component.scss'],
})
export class InputRadioButtonGroupComponent<TEnum> extends InputBaseComponent {
  @Input() radioButtonGroupName: string = 'default';
  @Input() options: InputRadioItem<TEnum>[] = [];

  public isOptionActive(option: TEnum) {
    return option == this.inputControl.value;
  }
}
