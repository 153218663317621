import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Output,
  ViewChild,
  Input,
  OnInit,
  ViewRef,
  OnDestroy,
} from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

import { catchError, map } from 'rxjs/operators';
import { EMPTY, Subscription } from 'rxjs';

import { Gallery, GalleryItem, ImageItem } from '@ngx-gallery/core';
import { Lightbox } from '@ngx-gallery/lightbox';

import { FileUtilService, FileModel } from 'farmcloud-core';
import { FileFormService } from './services/file-form.service';
import { ImageCompressionLogicService } from './services/image-compression-logic.service';

import { ResizeOptions, createImage, resizeImage } from './image.utils';
import { IImageOptions } from './models/image-options';

interface ImageResult {
  file: File;
  url: string;
  dataURL?: string;
  width?: number;
  height?: number;
  resized?: {
    dataURL: string;
    type: string;
  };
}

@Component({
  selector: 'lib-file-form-control',
  templateUrl: './file-form-control.component.html',
  styleUrls: ['./file-form-control.component.scss'],
  host: {
    '[style.width]': 'thumbnailWidth + "px"',
    '[style.height]': 'thumbnailHeight + "px"',
    '[style.max-width]': 'thumbnailWidth + "px"',
    '(drop)': 'drop($event)',
    '(dragenter)': 'dragenter($event)',
    '(dragover)': 'dragover($event)',
    '(dragleave)': 'dragleave($event)',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileFormControlComponent),
      multi: true,
    },
    FileFormService,
    ImageCompressionLogicService,
  ],
})
export class FileFormControlComponent implements ControlValueAccessor, OnInit, OnDestroy {
  isDraggingOverDropZone = false;
  isFile = false;
  isRequired = false;

  @Input() set label(value: string) {
    this._label = value || 'APPLICATION.FORM.addImage';
  }

  @Input() accept: string[] | null;
  @Input() acceptExtensions: string[] | null;
  @Input() maxSize: number | null;
  @Input() readonly: boolean;
  private _formControlName: string;
  private _label: string;

  @Input()
  set formControlName(value: string) {
    this._formControlName = value;
    this.checkIsRequired();
  }

  get formControlName(): string {
    return this._formControlName;
  }

  get formControl(): FormControl {
    const control = this.formGroup.get(this.formControlName) as FormControl;
    if (!control) return null;
    return control;
  }

  get label(): string {
    return this._label;
  }

  @Output() blur: EventEmitter<any> = new EventEmitter();
  @Output() change: EventEmitter<any> = new EventEmitter();
  @Output() focus: EventEmitter<any> = new EventEmitter();

  thumbnailWidth = 195;
  thumbnailHeight = 195;
  noImageFile = false;
  isDisabled = false;

  allowedImageTypes = ['jpg', 'jpeg', 'png', 'svg', 'jfif', 'gif', 'bmp'];
  galleryId: string;
  _imageThumbnail: string;

  errorMessageTranslationKey: string;
  errorMessageInfo: string;

  origImageWidth: number;
  orgiImageHeight: number;

  imageCompressionOptions: IImageOptions = {
    width: 1920,
    height: 1080,
    quality: 85,
  };

  compressImageSubscription: Subscription;

  _imageFile: FileModel;

  @ViewChild('imageElement', { static: false }) imageElement: ElementRef;
  @ViewChild('fileInput', { static: false }) fileInputElement: ElementRef;
  @ViewChild('dragOverlay', { static: false }) dragOverlayElement: ElementRef;

  private onChange = (_: FileModel) => {};

  private onTouched = () => {};

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly fileUtilService: FileUtilService,
    private readonly fileFormService: FileFormService,
    private readonly imageCompressionService: ImageCompressionLogicService,
    private readonly lightbox: Lightbox,
    private readonly gallery: Gallery,
    private readonly controlContainer: ControlContainer,
  ) {}

  ngOnDestroy(): void {
    if (this.compressImageSubscription) {
      this.compressImageSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {}

  get imageThumbnail() {
    return this._imageThumbnail;
  }

  get imageName() {
    return this._imageFile.rootFileName ? this._imageFile.rootFileName : this._imageFile.name;
  }

  set imageFile(value: FileModel) {
    this._imageFile = value;
    this.onChange(this._imageFile);
    this.onTouched();
    this.initImageThumbnail();
  }

  writeValue(value: FileModel) {
    this.imageFile = value;
    this.initImageThumbnail();
  }

  registerOnChange(fn: (_: FileModel) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  onImageClicked() {
    if (!this.readonly) {
      this.fileInputElement.nativeElement.click();
    }
  }

  onFileChanged() {
    this.dismissError();
    const file = this.fileInputElement.nativeElement.files[0] as File;
    if (!file) {
      return;
    }
    this.setFile(file);
  }

  openLightbox() {
    this.initGallery();
    this.lightbox.open(0, this.galleryId);
  }

  validate(file: File) {
    if (this.accept && this.accept.length > 0 && !this.accept.some(allowedType => file.type === allowedType)) {
      this.errorMessageTranslationKey = 'APPLICATION.VALIDATORS.fileAcceptFormat';
      this.errorMessageInfo = '';
      return false;
    }

    if (this.maxSize && file.size > this.maxSize * 1024 * 1024) {
      this.errorMessageTranslationKey = 'APPLICATION.VALIDATORS.fileMaxSize';
      this.errorMessageInfo = `${this.maxSize} MB`;
      return false;
    }

    return true;
  }

  checkIsRequired() {
    this.isRequired = this.isFormControlRequired(this.formControlName);
  }

  private isFormControlRequired(controlName: string): boolean {
    const formControl = (this.controlContainer.control as FormGroup).get(controlName);
    if (!formControl || !formControl.validator) return false;

    const validator = formControl.validator({} as AbstractControl);
    if (validator && validator.required) return true;

    return false;
  }

  private get formGroup(): FormGroup {
    return this.controlContainer.control as FormGroup;
  }

  private setFile(file: File) {
    if (this.validate(file)) {
      if (this.fileFormService.isImageFile(file, this.allowedImageTypes)) {
        this.compressImageSubscription = this.imageCompressionService
          .compressImageToRatio(file, this.imageCompressionOptions)
          .pipe(
            map((resultFile: File) => {
              if (!resultFile) {
                return;
              }
              this.imageFile = {
                name: file.name,
                file: resultFile,
              };
            }),
            catchError(err => {
              this.imageFile = undefined;
              return EMPTY;
            }),
          )
          .subscribe();
      } else {
        this.imageFile = {
          name: file.name,
          file,
        };
      }
    } else {
      this.imageFile = undefined;
    }
  }

  private setImageThumbnailFromFile(file: File) {
    let result: ImageResult = null;

    const res = this.fileFormService.setImageThumbnailFromFile(file, this.allowedImageTypes);

    this.noImageFile = res.noImageFile;
    result = {
      file: res.file,
      url: res.url,
    };

    this.resize(result).then(r => {
      this._imageThumbnail = r.resized.dataURL;
      this.origImageWidth = r.width;
      this.orgiImageHeight = r.height;
      this.isFile = true;
      if (this.changeDetector && !(this.changeDetector as ViewRef).destroyed) {
        this.changeDetector.detectChanges();
      }
    });
  }

  private setImageThumbnailFromUrl(file: FileModel) {
    const result = this.fileFormService.setImageThumbnailFromUrl(file, this.allowedImageTypes);
    this._imageThumbnail = result.url;
    this.noImageFile = result.noImageFile;
    this.isFile = true;
  }

  private initImageThumbnail() {
    if (this._imageFile) {
      if (this._imageFile.link != null) {
        this.setImageThumbnailFromUrl(this._imageFile);
      } else if (this._imageFile.file != null) {
        this.setImageThumbnailFromFile(this._imageFile.file);
      }
    } else {
      this._imageThumbnail = undefined;
      this.isFile = false;
    }
  }

  downloadFile() {
    if (this._imageFile.link) {
      this.fileUtilService.downloadFileWithNameAsync(this._imageFile.link, this.imageName);
    }
  }

  removeImage() {
    this.fileInputElement.nativeElement.value = null;
    this.imageFile = undefined;
  }

  drop(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    this.dismissError();
    if (!e.dataTransfer || !e.dataTransfer.files.length) {
      return;
    }

    this.setFile(e.dataTransfer.files[0]);
    this.updateDragOverlayStyles(false);
  }

  dragenter(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
  }

  dragover(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    this.updateDragOverlayStyles(true);
  }

  dragleave(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    this.updateDragOverlayStyles(false);
  }

  private updateDragOverlayStyles(isDragOver: boolean) {
    this.isDraggingOverDropZone = isDragOver;
  }

  private resize(result: ImageResult): Promise<ImageResult> {
    const resizeOptions: ResizeOptions = {
      resizeHeight: this.thumbnailHeight,
      resizeWidth: this.thumbnailWidth,
      resizeType: result.file.type,
      resizeMode: 'fit',
    };

    return new Promise(resolve => {
      createImage(result.url, image => {
        const dataUrl = resizeImage(image, resizeOptions);

        result.width = image.width;
        result.height = image.height;
        result.resized = {
          dataURL: dataUrl,
          type: this.getType(dataUrl),
        };

        resolve(result);
      });
    });
  }

  private dismissError() {
    this.errorMessageInfo = undefined;
    this.errorMessageTranslationKey = undefined;
  }

  private getType(dataUrl: string) {
    return dataUrl.match(/:(.+\/.+;)/)[1];
  }

  private initGallery() {
    this.galleryId = `${this.imageName}`;
    const items: GalleryItem[] = [];
    const item = new ImageItem({
      src: this._imageFile.link ? this._imageFile.link : URL.createObjectURL(this._imageFile.file),
      thumb: this._imageThumbnail,
    });
    items.push(item);
    const galleryRef = this.gallery.ref(this.galleryId);
    galleryRef.load(items);
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
