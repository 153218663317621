<div class="form-group">
  <label
    *ngIf="labelTranslateKey.length > 0"
    class="label"
    [ngClass]="{ required: isInputControlRequired() }"
    [for]="labelTranslateKey">
    {{ labelTranslateKey | translate }}
    <i
      *ngIf="tooltipTranslateKey"
      class="ph ph-info"
      data-toggle="tooltip"
      data-placement="top"
      title="{{ tooltipTranslateKey | translate }}"></i>
  </label>
  <ng-select
    appFieldsetDisabled
    class="ng-select choices"
    appendTo="body"
    [formControl]="inputControl"
    [id]="labelTranslateKey"
    [items]="options"
    [bindLabel]="bindLabel"
    [bindValue]="bindValue"
    [clearable]="clearable"
    [placeholder]="'APPLICATION.FORM.select' | translate"
    [notFoundText]="'APPLICATION.FORM.multiSelect:notFoundText' | translate"
    [groupBy]="groupBy"
    [multiple]="multiple"
    [closeOnSelect]="!multiple"
    [readonly]="readonly"
    [searchFn]="searchByField != null ? customSearchFn : null"
    [virtualScroll]="true">
    <ng-template
      ng-optgroup-tmp
      let-item="item">
      <span [title]="getDisplayGroupText(item) | translate">
        {{ getDisplayGroupText(item) | translate }}
      </span>
    </ng-template>

    <ng-template
      let-item="item"
      ng-option-tmp
      ng-label-tmp>
      <span [title]="getDisplayText(item) | translate">
        {{ getDisplayText(item) | translate }}
      </span>
    </ng-template>
  </ng-select>
  <app-validation-message
    [field]="inputControl"
    displayName="{{ labelTranslateKey | translate | lowercase }}"></app-validation-message>
</div>
