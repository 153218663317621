/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "../buttons/form-buttons/form-buttons.component.ngfactory";
import * as i3 from "../buttons/form-buttons/form-buttons.component";
import * as i4 from "@angular/common";
import * as i5 from "@angular/forms";
import * as i6 from "./form-wide.component";
var styles_FormWideComponent = [];
var RenderType_FormWideComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FormWideComponent, data: {} });
export { RenderType_FormWideComponent as RenderType_FormWideComponent };
function View_FormWideComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "h5", [["class", "headline-underline"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.headerTranslationKey)); _ck(_v, 1, 0, currVal_0); }); }
function View_FormWideComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-form-buttons", [], null, [[null, "cancelClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cancelClick" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FormButtonsComponent_0, i2.RenderType_FormButtonsComponent)), i0.ɵdid(1, 49152, null, 0, i3.FormButtonsComponent, [], null, { cancelClick: "cancelClick" })], null, null); }
function View_FormWideComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-primary mt-4"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onEditClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("APPLICATION.FORM.edit")); _ck(_v, 1, 0, currVal_0); }); }
function View_FormWideComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "d-flex justify-content-end"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FormWideComponent_3)), i0.ɵdid(2, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FormWideComponent_4)), i0.ɵdid(4, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isEditVisible(); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isEditVisible(); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_FormWideComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 10, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FormWideComponent_1)), i0.ɵdid(3, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 7, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngSubmit"], [null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 6).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 6).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("ngSubmit" === en)) {
        var pd_2 = (_co.onSave() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(5, 16384, null, 0, i5.ɵangular_packages_forms_forms_z, [], null, null), i0.ɵdid(6, 540672, null, 0, i5.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, { ngSubmit: "ngSubmit" }), i0.ɵprd(2048, null, i5.ControlContainer, null, [i5.FormGroupDirective]), i0.ɵdid(8, 16384, null, 0, i5.NgControlStatusGroup, [[4, i5.ControlContainer]], null, null), i0.ɵncd(null, 0), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FormWideComponent_2)), i0.ɵdid(11, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.headerTranslationKey; _ck(_v, 3, 0, currVal_0); var currVal_8 = _co.formGroup; _ck(_v, 6, 0, currVal_8); var currVal_9 = _co.displayFormButtons; _ck(_v, 11, 0, currVal_9); }, function (_ck, _v) { var currVal_1 = i0.ɵnov(_v, 8).ngClassUntouched; var currVal_2 = i0.ɵnov(_v, 8).ngClassTouched; var currVal_3 = i0.ɵnov(_v, 8).ngClassPristine; var currVal_4 = i0.ɵnov(_v, 8).ngClassDirty; var currVal_5 = i0.ɵnov(_v, 8).ngClassValid; var currVal_6 = i0.ɵnov(_v, 8).ngClassInvalid; var currVal_7 = i0.ɵnov(_v, 8).ngClassPending; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_FormWideComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "lib-form-wide", [], null, null, null, View_FormWideComponent_0, RenderType_FormWideComponent)), i0.ɵdid(1, 245760, null, 0, i6.FormWideComponent, [i4.DOCUMENT], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormWideComponentNgFactory = i0.ɵccf("lib-form-wide", i6.FormWideComponent, View_FormWideComponent_Host_0, { headerTranslationKey: "headerTranslationKey", formGroup: "formGroup", displayFormButtons: "displayFormButtons", isEditMode: "isEditMode", isOneWayForm: "isOneWayForm" }, { navigateBack: "navigateBack", onCancelForm: "onCancelForm", formSubmitted: "formSubmitted", editModeChanged: "editModeChanged", onInvalidForm: "onInvalidForm" }, ["*"]);
export { FormWideComponentNgFactory as FormWideComponentNgFactory };
