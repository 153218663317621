import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '@farm-portal/app-routing.module';
import { ClarityRoutingService } from './services/clarity-routing.service';
import { ErrorsHandler } from 'farmcloud-core';

@NgModule({
  declarations: [],
  imports: [CommonModule, AppRoutingModule],
  providers: [ClarityRoutingService, ErrorsHandler],
})
export class ClarityModule {
  constructor(private clarityRoutingService: ClarityRoutingService) {}
}
