import { ElementRef } from '@angular/core';
var OnlyDecimalDirective = /** @class */ (function () {
    function OnlyDecimalDirective(el) {
        this.el = el;
        this.allowDecimals = true;
    }
    OnlyDecimalDirective.prototype.onKeyDown = function (event) {
        if (this.allowDecimals) {
            return;
        }
        if (OnlyDecimalDirective.specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        var current = this.el.nativeElement.value;
        var next = current.concat(event.key);
        if (next && !String(next).match(OnlyDecimalDirective.regex)) {
            event.preventDefault();
        }
    };
    OnlyDecimalDirective.regex = new RegExp(/^\d+$/);
    OnlyDecimalDirective.specialKeys = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight'];
    return OnlyDecimalDirective;
}());
export { OnlyDecimalDirective };
