/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./load-user-data.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./load-user-data.component";
import * as i5 from "../../auth/services/auth.service";
import * as i6 from "../../auth/services/auth-store.service";
import * as i7 from "../../auth/user-context.service";
import * as i8 from "../../contact/services/contact-core.service";
import * as i9 from "@angular/router";
var styles_LoadUserDataComponent = [i0.styles];
var RenderType_LoadUserDataComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoadUserDataComponent, data: {} });
export { RenderType_LoadUserDataComponent as RenderType_LoadUserDataComponent };
function View_LoadUserDataComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "circle-spinner loading-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "spinner-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("APPLICATION.loadingData")); _ck(_v, 4, 0, currVal_0); }); }
function View_LoadUserDataComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "mt-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "font-weight-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 7, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " ", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " ", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" +48 730 834 686"])), (_l()(), i1.ɵeld(9, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.ngIf.name; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.ngIf.city; var currVal_2 = _v.context.ngIf.buildingNumber; _ck(_v, 4, 0, currVal_1, currVal_2); var currVal_3 = _v.context.ngIf.postCode; var currVal_4 = _v.context.ngIf.commune; _ck(_v, 6, 0, currVal_3, currVal_4); var currVal_5 = _v.context.ngIf.contactMainEmail; _ck(_v, 10, 0, currVal_5); }); }
function View_LoadUserDataComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "page-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "alert alert-dark"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_LoadUserDataComponent_3)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 3, "button", [["class", "btn btn-primary mt-5 w-100"]], [[8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onLogout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(9, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.ownerContact$)); _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("APPLICATION.ERRORS.loadingData")); _ck(_v, 2, 0, currVal_0); var currVal_2 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("APPLICATION.NAVBAR.logout")), ""); _ck(_v, 7, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("APPLICATION.NAVBAR.logout")); _ck(_v, 9, 0, currVal_3); }); }
export function View_LoadUserDataComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "page-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoadUserDataComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoadUserDataComponent_2)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.hasLoadedError == false); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.hasLoadedError; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_LoadUserDataComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-load-user-data", [], null, null, null, View_LoadUserDataComponent_0, RenderType_LoadUserDataComponent)), i1.ɵdid(1, 114688, null, 0, i4.LoadUserDataComponent, [i5.AuthService, i6.AuthStoreService, i7.UserContextService, i8.ContactCoreService, i9.ActivatedRoute, i9.Router, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoadUserDataComponentNgFactory = i1.ɵccf("app-load-user-data", i4.LoadUserDataComponent, View_LoadUserDataComponent_Host_0, {}, {}, []);
export { LoadUserDataComponentNgFactory as LoadUserDataComponentNgFactory };
