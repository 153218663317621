import { EventEmitter } from '@angular/core';
var FormButtonsComponent = /** @class */ (function () {
    function FormButtonsComponent() {
        this.isSubmitDisabled = false;
        this.cancelClick = new EventEmitter();
    }
    FormButtonsComponent.prototype.onCancelClick = function () {
        this.cancelClick.emit();
    };
    return FormButtonsComponent;
}());
export { FormButtonsComponent };
