import { OnInit } from '@angular/core';
import { LoggerService, SpinnerService, TranslateService } from 'farmcloud-core';
var AppComponent = /** @class */ (function () {
    function AppComponent(translateService, loggerService, spinnerService) {
        this.translateService = translateService;
        this.loggerService = loggerService;
        this.spinnerService = spinnerService;
        this.isLoading$ = this.spinnerService.status$;
    }
    AppComponent.prototype.ngOnInit = function () {
        this.translateService.initialize();
        this.loggerService.initialize();
    };
    return AppComponent;
}());
export { AppComponent };
