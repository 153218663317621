import * as tslib_1 from "tslib";
import { AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { InputBaseComponent } from '../input.base';
var InputHtmlComponent = /** @class */ (function (_super) {
    tslib_1.__extends(InputHtmlComponent, _super);
    function InputHtmlComponent(changeDetRef) {
        var _this = _super.call(this) || this;
        _this.changeDetRef = changeDetRef;
        _this.editorModules = {
            toolbar: [
                ['bold', 'italic', 'underline', 'strike'],
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ align: [] }],
                ['clean'],
            ],
        };
        return _this;
    }
    InputHtmlComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.inputControl.valueChanges.subscribe(function (data) {
            _this.changeDetRef.detectChanges();
        });
    };
    return InputHtmlComponent;
}(InputBaseComponent));
export { InputHtmlComponent };
