import { NotificationService } from '@farm-portal/core/layout/navbar/navbar-notifications/notification.service';
var NavbarNotificationsComponent = /** @class */ (function () {
    function NavbarNotificationsComponent(notificationService) {
        this.notificationService = notificationService;
    }
    NavbarNotificationsComponent.prototype.onNotificationClick = function (notification) {
        return this.notificationService.markAsRead(notification);
    };
    NavbarNotificationsComponent.prototype.onMarkAllNotificationsAsReadClick = function () {
        this.notificationService.markAllAsRead();
    };
    return NavbarNotificationsComponent;
}());
export { NavbarNotificationsComponent };
