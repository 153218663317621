import * as tslib_1 from "tslib";
import { ChangeDetectorRef, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { InputBaseComponent } from '../input.base';
var InputNgSelectComponent = /** @class */ (function (_super) {
    tslib_1.__extends(InputNgSelectComponent, _super);
    function InputNgSelectComponent(translateService, cdRef) {
        var _this = _super.call(this) || this;
        _this.translateService = translateService;
        _this.cdRef = cdRef;
        _this.options = [];
        _this.bindLabel = 'label';
        _this.bindValue = 'value';
        _this.clearable = true;
        _this.groupBy = '';
        _this.multiple = false;
        _this.searchByField = '';
        _this.translateKey = '';
        _this.isDictionary = false;
        _this.tooltipTranslateKey = null;
        _this.readonly = undefined;
        _this.customSearchFn = function (term, item) {
            term = term.toLowerCase();
            var value = item[_this.bindLabel];
            var displayTextValue = _this.get(value);
            return displayTextValue.toLowerCase().indexOf(term) > -1;
        };
        _this.onLangChangeSubscription = _this.translateService.onLangChange.subscribe(function () {
            _this.cdRef.detectChanges();
        });
        return _this;
    }
    InputNgSelectComponent.prototype.ngOnDestroy = function () {
        if (this.onLangChangeSubscription) {
            this.onLangChangeSubscription.unsubscribe();
        }
    };
    InputNgSelectComponent.prototype.getDisplayText = function (item) {
        var value = item[this.bindLabel];
        if (this.translateKey) {
            return this.translateKey + "." + value;
        }
        return value;
    };
    InputNgSelectComponent.prototype.getDisplayGroupText = function (item) {
        var value = item[this.groupBy];
        if (this.translateKey) {
            return this.translateKey + "." + value;
        }
        return value;
    };
    InputNgSelectComponent.prototype.get = function (value) {
        if (this.translateKey) {
            return this.translateService.instant(this.translateKey + "." + value);
        }
        if (this.isDictionary) {
            return this.translateService.instant("" + value);
        }
        return value;
    };
    return InputNgSelectComponent;
}(InputBaseComponent));
export { InputNgSelectComponent };
