import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppConfigurationService } from 'farmcloud-core';

@Injectable({
  providedIn: 'root',
})
export class ContactProxyService {
  private readonly url = `${this.config.configuration.api_url}/api/ContactForm`;

  constructor(
    private readonly httpClient: HttpClient,
    private config: AppConfigurationService,
  ) {}

  public sendMessage(message: string): Observable<void> {
    return this.httpClient.post<void>(`${this.url}/message`, { message: message });
  }

  public removeAccount(): Observable<void> {
    return this.httpClient.post<void>(`${this.url}/removeAccount`, {});
  }
}
