import { Component, Input } from '@angular/core';

import { InputBaseComponent } from '../input.base';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-input-datepicker',
  templateUrl: './input-datepicker.component.html',
  styleUrls: ['./../input.base.scss', './input-datepicker.component.scss'],
})
export class InputDatepickerComponent extends InputBaseComponent {
  @Input() maxDate: NgbDate | null;
  public static ngbDateToString(date: NgbDate): string {
    if (!date) {
      return null;
    }

    return InputDatepickerComponent.ngbDateToDate(date).toUTCString();
  }

  public static ngbDateToDate(date: NgbDate): Date {
    if (!date) {
      return null;
    }

    // NgbDate starts the month index  from 1
    return new Date(date.year, date.month - 1, date.day, 0, 0);
  }

  public static stringToNgbDate(value: string): NgbDate | null {
    if (!value) {
      return null;
    }

    if (value.length === 0) {
      return null;
    }

    // Date's getMonth() method returns the month index starting from 0 (January)
    // NgbDate expects the month index starting from 1
    const date = new Date(value);
    return new NgbDate(date.getUTCFullYear(), date.getUTCMonth() + 1, date.getUTCDate());
  }
}
