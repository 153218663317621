/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./empty-state.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./empty-state.component";
var styles_EmptyStateComponent = [i0.styles];
var RenderType_EmptyStateComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmptyStateComponent, data: {} });
export { RenderType_EmptyStateComponent as RenderType_EmptyStateComponent };
function View_EmptyStateComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.btnText; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.btnIcon, ""); _ck(_v, 3, 0, currVal_1); }); }
function View_EmptyStateComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "empty-state py-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "Empty State"], ["class", "empty-state-img"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h5", [["class", "empty-state-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "empty-state-content mb-3"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EmptyStateComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.shouldShowButton(); _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("./assets/images/" + _co.assetSrc); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.subtitle; _ck(_v, 5, 0, currVal_2); }); }
function View_EmptyStateComponent_3(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0), (_l()(), i1.ɵand(0, null, null, 0))], null, null); }
export function View_EmptyStateComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_EmptyStateComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["content", 2]], null, 0, null, View_EmptyStateComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shouldDisplayEmptyState(); var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_EmptyStateComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-empty-state", [], null, null, null, View_EmptyStateComponent_0, RenderType_EmptyStateComponent)), i1.ɵdid(1, 49152, null, 0, i3.EmptyStateComponent, [], null, null)], null, null); }
var EmptyStateComponentNgFactory = i1.ɵccf("app-empty-state", i3.EmptyStateComponent, View_EmptyStateComponent_Host_0, { title: "title", subtitle: "subtitle", btnText: "btnText", btnIcon: "btnIcon", showButton: "showButton", assetSrc: "assetSrc", displayModel: "displayModel" }, { clicked: "clicked" }, ["*"]);
export { EmptyStateComponentNgFactory as EmptyStateComponentNgFactory };
