import { HttpParams } from '@angular/common/http';
var BaseProxyService = /** @class */ (function () {
    function BaseProxyService() {
    }
    BaseProxyService.prototype.prepareQueryGridifyParams = function (requestList) {
        var params = new HttpParams();
        if ('page' in requestList && 'pageSize' in requestList) {
            params = this.preparePaginationQueryParams(params, requestList.page, requestList.pageSize);
        }
        params = this.prepareOrderingQueryParams(params, requestList.orderBy);
        params = this.prepareFilterByQueryParams(params, requestList.filter);
        return params;
    };
    BaseProxyService.prototype.prepareFilterByQueryParams = function (httpParams, filterList) {
        var _this = this;
        if (!filterList) {
            return httpParams;
        }
        var filterBy = '';
        filterList.forEach(function (item) {
            var value = item.value;
            if (filterBy.length > 0) {
                filterBy += ',';
            }
            if (value === null) {
                filterBy += "" + item.field + item.condition;
                return;
            }
            if (_this.isValidInCondition(item)) {
                var values = item.value.map(function (val) { return _this.mapValueToString(val); }).join(';');
                filterBy += item.field + " " + item.condition + " " + values;
                return;
            }
            if (typeof value === 'string') {
                value = value.replace(/([(),|\\]|\/i)/g, '\\$1');
            }
            if (value instanceof Date) {
                value = value.toISOString();
            }
            filterBy += "" + item.field + item.condition + value;
        });
        var params = httpParams;
        if (filterBy.length > 0) {
            params = params.set('FilterBy', filterBy);
        }
        return params;
    };
    BaseProxyService.prototype.isValidInCondition = function (filterRequest) {
        if (filterRequest.condition !== '#In') {
            return false;
        }
        return Array.isArray(filterRequest.value) && filterRequest.value.every(function (item) { return typeof item === 'string' || item instanceof Date; });
    };
    BaseProxyService.prototype.preparePaginationQueryParams = function (httpParams, page, pageSize) {
        var params = httpParams;
        if (page != null) {
            params = params.set('page', String(page));
        }
        if (pageSize != null) {
            params = params.set('pageSize', String(pageSize));
        }
        return params;
    };
    BaseProxyService.prototype.prepareOrderingQueryParams = function (httpParams, orderBy) {
        if (!orderBy) {
            return httpParams;
        }
        var orderByParams = '';
        orderBy.forEach(function (x) {
            if (!x) {
                return;
            }
            var value = x.field;
            if (!value) {
                return;
            }
            if (orderByParams.length > 0) {
                orderByParams += ',';
            }
            orderByParams += value + " " + x.sortOrder;
        });
        var params = httpParams;
        if (orderByParams.length > 0) {
            params = params.set('OrderBy', orderByParams);
        }
        return params;
    };
    BaseProxyService.prototype.mapValueToString = function (value) {
        if (value instanceof Date) {
            return value.toISOString();
        }
        return value;
    };
    return BaseProxyService;
}());
export { BaseProxyService };
