import { OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivationStart, Router } from '@angular/router';
import { SupportedLanguages, TranslateModuleOptions, initAppConfiguration, } from 'farmcloud-core';
import { TranslationInitializationService } from './translation-initialization.service';
var DEFAULT_TRANSLATE_MODULE_OPTIONS_CONFIG = {
    defaultLanguage: SupportedLanguages.PL,
};
export function initializeTranslations(translationInitService) {
    return function () {
        return translationInitService.initializeTranslations();
    };
}
var ɵ0 = initAppConfiguration, ɵ1 = DEFAULT_TRANSLATE_MODULE_OPTIONS_CONFIG;
var AppModule = /** @class */ (function () {
    function AppModule(router, titleService) {
        var _this = this;
        this.router = router;
        this.titleService = titleService;
        //set title of each route that has data title provided
        this.routerSub = this.router.events.subscribe(function (data) {
            if (data instanceof ActivationStart && data.snapshot.data['title']) {
                var title = data.snapshot.data['title'];
                _this.titleService.setTitle(title + " - FarmPortal");
            }
        });
    }
    AppModule.prototype.ngOnDestroy = function () {
        if (this.routerSub) {
            this.routerSub.unsubscribe();
        }
    };
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1 };
