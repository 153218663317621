import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IdpAuthGuard } from './idp-auth.guard';
import { IdpOrganizationGuard } from './idp-organization.guard';
import { AuthService } from './services/auth.service';
import { AuthProxyService } from './services/auth-proxy.service';
import { AuthStoreService } from './services/auth-store.service';
import { AuthInterceptorProvider } from './services/auth-interceptor.service';
import { IdpRucipGuard } from '@farm-portal/core/auth/idp-rucip.guard';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [AuthService, AuthProxyService, AuthStoreService, AuthInterceptorProvider, IdpAuthGuard, IdpOrganizationGuard, IdpRucipGuard],
})
export class AuthModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: AuthModule,
      providers: [
        AuthService,
        AuthProxyService,
        AuthStoreService,
        AuthInterceptorProvider,
        IdpAuthGuard,
        IdpOrganizationGuard,
        IdpRucipGuard,
      ],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: AuthModule) {
    if (parentModule) {
      throw new Error('AuthModule is already loaded. Import it in the AppModule only');
    }
  }
}
