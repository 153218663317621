import { Component, Input, OnDestroy, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { customAnimations } from '../animations/custom-animations';
import { RouteGroup, RouteInfo } from './sidebar.metadata';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: customAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnDestroy {
  @Output() logoutEmit = new EventEmitter<void>();

  @Input() public menuGroups: RouteGroup[];
  @Input() public userClaims: string[];
  @Input() public user: string;

  depth: number;
  activeTitles: string[] = [];
  expanded: boolean;

  @Input() logoUrl: string;

  private destroy$ = new Subject<void>();

  constructor(public translate: TranslateService) {
    if (this.depth === undefined) {
      this.depth = 0;
      this.expanded = true;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  logout(): void {
    this.logoutEmit.emit();
  }

  toggleSlideInOut() {
    this.expanded = !this.expanded;
  }

  handleToggle(titles) {
    this.activeTitles = titles;
  }

  hasSubmenuAndHasNotExternalLink(item: RouteInfo): boolean {
    return item.submenu.length > 0 && !item.isExternalLink && this.isMenuItemForComponent(item);
  }

  hasNotSubmenuAndExternalLink(item: RouteInfo): boolean {
    return item.submenu.length === 0 && !item.isExternalLink && this.isMenuItemForComponent(item);
  }

  hasMenuExternalLink(item: RouteInfo): boolean {
    return item.isExternalLink && this.isMenuItemForComponent(item);
  }

  isMenuItemForComponent(item: RouteInfo): boolean {
    if (item.components === undefined) {
      return true;
    }

    if (item.components.length === 0) {
      return true;
    }

    if (this.userClaims === undefined) {
      return true;
    }

    return item.components.some(component => {
      return this.userClaims.some(claim => {
        return claim.startsWith(component);
      });
    });
  }

  isMenuGroupForComponent(item: RouteGroup): boolean {
    if (item.components === undefined) {
      return true;
    }

    if (item.components.length === 0) {
      return true;
    }

    if (this.userClaims === undefined) {
      return true;
    }

    return item.components.some(component => {
      return this.userClaims.some(claim => {
        return claim.startsWith(component);
      });
    });
  }
}
