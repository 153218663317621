import * as tslib_1 from "tslib";
import { AfterViewInit, ChangeDetectorRef, Injector, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { InputMapService } from './services/input-map.service';
import { AnchorPointX, AnchorPointY } from '@farm-portal/shared/modules/form-inputs/inputs/input-map/models/input-map.consts';
import { EDITABLE_ZONE_COLOR, MAP_CONTOUR_COLOR } from '@farm-portal/shared/modules/layout/pure-google-maps/common/config/map-config';
import { GoogleMapService } from '@farm-portal/shared/modules/layout/pure-google-maps/common/services/google-map.service';
import { v4 as uuid } from 'uuid';
var InputMapComponent = /** @class */ (function () {
    function InputMapComponent(injector, googleMapService, inputMapService, cdRef) {
        this.injector = injector;
        this.googleMapService = googleMapService;
        this.inputMapService = inputMapService;
        this.cdRef = cdRef;
        this.height = '400px';
        this.value = [];
        this.value$ = new BehaviorSubject([]);
        this.onChange = function () { };
        this.onTouched = function () { };
        this.isDisabled$ = new BehaviorSubject(false);
        this.map$ = new BehaviorSubject(null);
        this.mapOptions$ = new BehaviorSubject(null);
        this.drawnPolygons = [];
        this.drawnMarkers = [];
    }
    Object.defineProperty(InputMapComponent.prototype, "mapOptions", {
        set: function (options) {
            this.mapOptions$.next(options);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InputMapComponent.prototype, "isPolygonDrawingActive", {
        get: function () {
            return this.drawingManager && this.drawingManager.getDrawingMode() === google.maps.drawing.OverlayType.POLYGON;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InputMapComponent.prototype, "isMarkerDrawingActive", {
        get: function () {
            return this.drawingManager && this.drawingManager.getDrawingMode() === google.maps.drawing.OverlayType.MARKER;
        },
        enumerable: true,
        configurable: true
    });
    InputMapComponent.prototype.ngOnDestroy = function () {
        if (this.drawingOnMapSubscription) {
            this.drawingOnMapSubscription.unsubscribe();
        }
        if (this.centerSubscription) {
            this.centerSubscription.unsubscribe();
        }
        if (this.drawingManagerListener) {
            this.drawingManagerListener.remove();
        }
    };
    InputMapComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initializeMapSubscription();
        if (this.centerPoint$) {
            this.centerSubscription = combineLatest([this.map$, this.centerPoint$]).subscribe(function (_a) {
                var map = _a[0], center = _a[1];
                if (center) {
                    _this.googleMapService.centerMap(map, new google.maps.LatLng(center.lat, center.lng));
                }
            });
        }
    };
    InputMapComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        setTimeout(function () {
            _this.ngControl = _this.injector.get(NgControl, null);
            if (_this.ngControl) {
                _this.ngControl.valueAccessor = _this;
            }
            else {
                console.error('NgControl is not available');
            }
        });
    };
    InputMapComponent.prototype.writeValue = function (value) {
        if (value) {
            this.clearFeatureProperties(value);
            this.value = value;
            this.value$.next(value);
        }
    };
    InputMapComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    InputMapComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    InputMapComponent.prototype.setDisabledState = function (isDisabled) {
        this.isDisabled = isDisabled;
        this.isDisabled$.next(isDisabled);
    };
    InputMapComponent.prototype.updateValue = function (updatedFeatures) {
        this.value = updatedFeatures;
        this.onChange(this.value);
        this.onTouched();
    };
    InputMapComponent.prototype.onMapInitialized = function ($event) {
        this.map$.next($event);
    };
    InputMapComponent.prototype.setDrawingMode = function (mode) {
        if (this.drawingManager) {
            this.drawingManager.setDrawingMode(mode);
        }
    };
    InputMapComponent.prototype.isDrawingEnabled = function () {
        if (!this.mapOptions$.value.isDrawingEnabled) {
            return false;
        }
        if (this.mapOptions$.value.isDrawingMarkersEnabled) {
            return true;
        }
        if (this.mapOptions$.value.isDrawingPolygonEnabled) {
            return true;
        }
        return false;
    };
    InputMapComponent.prototype.initializeMapSubscription = function () {
        var _this = this;
        this.drawingOnMapSubscription = combineLatest([this.map$, this.mapOptions$, this.value$, this.isDisabled$]).subscribe(function (_a) {
            var map = _a[0], mapOptions = _a[1], value = _a[2], _ = _a[3];
            if (!map) {
                return;
            }
            if (mapOptions) {
                _this.initializeDrawingManager(map, mapOptions);
            }
            _this.clearExistingFeatures();
            if (value && value.length) {
                _this.drawFeatures(map, value, mapOptions);
            }
        });
    };
    InputMapComponent.prototype.drawFeatures = function (map, features, mapOptions) {
        var _this = this;
        var bounds = new google.maps.LatLngBounds();
        features.forEach(function (feature) {
            if (feature.geometry.type === 'Polygon') {
                _this.createAndAddPolygon(map, feature, bounds, mapOptions);
            }
            if (feature.geometry.type === 'Point') {
                _this.createAndAddMarker(map, feature, bounds);
            }
        });
        this.googleMapService.fitBounds(map, bounds);
    };
    InputMapComponent.prototype.updateValueWithDrawnMarkersAndDrawnPolygons = function () {
        this.updateValue(this.drawnPolygons.map(function (p) { return p.geoPolygon; }).concat(this.drawnMarkers.map(function (p) { return p.geoPoint; })));
    };
    // DRAWING MANAGER INITIALIZATION
    InputMapComponent.prototype.initializeDrawingManager = function (map, options) {
        if (!options.isDrawingEnabled) {
            return;
        }
        var drawingModes = [];
        if (options.isDrawingPolygonEnabled) {
            drawingModes.push(google.maps.drawing.OverlayType.POLYGON);
        }
        if (options.isDrawingMarkersEnabled) {
            drawingModes.push(google.maps.drawing.OverlayType.MARKER);
        }
        this.drawingManager = this.googleMapService.createDrawingManager(map, {
            drawingControl: false,
            drawingControlOptions: {
                drawingModes: drawingModes,
            },
            polygonOptions: {
                strokeColor: options.polygonFillStrokeColor || EDITABLE_ZONE_COLOR,
                fillColor: options.polygonFillStrokeColor || EDITABLE_ZONE_COLOR,
            },
        });
        if (!this.drawingManager) {
            return;
        }
        this.initializeDrawingManagerListeners(map, this.drawingManager, options);
    };
    InputMapComponent.prototype.initializeDrawingManagerListeners = function (map, drawingManager, options) {
        var _this = this;
        this.drawingManagerListener = drawingManager.addListener('overlaycomplete', function (event) {
            if (event.type === google.maps.drawing.OverlayType.POLYGON) {
                _this.handleAddDrawedGoogleMapsPolygon(event, map, drawingManager, options);
            }
            if (event.type === google.maps.drawing.OverlayType.MARKER) {
                _this.handleAddDrawedGoogleMapsMarker(event, map, drawingManager);
            }
            _this.cdRef.detectChanges();
        });
    };
    // DRAWING GOOGLE MAPS FEATURES AND DRAWING CURRENT FEATURES
    InputMapComponent.prototype.handleAddDrawedGoogleMapsPolygon = function (event, map, drawingManager, options) {
        var poly = event.overlay;
        poly.setMap(null);
        if (drawingManager.getDrawingMode() === null) {
            return;
        }
        var properties = {
            editable: !this.isDisabled || false,
            strokeColor: options.polygonFillStrokeColor || MAP_CONTOUR_COLOR,
            fillColor: options.polygonFillStrokeColor || MAP_CONTOUR_COLOR,
        };
        var polygon = this.googleMapService.createPolygon(map, tslib_1.__assign({ paths: poly.getPath() }, properties));
        var polygonId = uuid();
        polygon.set('id', polygonId);
        var geoFeature = this.googleMapService.mapPolygonToFeature(polygon);
        var centerPoint = this.googleMapService.getCenterPoint(polygon);
        geoFeature.properties['center'] = centerPoint;
        var drawnPolygonFeature = {
            id: polygonId,
            googleMapsPolygon: polygon,
            geoPolygon: geoFeature,
        };
        this.initializePolygonListeners(map, polygon, drawnPolygonFeature);
        this.drawnPolygons.push(drawnPolygonFeature);
        this.setDrawingMode(null);
        this.updateValueWithDrawnMarkersAndDrawnPolygons();
    };
    InputMapComponent.prototype.handleAddDrawedGoogleMapsMarker = function (event, map, drawingManager) {
        var marker = event.overlay;
        marker.setMap(null);
        if (drawingManager.getDrawingMode() === null) {
            return;
        }
        var properties = {
            draggable: !this.isDisabled || false,
            icon: {
                url: './assets/custom-map-pin.svg',
                anchor: new google.maps.Point(AnchorPointX, AnchorPointY),
            },
            animation: google.maps.Animation.DROP,
        };
        var drawedMarker = this.googleMapService.createMarker(map, tslib_1.__assign({ position: marker.getPosition() }, properties));
        var markerId = uuid();
        drawedMarker.set('id', markerId);
        var geoFeature = this.googleMapService.mapMarkerToFeature(marker);
        var centerPoint = this.googleMapService.getCenterPoint(drawedMarker);
        geoFeature.properties['center'] = centerPoint;
        var drawnFeatureMarker = {
            id: markerId,
            googleMapsMarker: drawedMarker,
            geoPoint: geoFeature,
        };
        this.initializeMarkerListeners(map, drawedMarker, drawnFeatureMarker);
        this.drawnMarkers.push(drawnFeatureMarker);
        this.setDrawingMode(null);
        this.updateValueWithDrawnMarkersAndDrawnPolygons();
    };
    InputMapComponent.prototype.createAndAddPolygon = function (map, feature, bounds, mapOptions) {
        var polygonPaths = this.googleMapService.mapPolygonGeometryToPaths(feature.geometry);
        var googleMapsPolygon = this.googleMapService.createPolygon(map, {
            paths: polygonPaths,
            editable: !this.isDisabled,
            fillColor: mapOptions.polygonFillStrokeColor || MAP_CONTOUR_COLOR,
            strokeColor: mapOptions.polygonFillStrokeColor || MAP_CONTOUR_COLOR,
            fillOpacity: 0.2,
        });
        var polygonId = uuid();
        googleMapsPolygon.set('id', polygonId);
        googleMapsPolygon.getPath().forEach(function (x) {
            bounds.extend(x);
        });
        var centerPoint = this.googleMapService.getCenterPoint(googleMapsPolygon);
        feature.properties['center'] = centerPoint;
        var drawnPolygonFeature = {
            id: polygonId,
            googleMapsPolygon: googleMapsPolygon,
            geoPolygon: feature,
        };
        this.drawnPolygons.push(drawnPolygonFeature);
        this.initializePolygonListeners(map, googleMapsPolygon, drawnPolygonFeature);
    };
    InputMapComponent.prototype.createAndAddMarker = function (map, feature, bounds) {
        var position = new google.maps.LatLng(feature.geometry.coordinates[1], feature.geometry.coordinates[0]);
        var googleMapsMarker = this.googleMapService.createMarker(map, {
            position: position,
            draggable: !this.isDisabled,
            icon: {
                url: './assets/custom-map-pin.svg',
                anchor: new google.maps.Point(AnchorPointX, AnchorPointY),
            },
        });
        var markerId = uuid();
        googleMapsMarker.set('id', markerId);
        bounds.extend(googleMapsMarker.getPosition());
        var centerPoint = this.googleMapService.getCenterPoint(googleMapsMarker);
        feature.properties['center'] = centerPoint;
        var drawnMarkerFeature = {
            id: markerId,
            googleMapsMarker: googleMapsMarker,
            geoPoint: feature,
        };
        this.drawnMarkers.push(drawnMarkerFeature);
        this.initializeMarkerListeners(map, googleMapsMarker, drawnMarkerFeature);
    };
    // LISTENERS ACTIONS
    InputMapComponent.prototype.initializePolygonListeners = function (map, polygon, drawnPolygonFeature) {
        var _this = this;
        google.maps.event.addListener(polygon.getPath(), 'set_at', function () {
            _this.updatePolygonFeature(drawnPolygonFeature);
        });
        google.maps.event.addListener(polygon.getPath(), 'insert_at', function () {
            _this.updatePolygonFeature(drawnPolygonFeature);
        });
        if (this.isDisabled) {
            return;
        }
        this.setPopupForPolygon(map, polygon);
    };
    InputMapComponent.prototype.updatePolygonFeature = function (drawnPolygonFeature) {
        drawnPolygonFeature.geoPolygon = this.googleMapService.mapPolygonToFeature(drawnPolygonFeature.googleMapsPolygon);
        this.updateValueWithDrawnMarkersAndDrawnPolygons();
    };
    InputMapComponent.prototype.initializeMarkerListeners = function (map, marker, drawnMarkerFeature) {
        var _this = this;
        google.maps.event.addListener(marker, 'dragend', function () {
            _this.updateMarkerFeature(drawnMarkerFeature);
        });
        if (this.isDisabled) {
            return;
        }
        this.setPopupForMarker(map, marker);
    };
    InputMapComponent.prototype.updateMarkerFeature = function (drawnMarkerFeature) {
        drawnMarkerFeature.geoPoint = this.googleMapService.mapMarkerToFeature(drawnMarkerFeature.googleMapsMarker);
        this.updateValueWithDrawnMarkersAndDrawnPolygons();
    };
    InputMapComponent.prototype.clearExistingFeatures = function () {
        this.googleMapService.clearPolygons();
        this.googleMapService.clearMarkers();
        this.drawnPolygons = [];
        this.drawnMarkers = [];
    };
    InputMapComponent.prototype.setPopupForMarker = function (map, marker) {
        var infoWindow = new google.maps.InfoWindow({
            content: this.inputMapService.buildRemoveInfoWindow(marker.get('id')),
            pixelOffset: new google.maps.Size(0, -50, 'px', 'px'),
        });
        this.setListenerForInfoPopup(marker.get('id'), infoWindow);
        this.inputMapService.setOnClickMarkerListener(map, marker, infoWindow);
    };
    InputMapComponent.prototype.setPopupForPolygon = function (map, polygon) {
        var infoWindow = new google.maps.InfoWindow({
            content: this.inputMapService.buildRemoveInfoWindow(polygon.get('id')),
            pixelOffset: new google.maps.Size(0, -50, 'px', 'px'),
        });
        this.setListenerForInfoPopup(polygon.get('id'), infoWindow);
        this.inputMapService.setOnClickPolygonListener(map, polygon, infoWindow);
    };
    InputMapComponent.prototype.setListenerForInfoPopup = function (id, infowindow) {
        var _this = this;
        if (this.isDisabled) {
            return;
        }
        google.maps.event.addListener(infowindow, 'domready', function () {
            document.getElementById(id + "_Remove").addEventListener('click', function () {
                _this.removeFeatureFromMap(id);
                infowindow.close();
            });
        });
    };
    InputMapComponent.prototype.removeFeatureFromMap = function (id) {
        var markerToRemove = this.drawnMarkers.find(function (x) { return x.id === id; });
        if (markerToRemove) {
            this.drawnMarkers = this.drawnMarkers.filter(function (x) { return x.id !== id; });
            markerToRemove.googleMapsMarker.setMap(null);
            this.updateValueWithDrawnMarkersAndDrawnPolygons();
        }
        var polygonToRemove = this.drawnPolygons.find(function (x) { return x.id === id; });
        if (polygonToRemove) {
            this.drawnPolygons = this.drawnPolygons.filter(function (x) { return x.id !== id; });
            polygonToRemove.googleMapsPolygon.setMap(null);
            this.updateValueWithDrawnMarkersAndDrawnPolygons();
        }
        this.cdRef.detectChanges();
    };
    // HELPERS
    InputMapComponent.prototype.isPolygonLimitReached = function (mapOptions) {
        if (!mapOptions) {
            return true;
        }
        if (!mapOptions.isDrawingPolygonEnabled) {
            return true;
        }
        if (mapOptions.maxAcceptablePolygonsCount && this.drawnPolygons && this.drawnPolygons.length >= mapOptions.maxAcceptablePolygonsCount) {
            return true;
        }
        return false;
    };
    InputMapComponent.prototype.isMarkersLimitReached = function (mapOptions) {
        if (!mapOptions) {
            return true;
        }
        if (!mapOptions.isDrawingMarkersEnabled) {
            return true;
        }
        if (mapOptions.maxAcceptableMarkersCount && this.drawnMarkers && this.drawnMarkers.length >= mapOptions.maxAcceptableMarkersCount) {
            return true;
        }
        return false;
    };
    InputMapComponent.prototype.clearFeatureProperties = function (feature) {
        feature.forEach(function (feature) { return (feature.properties = {}); });
    };
    return InputMapComponent;
}());
export { InputMapComponent };
