import { BehaviorSubject, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { CurrentUserProxyService } from './current-user-proxy.service';
import { AuthService } from './services/auth.service';
import { AuthStoreService } from './services/auth-store.service';
import * as i0 from "@angular/core";
import * as i1 from "./current-user-proxy.service";
import * as i2 from "./services/auth.service";
import * as i3 from "./services/auth-store.service";
var UserContextService = /** @class */ (function () {
    function UserContextService(currentUserProxyService, authService, authStore) {
        this.currentUserProxyService = currentUserProxyService;
        this.authService = authService;
        this.authStore = authStore;
        this.userOrganizations = new BehaviorSubject([]);
        this.currentUserOrganization = new BehaviorSubject(null);
        this.currentUserOrganization$ = this.currentUserOrganization.asObservable();
        this.userOrganizations$ = this.userOrganizations.asObservable();
    }
    Object.defineProperty(UserContextService.prototype, "currentOrganizationId", {
        get: function () {
            return this.currentUserOrganization.value && this.currentUserOrganization.value.id;
        },
        enumerable: true,
        configurable: true
    });
    UserContextService.prototype.clean = function () {
        this.userOrganizations.next(null);
        this.currentUserOrganization.next(null);
    };
    UserContextService.prototype.tryGetCurrentUserData$ = function () {
        return this.getCurrentUserOrganization$();
    };
    UserContextService.prototype.getCurrentUserOrganization$ = function () {
        var _this = this;
        return this.currentUserOrganization$.pipe(switchMap(function (currentUserOrganization) {
            if (currentUserOrganization != null) {
                return of(currentUserOrganization);
            }
            else {
                return _this.refresh$();
            }
        }), take(1));
    };
    UserContextService.prototype.selectCurrentUserOrganization = function (selectedOrganization) {
        var _this = this;
        return this.authService.refreshTokenOnOrganizationChange(selectedOrganization.id).pipe(tap(function () {
            _this.currentUserOrganization.next(selectedOrganization);
        }));
    };
    UserContextService.prototype.refresh$ = function () {
        var _this = this;
        return this.currentUserProxyService.getUserOrganizations().pipe(map(function (response) {
            if (response.length > 0) {
                _this.userOrganizations.next(response);
                var defaultOrganization = _this.getDefaultOrganization(response);
                _this.currentUserOrganization.next(defaultOrganization);
                return defaultOrganization;
            }
            throw new Error("Organization for user is not defined");
        }), take(1));
    };
    UserContextService.prototype.getDefaultOrganization = function (organizations) {
        if (organizations.length > 0) {
            var organizationId_1 = this.authStore.getUserOrganizationId;
            return organizations.find(function (x) { return x.id === organizationId_1; }) || organizations[0];
        }
        return null;
    };
    UserContextService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserContextService_Factory() { return new UserContextService(i0.ɵɵinject(i1.CurrentUserProxyService), i0.ɵɵinject(i2.AuthService), i0.ɵɵinject(i3.AuthStoreService)); }, token: UserContextService, providedIn: "root" });
    return UserContextService;
}());
export { UserContextService };
