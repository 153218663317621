/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "./submit-button.component";
var styles_SubmitButtonComponent = [];
var RenderType_SubmitButtonComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SubmitButtonComponent, data: {} });
export { RenderType_SubmitButtonComponent as RenderType_SubmitButtonComponent };
export function View_SubmitButtonComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["type", "submit"]], [[8, "disabled", 0]], null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", "\n"])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDisabled; _ck(_v, 0, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("APPLICATION.FORM.save")); _ck(_v, 1, 0, currVal_1); }); }
export function View_SubmitButtonComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-submit-button", [], null, null, null, View_SubmitButtonComponent_0, RenderType_SubmitButtonComponent)), i0.ɵdid(1, 49152, null, 0, i2.SubmitButtonComponent, [], null, null)], null, null); }
var SubmitButtonComponentNgFactory = i0.ɵccf("app-submit-button", i2.SubmitButtonComponent, View_SubmitButtonComponent_Host_0, { isDisabled: "isDisabled" }, {}, []);
export { SubmitButtonComponentNgFactory as SubmitButtonComponentNgFactory };
