export interface AuthPartner {
  loginType: string;
  partnerId: string;
  permissionComponent: string;
}

export const AUTH_PARTNERS: AuthPartner[] = [
  {
    partnerId: '523587b2-6586-4f5e-8987-399ac381550a',
    loginType: 'PFZ',
    permissionComponent: 'Organization.Partners.PFZ',
  },
];
