import { OnInit } from '@angular/core';
import { ROUTES } from '@farm-portal/app-routing.config';
import { AUTH_PARTNERS } from '@farm-portal/core/auth/models/auth-partners.const';
import { AuthStoreService } from '@farm-portal/core/auth/services/auth-store.service';
import { AuthService } from '@farm-portal/core/auth/services/auth.service';
import { UserContextService } from '@farm-portal/core/auth/user-context.service';
var FullLayoutComponent = /** @class */ (function () {
    function FullLayoutComponent(authService, authStore, userContextService) {
        this.authService = authService;
        this.authStore = authStore;
        this.userContextService = userContextService;
        this.iscollapsed = false;
        this.routes = ROUTES;
    }
    Object.defineProperty(FullLayoutComponent.prototype, "groupClaims", {
        get: function () {
            return this.authStore.getUserClaims;
        },
        enumerable: true,
        configurable: true
    });
    FullLayoutComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.user = this.authStore.getUserFullName;
        this.userContextService.currentUserOrganization$.subscribe(function (x) {
            _this.reseller = x != null ? x.reseller : null;
        });
    };
    FullLayoutComponent.prototype.logout = function () {
        var _this = this;
        var partner = AUTH_PARTNERS.find(function (partner) { return _this.hasAccess(partner.permissionComponent); });
        var partnerId = partner ? partner.partnerId : undefined;
        this.authService.logout();
        this.userContextService.clean();
        return this.authService.navigateToLoginPage(partnerId);
    };
    FullLayoutComponent.prototype.hasAccess = function (componentKey) {
        return this.groupClaims.some(function (claim) {
            return claim.startsWith(componentKey);
        });
    };
    return FullLayoutComponent;
}());
export { FullLayoutComponent };
