import { Injectable, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';

import { Loader } from 'google-maps';
import { AppConfigurationService } from 'farmcloud-core';
import { PureGoogleMapsServicesModule } from '../../pure-google-maps-services.module';
import DrawingManagerOptions = google.maps.drawing.DrawingManagerOptions;

/**
 * @deprecated
 * This map is obsolete and should not be used. Use GoogleMapService instead.
 */
@Injectable({
  providedIn: PureGoogleMapsServicesModule,
})
export class PureMapService {
  private static loadingScriptPromise: Promise<void> | null = null;
  private static isScriptLoaded: boolean = false;

  private googleMap: google.maps.Map;
  private drawingManager: google.maps.drawing.DrawingManager;
  private markers = [];
  private polygons = [];

  mapReady = new Subject<google.maps.Map>();

  constructor(private appConfigurationService: AppConfigurationService) {}

  async initMap(mapDiv: ElementRef, tilt: number) {
    await this.loadGoogleMaps();

    this.googleMap = new google.maps.Map(mapDiv.nativeElement, {
      center: { lat: 52.16045455774706, lng: 19.1162109375 },
      zoom: 7,
      mapTypeId: 'hybrid',
      tilt,
    });

    this.mapReady.next(this.googleMap);
    return this.googleMap;
  }

  get map(): google.maps.Map {
    this.assertMapInitialized();
    return this.googleMap;
  }

  setMapOptions(options: google.maps.MapOptions) {
    this.map.setOptions(options);
  }

  createMarker(options: google.maps.ReadonlyMarkerOptions): google.maps.Marker {
    const marker = new google.maps.Marker(options);
    marker.setMap(this.map);
    this.markers.push(marker);
    return marker;
  }

  createSingleMarker(options: google.maps.MarkerOptions): google.maps.Marker {
    this.markers.forEach(x => {
      x.setMap(null);
    });
    const marker = new google.maps.Marker(options);
    marker.setMap(this.map);
    this.markers.push(marker);
    return marker;
  }

  createPolygon(options: google.maps.PolygonOptions): google.maps.Polygon {
    const polygon = new google.maps.Polygon(options);
    polygon.setMap(this.map);

    this.polygons.push(polygon);
    return polygon;
  }

  clearPolygons() {
    this.polygons.forEach(polygon => this.removePolygon(polygon));
    this.polygons = [];
  }

  removePolygon(polygon: google.maps.Polygon) {
    polygon.setMap(null);
  }

  createPolyline(options: google.maps.PolylineOptions): google.maps.Polyline {
    const polyline = new google.maps.Polyline(options);
    polyline.setMap(this.map);

    return polyline;
  }

  createDrawingManager(options: DrawingManagerOptions): google.maps.drawing.DrawingManager {
    if (this.drawingManager) {
      this.drawingManager.setMap(null);
    }
    this.drawingManager = new google.maps.drawing.DrawingManager(options);
    this.drawingManager.setMap(this.map);
    return this.drawingManager;
  }

  setZoom(zoomValue: number): void {
    this.map.setZoom(zoomValue);
  }

  centerMap(value: google.maps.LatLng): void {
    this.map.setCenter(value);
  }

  fitBounds(bounds: google.maps.LatLngBounds): void {
    this.map.fitBounds(bounds);
    this.map.panToBounds(bounds);
  }

  overlayTiles(imageMapType: google.maps.ImageMapType) {
    this.map.overlayMapTypes.push(imageMapType);
  }

  clearMapTypes() {
    this.map.overlayMapTypes.clear();
  }

  clearMarkers() {
    this.markers.forEach(marker => {
      marker.setMap(null);
    });
    this.markers = [];
  }

  private async loadGoogleMaps(): Promise<void> {
    if (PureMapService.isScriptLoaded) {
      return;
    }

    if (PureMapService.loadingScriptPromise) {
      return PureMapService.loadingScriptPromise;
    }

    const apiKey = this.appConfigurationService.configuration.google_maps_api_key;
    const loader = new Loader(apiKey, {
      libraries: ['drawing', 'places', 'geometry'],
    });

    PureMapService.loadingScriptPromise = loader
      .load()
      .then(() => {
        PureMapService.isScriptLoaded = true;
      })
      .catch(error => {
        console.error('Error loading Google Maps script', error);
        throw error;
      });

    return PureMapService.loadingScriptPromise;
  }

  private assertMapInitialized() {
    if (this.googleMap == null) {
      throw new Error('Map is not initialized');
    }
  }
}
