import { FileFormControlComponent } from '@farm-portal/shared/modules/form-inputs/inputs/file-form-control/file-form-control.component';
import { InputHtmlComponent } from '@farm-portal/shared/modules/form-inputs/inputs/input-html/input-html.component';
import { FieldsetDisabledDirective } from '@farm-portal/shared/modules/form-inputs/inputs/input-ng-select/fieldset-disabled.directive';
import { OnlyDecimalDirective } from '@farm-portal/shared/modules/form-inputs/inputs/input-number/only-decimal.directive';
import { InputPhoneNumberComponent } from '@farm-portal/shared/modules/form-inputs/inputs/input-phone-number/input-phone-number.component';
import { CancelButtonComponent } from './buttons/cancel-button/cancel-button.component';
import { FormButtonsComponent } from './buttons/form-buttons/form-buttons.component';
import { SubmitButtonComponent } from './buttons/submit-button/submit-button.component';
import { InputCheckboxComponent } from './inputs/input-checkbox/input-checkbox.component';
import { InputDatepickerComponent } from './inputs/input-datepicker/input-datepicker.component';
import { InputDateTimePickerComponent } from './inputs/input-datetime-picker/input-datetime-picker.component';
import { InputEmailComponent } from './inputs/input-email/input-email.component';
import { InputMapComponent } from './inputs/input-map/input-map.component';
import { InputNgSelectComponent } from './inputs/input-ng-select/input-ng-select.component';
import { InputNumberComponent } from './inputs/input-number/input-number.component';
import { OnlyPositiveDirective } from './inputs/input-number/only-positive.directive';
import { InputPasswordComponent } from './inputs/input-password/input-password.component';
import { InputRadioButtonGroupComponent } from './inputs/input-radio-button-group/input-radio-button-group.component';
import { InpuRangeComponent } from './inputs/input-range/input-range.component';
import { InputSelectComponent } from './inputs/input-select/input-select.component';
import { InputTextComponent } from './inputs/input-text/input-text.component';
import { InputTextareaComponent } from './inputs/input-textarea/input-textarea.component';
import { InputTimepickerComponent } from './inputs/input-timepicker/input-timepicker.component';
var inputs = [
    InputDatepickerComponent,
    InputCheckboxComponent,
    InputEmailComponent,
    InputNgSelectComponent,
    FieldsetDisabledDirective,
    InputNumberComponent,
    InputPasswordComponent,
    InputSelectComponent,
    InputTextComponent,
    InputTextareaComponent,
    InputHtmlComponent,
    InputPhoneNumberComponent,
    InpuRangeComponent,
    InputTimepickerComponent,
    InputMapComponent,
    InputDateTimePickerComponent,
    InputRadioButtonGroupComponent,
    FileFormControlComponent,
];
var buttons = [SubmitButtonComponent, CancelButtonComponent, FormButtonsComponent];
var directives = [OnlyDecimalDirective, OnlyPositiveDirective];
var FormInputsModule = /** @class */ (function () {
    function FormInputsModule() {
    }
    return FormInputsModule;
}());
export { FormInputsModule };
