import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-form-buttons',
  templateUrl: './form-buttons.component.html',
})
export class FormButtonsComponent {
  @Input() isSubmitDisabled: boolean = false;
  @Output() cancelClick: EventEmitter<void> = new EventEmitter<void>();

  public onCancelClick(): void {
    this.cancelClick.emit();
  }
}
