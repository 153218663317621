import { ChangeDetectorRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AUTH_PARTNERS } from '@farm-portal/core/auth/models/auth-partners.const';
import { AuthStoreService } from '@farm-portal/core/auth/services/auth-store.service';
import { AuthService } from '@farm-portal/core/auth/services/auth.service';
import { UserContextService } from '@farm-portal/core/auth/user-context.service';
import { ContactCoreService } from '@farm-portal/core/contact';
var LoadUserDataComponent = /** @class */ (function () {
    function LoadUserDataComponent(authService, authStore, userContextService, contactCoreService, route, router, changeDetectorRef) {
        this.authService = authService;
        this.authStore = authStore;
        this.userContextService = userContextService;
        this.contactCoreService = contactCoreService;
        this.route = route;
        this.router = router;
        this.changeDetectorRef = changeDetectorRef;
    }
    Object.defineProperty(LoadUserDataComponent.prototype, "groupClaims", {
        get: function () {
            return this.authStore.getUserClaims;
        },
        enumerable: true,
        configurable: true
    });
    LoadUserDataComponent.prototype.ngOnInit = function () {
        var _this = this;
        var returnUrl = this.route.snapshot.queryParams.returnUrl;
        this.hasLoadedError = false;
        this.userContextService.tryGetCurrentUserData$().subscribe(function () {
            returnUrl == null ? _this.router.navigate(['/']) : _this.router.navigateByUrl(returnUrl);
        }, function (_) {
            _this.hasLoadedError = true;
            _this.ownerContact$ = _this.contactCoreService.getOwnerContact();
            _this.changeDetectorRef.detectChanges();
        });
    };
    LoadUserDataComponent.prototype.onLogout = function () {
        var _this = this;
        var partner = AUTH_PARTNERS.find(function (partner) { return _this.hasAccess(partner.permissionComponent); });
        var partnerId = partner ? partner.partnerId : undefined;
        this.userContextService.clean();
        return this.authService.navigateToLoginPage(partnerId);
    };
    LoadUserDataComponent.prototype.hasAccess = function (componentKey) {
        return this.groupClaims.some(function (claim) {
            return claim.startsWith(componentKey);
        });
    };
    return LoadUserDataComponent;
}());
export { LoadUserDataComponent };
