import { Component, Input } from '@angular/core';

import { InputBaseComponent } from '../input.base';

@Component({
  selector: 'app-input-timepicker',
  templateUrl: './input-timepicker.component.html',
  styleUrls: ['./../input.base.scss', './input-timepicker.component.scss'],
})
export class InputTimepickerComponent extends InputBaseComponent {}
