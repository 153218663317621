import { HttpClient } from '@angular/common/http';
import { AppConfigurationService } from 'farmcloud-core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "farmcloud-core";
var CurrentUserProxyService = /** @class */ (function () {
    function CurrentUserProxyService(httpClient, config) {
        this.httpClient = httpClient;
        this.config = config;
        this.userApiUrl = this.config.configuration.api_url + "/api/User";
    }
    CurrentUserProxyService.prototype.getUserOrganizations = function () {
        return this.httpClient.get(this.userApiUrl + "/Organizations");
    };
    CurrentUserProxyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CurrentUserProxyService_Factory() { return new CurrentUserProxyService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AppConfigurationService)); }, token: CurrentUserProxyService, providedIn: "root" });
    return CurrentUserProxyService;
}());
export { CurrentUserProxyService };
