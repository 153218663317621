import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ErrorsHandler } from 'farmcloud-core';

@Injectable({
  providedIn: 'root',
})
export class ClarityRoutingService implements OnDestroy {
  private routerSub: Subscription;

  constructor(
    private router: Router,
    private errorsHandler: ErrorsHandler,
  ) {
    this.routerSub = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      try {
        // clarity function comes from script that we injected in index.html
        // @ts-ignore
        clarity('set', 'pageTitle', document.title);
      } catch (error) {
        this.errorsHandler.handleError(new Error('Cannot set custom tag for this page - Clarity is not installed' + error));
      }
    });
  }

  ngOnDestroy() {
    if (this.routerSub) this.routerSub.unsubscribe();
  }
}
