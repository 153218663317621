import * as tslib_1 from "tslib";
import { ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { Loader } from 'google-maps';
import { AppConfigurationService } from 'farmcloud-core';
import * as i0 from "@angular/core";
import * as i1 from "farmcloud-core";
import * as i2 from "../../pure-google-maps-services.module";
/**
 * @deprecated
 * This map is obsolete and should not be used. Use GoogleMapService instead.
 */
var PureMapService = /** @class */ (function () {
    function PureMapService(appConfigurationService) {
        this.appConfigurationService = appConfigurationService;
        this.markers = [];
        this.polygons = [];
        this.mapReady = new Subject();
    }
    PureMapService.prototype.initMap = function (mapDiv, tilt) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loadGoogleMaps()];
                    case 1:
                        _a.sent();
                        this.googleMap = new google.maps.Map(mapDiv.nativeElement, {
                            center: { lat: 52.16045455774706, lng: 19.1162109375 },
                            zoom: 7,
                            mapTypeId: 'hybrid',
                            tilt: tilt,
                        });
                        this.mapReady.next(this.googleMap);
                        return [2 /*return*/, this.googleMap];
                }
            });
        });
    };
    Object.defineProperty(PureMapService.prototype, "map", {
        get: function () {
            this.assertMapInitialized();
            return this.googleMap;
        },
        enumerable: true,
        configurable: true
    });
    PureMapService.prototype.setMapOptions = function (options) {
        this.map.setOptions(options);
    };
    PureMapService.prototype.createMarker = function (options) {
        var marker = new google.maps.Marker(options);
        marker.setMap(this.map);
        this.markers.push(marker);
        return marker;
    };
    PureMapService.prototype.createSingleMarker = function (options) {
        this.markers.forEach(function (x) {
            x.setMap(null);
        });
        var marker = new google.maps.Marker(options);
        marker.setMap(this.map);
        this.markers.push(marker);
        return marker;
    };
    PureMapService.prototype.createPolygon = function (options) {
        var polygon = new google.maps.Polygon(options);
        polygon.setMap(this.map);
        this.polygons.push(polygon);
        return polygon;
    };
    PureMapService.prototype.clearPolygons = function () {
        var _this = this;
        this.polygons.forEach(function (polygon) { return _this.removePolygon(polygon); });
        this.polygons = [];
    };
    PureMapService.prototype.removePolygon = function (polygon) {
        polygon.setMap(null);
    };
    PureMapService.prototype.createPolyline = function (options) {
        var polyline = new google.maps.Polyline(options);
        polyline.setMap(this.map);
        return polyline;
    };
    PureMapService.prototype.createDrawingManager = function (options) {
        if (this.drawingManager) {
            this.drawingManager.setMap(null);
        }
        this.drawingManager = new google.maps.drawing.DrawingManager(options);
        this.drawingManager.setMap(this.map);
        return this.drawingManager;
    };
    PureMapService.prototype.setZoom = function (zoomValue) {
        this.map.setZoom(zoomValue);
    };
    PureMapService.prototype.centerMap = function (value) {
        this.map.setCenter(value);
    };
    PureMapService.prototype.fitBounds = function (bounds) {
        this.map.fitBounds(bounds);
        this.map.panToBounds(bounds);
    };
    PureMapService.prototype.overlayTiles = function (imageMapType) {
        this.map.overlayMapTypes.push(imageMapType);
    };
    PureMapService.prototype.clearMapTypes = function () {
        this.map.overlayMapTypes.clear();
    };
    PureMapService.prototype.clearMarkers = function () {
        this.markers.forEach(function (marker) {
            marker.setMap(null);
        });
        this.markers = [];
    };
    PureMapService.prototype.loadGoogleMaps = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var apiKey, loader;
            return tslib_1.__generator(this, function (_a) {
                if (PureMapService.isScriptLoaded) {
                    return [2 /*return*/];
                }
                if (PureMapService.loadingScriptPromise) {
                    return [2 /*return*/, PureMapService.loadingScriptPromise];
                }
                apiKey = this.appConfigurationService.configuration.google_maps_api_key;
                loader = new Loader(apiKey, {
                    libraries: ['drawing', 'places', 'geometry'],
                });
                PureMapService.loadingScriptPromise = loader
                    .load()
                    .then(function () {
                    PureMapService.isScriptLoaded = true;
                })
                    .catch(function (error) {
                    console.error('Error loading Google Maps script', error);
                    throw error;
                });
                return [2 /*return*/, PureMapService.loadingScriptPromise];
            });
        });
    };
    PureMapService.prototype.assertMapInitialized = function () {
        if (this.googleMap == null) {
            throw new Error('Map is not initialized');
        }
    };
    PureMapService.loadingScriptPromise = null;
    PureMapService.isScriptLoaded = false;
    PureMapService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PureMapService_Factory() { return new PureMapService(i0.ɵɵinject(i1.AppConfigurationService)); }, token: PureMapService, providedIn: i2.PureGoogleMapsServicesModule });
    return PureMapService;
}());
export { PureMapService };
