/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../cancel-button/cancel-button.component.ngfactory";
import * as i2 from "../cancel-button/cancel-button.component";
import * as i3 from "../submit-button/submit-button.component.ngfactory";
import * as i4 from "../submit-button/submit-button.component";
import * as i5 from "./form-buttons.component";
var styles_FormButtonsComponent = [];
var RenderType_FormButtonsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FormButtonsComponent, data: {} });
export { RenderType_FormButtonsComponent as RenderType_FormButtonsComponent };
export function View_FormButtonsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "d-flex justify-content-end mt-4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-cancel-button", [["class", "mr-2"]], null, [[null, "cancelClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cancelClick" === en)) {
        var pd_0 = (_co.onCancelClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_CancelButtonComponent_0, i1.RenderType_CancelButtonComponent)), i0.ɵdid(2, 49152, null, 0, i2.CancelButtonComponent, [], null, { cancelClick: "cancelClick" }), (_l()(), i0.ɵeld(3, 0, null, null, 1, "app-submit-button", [], null, null, null, i3.View_SubmitButtonComponent_0, i3.RenderType_SubmitButtonComponent)), i0.ɵdid(4, 49152, null, 0, i4.SubmitButtonComponent, [], { isDisabled: [0, "isDisabled"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isSubmitDisabled; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_FormButtonsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-form-buttons", [], null, null, null, View_FormButtonsComponent_0, RenderType_FormButtonsComponent)), i0.ɵdid(1, 49152, null, 0, i5.FormButtonsComponent, [], null, null)], null, null); }
var FormButtonsComponentNgFactory = i0.ɵccf("app-form-buttons", i5.FormButtonsComponent, View_FormButtonsComponent_Host_0, { isSubmitDisabled: "isSubmitDisabled" }, { cancelClick: "cancelClick" }, []);
export { FormButtonsComponentNgFactory as FormButtonsComponentNgFactory };
