import * as tslib_1 from "tslib";
import { InputBaseComponent } from '../input.base';
var InputRadioButtonGroupComponent = /** @class */ (function (_super) {
    tslib_1.__extends(InputRadioButtonGroupComponent, _super);
    function InputRadioButtonGroupComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.radioButtonGroupName = 'default';
        _this.options = [];
        return _this;
    }
    InputRadioButtonGroupComponent.prototype.isOptionActive = function (option) {
        return option == this.inputControl.value;
    };
    return InputRadioButtonGroupComponent;
}(InputBaseComponent));
export { InputRadioButtonGroupComponent };
