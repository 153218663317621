import * as tslib_1 from "tslib";
import { InputBaseComponent } from '../input.base';
var InputPasswordComponent = /** @class */ (function (_super) {
    tslib_1.__extends(InputPasswordComponent, _super);
    function InputPasswordComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return InputPasswordComponent;
}(InputBaseComponent));
export { InputPasswordComponent };
