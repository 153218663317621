/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./message-unread-notification.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./message-unread-notification.component";
import * as i4 from "./message.service";
var styles_MessageUnreadNotificationComponent = [i0.styles];
var RenderType_MessageUnreadNotificationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MessageUnreadNotificationComponent, data: {} });
export { RenderType_MessageUnreadNotificationComponent as RenderType_MessageUnreadNotificationComponent };
function View_MessageUnreadNotificationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "message-badge badge badge-number"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.ngIf; _ck(_v, 1, 0, currVal_0); }); }
function View_MessageUnreadNotificationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["class", "message-btn btn btn-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "message-btn-icon ph-duotone ph-envelope color-primary"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageUnreadNotificationComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.context.$implicit != 0); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_MessageUnreadNotificationComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_MessageUnreadNotificationComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.messageUnreadService.unreadMessageCount$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_MessageUnreadNotificationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-message-unread-notification", [], null, null, null, View_MessageUnreadNotificationComponent_0, RenderType_MessageUnreadNotificationComponent)), i1.ɵdid(1, 49152, null, 0, i3.MessageUnreadNotificationComponent, [i4.MessageService], null, null)], null, null); }
var MessageUnreadNotificationComponentNgFactory = i1.ɵccf("app-message-unread-notification", i3.MessageUnreadNotificationComponent, View_MessageUnreadNotificationComponent_Host_0, {}, {}, []);
export { MessageUnreadNotificationComponentNgFactory as MessageUnreadNotificationComponentNgFactory };
