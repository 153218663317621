import { OnInit } from '@angular/core';
import { TranslateService } from 'farmcloud-core';
var LangSelectorComponent = /** @class */ (function () {
    function LangSelectorComponent(translateService) {
        this.translateService = translateService;
        this.flagUrl = './assets/img/flags/';
    }
    LangSelectorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.languages = this.translateService.supportedLanguages().map(function (l) { return _this.mapLanguage(l); });
        this.selectedLanguage = this.mapLanguage(this.translateService.getLanguage());
    };
    LangSelectorComponent.prototype.changeLanguage = function (languageKey) {
        this.translateService.setLanguage(languageKey);
        this.selectedLanguage = this.mapLanguage(this.translateService.getLanguage());
    };
    LangSelectorComponent.prototype.trackByFn = function (index, item) {
        return item.key;
    };
    LangSelectorComponent.prototype.mapLanguage = function (language) {
        return {
            key: language,
            flagUrl: "" + this.flagUrl + language + ".png",
        };
    };
    return LangSelectorComponent;
}());
export { LangSelectorComponent };
