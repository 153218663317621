import { combineLatest, timer, BehaviorSubject } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { AppConfigurationService } from 'farmcloud-core';
import { MessageCoreProxyService } from '@farm-portal/core/message/services/message-core-proxy.service';
import { UserContextService } from '@farm-portal/core/auth/user-context.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../message/services/message-core-proxy.service";
import * as i2 from "farmcloud-core";
import * as i3 from "../../../auth/user-context.service";
import * as i4 from "../../../message/message-core-services.module";
var MessageService = /** @class */ (function () {
    function MessageService(messageCoreProxyService, config, userContextService) {
        var _this = this;
        this.messageCoreProxyService = messageCoreProxyService;
        this.config = config;
        this.userContextService = userContextService;
        this.refresh$ = new BehaviorSubject(null);
        if (!this.config.configuration.messages_enabled) {
            return;
        }
        this.messageData$ = combineLatest([
            timer(0, this.config.configuration.messages_refresh_rate),
            this.refresh$,
            this.userContextService.currentUserOrganization$,
        ]).pipe(filter(function (_a) {
            var currentOrganization = _a[2];
            return !!currentOrganization;
        }), switchMap(function () {
            return _this.messageCoreProxyService.getAllUnreadMessages();
        }));
        this.unreadMessageCount$ = this.messageData$.pipe(map(function (x) { return x.items.length; }), map(function (x) {
            if (x > 9) {
                return '9+';
            }
            return x.toString();
        }));
    }
    MessageService.prototype.refresh = function () {
        this.refresh$.next(null);
    };
    MessageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MessageService_Factory() { return new MessageService(i0.ɵɵinject(i1.MessageCoreProxyService), i0.ɵɵinject(i2.AppConfigurationService), i0.ɵɵinject(i3.UserContextService)); }, token: MessageService, providedIn: i4.MessageCoreServicesModule });
    return MessageService;
}());
export { MessageService };
