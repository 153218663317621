import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslationModule, SpinnerModule, PipesModule } from 'farmcloud-core';
import { MessageCoreServicesModule } from '@farm-portal/core/message/message-core-services.module';
import { FullLayoutComponent } from './full-layout/full-layout.component';
import { LoadUserDataComponent } from './load-user-data/load-user-data.component';
import { FarmNavItemComponent } from './navbar/farm-nav-item/farm-nav-item.component';
import { ContactCoreServicesModule } from '../contact';
import { SidebarListDirective } from '@farm-portal/core/layout/directives/sidebarlist.directive';
import { SidebarDirective } from '@farm-portal/core/layout/directives/sidebar.directive';
import { SidebarLinkDirective } from '@farm-portal/core/layout/directives/sidebarlink.directive';
import { SidebarAnchorToggleDirective } from '@farm-portal/core/layout/directives/sidebaranchortoggle.directive';
import { NavbarComponent } from '@farm-portal/core/layout/navbar/navbar-template/navbar.component';
import { UserNavItemComponent } from '@farm-portal/core/layout/navbar/navbar-template/user-nav-item/user-nav-item.component';
import { SidebarComponent } from '@farm-portal/core/layout/sidebar/sidebar.component';
import { NgbDropdownModule, NgbModule, NgbTabsetModule } from '@ng-bootstrap/ng-bootstrap';
import { FormInputsModule } from '@farm-portal/shared/modules/form-inputs/form-inputs.module';
import { NavbarNotificationsComponent } from '@farm-portal/core/layout/navbar/navbar-notifications/navbar-notifications.component';
import { MessageUnreadNotificationComponent } from '@farm-portal/core/layout/navbar/message-unread-notification/message-unread-notification.component';
import { LangSelectorComponent } from '@farm-portal/core/layout/navbar/lang-selector/lang-selector.component';
import { LayoutModule } from '@farm-portal/shared/modules/layout/layout.module';

@NgModule({
  declarations: [
    FullLayoutComponent,
    LoadUserDataComponent,
    FarmNavItemComponent,
    SidebarListDirective,
    SidebarDirective,
    SidebarLinkDirective,
    SidebarAnchorToggleDirective,
    NavbarComponent,
    UserNavItemComponent,
    SidebarComponent,
    NavbarNotificationsComponent,
    MessageUnreadNotificationComponent,
    LangSelectorComponent,
  ],
  imports: [
    CommonModule,
    TranslationModule,
    RouterModule,
    SpinnerModule,
    ContactCoreServicesModule,
    MessageCoreServicesModule,
    NgbDropdownModule,
    NgbTabsetModule,
    NgbModule,
    FormInputsModule,
    PipesModule,
    LayoutModule,
  ],
  exports: [FullLayoutComponent, NavbarNotificationsComponent],
})
export class LayoutCoreModule {}
