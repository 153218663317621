import * as tslib_1 from "tslib";
import { InputBaseComponent } from '../input.base';
var InputTimepickerComponent = /** @class */ (function (_super) {
    tslib_1.__extends(InputTimepickerComponent, _super);
    function InputTimepickerComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return InputTimepickerComponent;
}(InputBaseComponent));
export { InputTimepickerComponent };
