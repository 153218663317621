import { Component, Input, Output, EventEmitter } from '@angular/core';
import { EmptyStateDisplayModel } from '@farm-portal/shared/modules/layout/empty-state/empty-state-display.model';

@Component({
  selector: 'app-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss'],
})
export class EmptyStateComponent {
  @Input() public title: string;
  @Input() public subtitle: string;
  @Input() public btnText: string;
  @Input() public btnIcon = 'ph ph-plus';
  @Input() public showButton: boolean | null = true;
  @Input() public assetSrc: string = 'empty-state.png';

  @Input() public displayModel: EmptyStateDisplayModel | null;

  @Output() public clicked = new EventEmitter();

  public onClick(): void {
    this.clicked.emit();
  }

  public shouldDisplayEmptyState(): boolean {
    if (!this.displayModel) {
      return true;
    }

    if (this.displayModel.initialDataLoading) {
      return false;
    }

    if (!this.displayModel.anyDataReturned && !this.displayModel.filtersExists) {
      return true;
    }

    return false;
  }

  public shouldShowButton(): boolean {
    if (!this.showButton) {
      return false;
    }

    return !!this.btnText && this.btnText.length > 0;
  }
}
