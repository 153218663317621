/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./farm-nav-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/modules/form-inputs/form-wide/form-wide.component.ngfactory";
import * as i3 from "../../../../shared/modules/form-inputs/form-wide/form-wide.component";
import * as i4 from "@angular/common";
import * as i5 from "../../../../shared/modules/form-inputs/inputs/input-ng-select/input-ng-select.component.ngfactory";
import * as i6 from "../../../../shared/modules/form-inputs/inputs/input-ng-select/input-ng-select.component";
import * as i7 from "@ngx-translate/core";
import * as i8 from "./farm-nav-item.component";
import * as i9 from "../../../auth/user-context.service";
import * as i10 from "farmcloud-core";
import * as i11 from "@angular/router";
import * as i12 from "@angular/forms";
var styles_FarmNavItemComponent = [i0.styles];
var RenderType_FarmNavItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FarmNavItemComponent, data: {} });
export { RenderType_FarmNavItemComponent as RenderType_FarmNavItemComponent };
export function View_FarmNavItemComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "lib-form-wide", [], null, null, null, i2.View_FormWideComponent_0, i2.RenderType_FormWideComponent)), i1.ɵdid(1, 245760, null, 0, i3.FormWideComponent, [i4.DOCUMENT], { formGroup: [0, "formGroup"], displayFormButtons: [1, "displayFormButtons"], isEditMode: [2, "isEditMode"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "app-input-ng-select", [["bindLabel", "name"], ["searchByField", "name"]], null, null, null, i5.View_InputNgSelectComponent_0, i5.RenderType_InputNgSelectComponent)), i1.ɵdid(3, 245760, null, 0, i6.InputNgSelectComponent, [i7.TranslateService, i1.ChangeDetectorRef], { inputControl: [0, "inputControl"], options: [1, "options"], bindLabel: [2, "bindLabel"], bindValue: [3, "bindValue"], clearable: [4, "clearable"], searchByField: [5, "searchByField"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formGroup; var currVal_1 = false; var currVal_2 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.getControl("userOrganization"); var currVal_4 = i1.ɵunv(_v, 3, 1, i1.ɵnov(_v, 4).transform(_co.userOrganizations$)); var currVal_5 = "name"; var currVal_6 = null; var currVal_7 = false; var currVal_8 = "name"; _ck(_v, 3, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
export function View_FarmNavItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-farm-nav-item", [], null, null, null, View_FarmNavItemComponent_0, RenderType_FarmNavItemComponent)), i1.ɵdid(1, 245760, null, 0, i8.FarmNavItemComponent, [i9.UserContextService, i10.SpinnerService, i11.Router, i12.FormBuilder], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FarmNavItemComponentNgFactory = i1.ɵccf("app-farm-nav-item", i8.FarmNavItemComponent, View_FarmNavItemComponent_Host_0, {}, {}, []);
export { FarmNavItemComponentNgFactory as FarmNavItemComponentNgFactory };
