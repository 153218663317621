import { AfterViewInit, ChangeDetectorRef, Component, Injector, Input, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { Feature, Point, Polygon } from 'geojson';
import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';
import { InputMapOptions } from './models/input-map-drawing-options.model';
import { InputMapService } from './services/input-map.service';

import { AnchorPointX, AnchorPointY } from '@farm-portal/shared/modules/form-inputs/inputs/input-map/models/input-map.consts';
import { EDITABLE_ZONE_COLOR, MAP_CONTOUR_COLOR } from '@farm-portal/shared/modules/layout/pure-google-maps/common/config/map-config';
import { GoogleMapService } from '@farm-portal/shared/modules/layout/pure-google-maps/common/services/google-map.service';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'app-input-map',
  templateUrl: 'input-map.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputMapComponent),
      multi: true,
    },
    GoogleMapService,
    InputMapService,
  ],
})
export class InputMapComponent implements OnInit, AfterViewInit, ControlValueAccessor, OnDestroy {
  @Input() labelTranslateKey: string;
  @Input() centerPoint$: Observable<{ lat: number; lng: number }>;

  @Input() set mapOptions(options: InputMapOptions) {
    this.mapOptions$.next(options);
  }

  @Input() height: string = '400px';

  value: Feature[] = [];
  value$ = new BehaviorSubject<Feature[]>([]);
  onChange: (value: Feature[]) => void = () => {};
  onTouched: () => void = () => {};

  public isDisabled: boolean;
  public isDisabled$ = new BehaviorSubject<boolean>(false);
  public map$: BehaviorSubject<google.maps.Map> = new BehaviorSubject<google.maps.Map>(null);
  public mapOptions$: BehaviorSubject<InputMapOptions> = new BehaviorSubject<InputMapOptions>(null);

  public get isPolygonDrawingActive() {
    return this.drawingManager && this.drawingManager.getDrawingMode() === google.maps.drawing.OverlayType.POLYGON;
  }

  public get isMarkerDrawingActive() {
    return this.drawingManager && this.drawingManager.getDrawingMode() === google.maps.drawing.OverlayType.MARKER;
  }

  private drawingManager: google.maps.drawing.DrawingManager;
  private drawnPolygons: { id: string; googleMapsPolygon: google.maps.Polygon; geoPolygon: Feature<Polygon> }[] = [];
  private drawnMarkers: { id: string; googleMapsMarker: google.maps.Marker; geoPoint: Feature<Point> }[] = [];
  private drawingManagerListener: google.maps.MapsEventListener;
  private drawingOnMapSubscription: Subscription;
  private centerSubscription: Subscription;

  public ngControl: NgControl;

  constructor(
    private injector: Injector,
    private readonly googleMapService: GoogleMapService,
    private readonly inputMapService: InputMapService,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnDestroy(): void {
    if (this.drawingOnMapSubscription) {
      this.drawingOnMapSubscription.unsubscribe();
    }

    if (this.centerSubscription) {
      this.centerSubscription.unsubscribe();
    }

    if (this.drawingManagerListener) {
      this.drawingManagerListener.remove();
    }
  }

  ngOnInit(): void {
    this.initializeMapSubscription();

    if (this.centerPoint$) {
      this.centerSubscription = combineLatest([this.map$, this.centerPoint$]).subscribe(([map, center]) => {
        if (center) {
          this.googleMapService.centerMap(map, new google.maps.LatLng(center.lat, center.lng));
        }
      });
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.ngControl = this.injector.get(NgControl, null);
      if (this.ngControl) {
        this.ngControl.valueAccessor = this;
      } else {
        console.error('NgControl is not available');
      }
    });
  }

  writeValue(value: Feature[]): void {
    if (value) {
      this.clearFeatureProperties(value);
      this.value = value;
      this.value$.next(value);
    }
  }

  registerOnChange(fn: (value: Feature[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.isDisabled$.next(isDisabled);
  }

  updateValue(updatedFeatures: Feature[]): void {
    this.value = updatedFeatures;
    this.onChange(this.value);
    this.onTouched();
  }

  public onMapInitialized($event: google.maps.Map) {
    this.map$.next($event);
  }

  public setDrawingMode(mode: google.maps.drawing.OverlayType) {
    if (this.drawingManager) {
      this.drawingManager.setDrawingMode(mode);
    }
  }

  public isDrawingEnabled() {
    if (!this.mapOptions$.value.isDrawingEnabled) {
      return false;
    }

    if (this.mapOptions$.value.isDrawingMarkersEnabled) {
      return true;
    }

    if (this.mapOptions$.value.isDrawingPolygonEnabled) {
      return true;
    }

    return false;
  }

  private initializeMapSubscription() {
    this.drawingOnMapSubscription = combineLatest([this.map$, this.mapOptions$, this.value$, this.isDisabled$]).subscribe(
      ([map, mapOptions, value, _]) => {
        if (!map) {
          return;
        }

        if (mapOptions) {
          this.initializeDrawingManager(map, mapOptions);
        }

        this.clearExistingFeatures();

        if (value && value.length) {
          this.drawFeatures(map, value, mapOptions);
        }
      },
    );
  }

  private drawFeatures(map: google.maps.Map, features: Feature[], mapOptions: InputMapOptions) {
    const bounds = new google.maps.LatLngBounds();

    features.forEach(feature => {
      if (feature.geometry.type === 'Polygon') {
        this.createAndAddPolygon(map, feature as Feature<Polygon>, bounds, mapOptions);
      }

      if (feature.geometry.type === 'Point') {
        this.createAndAddMarker(map, feature as Feature<Point>, bounds);
      }
    });

    this.googleMapService.fitBounds(map, bounds);
  }

  private updateValueWithDrawnMarkersAndDrawnPolygons() {
    this.updateValue([...this.drawnPolygons.map(p => p.geoPolygon), ...this.drawnMarkers.map(p => p.geoPoint)]);
  }

  // DRAWING MANAGER INITIALIZATION

  private initializeDrawingManager(map: google.maps.Map, options: InputMapOptions) {
    if (!options.isDrawingEnabled) {
      return;
    }

    const drawingModes: google.maps.drawing.OverlayType[] = [];

    if (options.isDrawingPolygonEnabled) {
      drawingModes.push(google.maps.drawing.OverlayType.POLYGON);
    }

    if (options.isDrawingMarkersEnabled) {
      drawingModes.push(google.maps.drawing.OverlayType.MARKER);
    }

    this.drawingManager = this.googleMapService.createDrawingManager(map, {
      drawingControl: false,
      drawingControlOptions: {
        drawingModes: drawingModes,
      },
      polygonOptions: {
        strokeColor: options.polygonFillStrokeColor || EDITABLE_ZONE_COLOR,
        fillColor: options.polygonFillStrokeColor || EDITABLE_ZONE_COLOR,
      },
    });

    if (!this.drawingManager) {
      return;
    }

    this.initializeDrawingManagerListeners(map, this.drawingManager, options);
  }

  private initializeDrawingManagerListeners(
    map: google.maps.Map,
    drawingManager: google.maps.drawing.DrawingManager,
    options: InputMapOptions,
  ) {
    this.drawingManagerListener = drawingManager.addListener('overlaycomplete', event => {
      if (event.type === google.maps.drawing.OverlayType.POLYGON) {
        this.handleAddDrawedGoogleMapsPolygon(event, map, drawingManager, options);
      }

      if (event.type === google.maps.drawing.OverlayType.MARKER) {
        this.handleAddDrawedGoogleMapsMarker(event, map, drawingManager);
      }

      this.cdRef.detectChanges();
    });
  }

  // DRAWING GOOGLE MAPS FEATURES AND DRAWING CURRENT FEATURES

  private handleAddDrawedGoogleMapsPolygon(
    event: google.maps.drawing.OverlayCompleteEvent,
    map: google.maps.Map,
    drawingManager: google.maps.drawing.DrawingManager,
    options: InputMapOptions,
  ) {
    const poly = event.overlay as google.maps.Polygon;
    poly.setMap(null);
    if (drawingManager.getDrawingMode() === null) {
      return;
    }
    const properties = {
      editable: !this.isDisabled || false,
      strokeColor: options.polygonFillStrokeColor || MAP_CONTOUR_COLOR,
      fillColor: options.polygonFillStrokeColor || MAP_CONTOUR_COLOR,
    };

    const polygon = this.googleMapService.createPolygon(map, {
      paths: poly.getPath(),
      ...properties,
    });

    const polygonId = uuid();
    polygon.set('id', polygonId);

    const geoFeature = this.googleMapService.mapPolygonToFeature(polygon);

    const centerPoint = this.googleMapService.getCenterPoint(polygon);

    geoFeature.properties['center'] = centerPoint;

    const drawnPolygonFeature = {
      id: polygonId,
      googleMapsPolygon: polygon,
      geoPolygon: geoFeature,
    };

    this.initializePolygonListeners(map, polygon, drawnPolygonFeature);
    this.drawnPolygons.push(drawnPolygonFeature);
    this.setDrawingMode(null);
    this.updateValueWithDrawnMarkersAndDrawnPolygons();
  }

  private handleAddDrawedGoogleMapsMarker(
    event: google.maps.drawing.OverlayCompleteEvent,
    map: google.maps.Map,
    drawingManager: google.maps.drawing.DrawingManager,
  ) {
    const marker = event.overlay as google.maps.Marker;
    marker.setMap(null);
    if (drawingManager.getDrawingMode() === null) {
      return;
    }
    const properties: google.maps.MarkerOptions = {
      draggable: !this.isDisabled || false,
      icon: {
        url: './assets/custom-map-pin.svg',
        anchor: new google.maps.Point(AnchorPointX, AnchorPointY),
      },
      animation: google.maps.Animation.DROP,
    };

    const drawedMarker = this.googleMapService.createMarker(map, {
      position: marker.getPosition(),
      ...properties,
    });

    const markerId = uuid();
    drawedMarker.set('id', markerId);

    const geoFeature = this.googleMapService.mapMarkerToFeature(marker);

    const centerPoint = this.googleMapService.getCenterPoint(drawedMarker);

    geoFeature.properties['center'] = centerPoint;

    const drawnFeatureMarker = {
      id: markerId,
      googleMapsMarker: drawedMarker,
      geoPoint: geoFeature,
    };

    this.initializeMarkerListeners(map, drawedMarker, drawnFeatureMarker);
    this.drawnMarkers.push(drawnFeatureMarker);
    this.setDrawingMode(null);
    this.updateValueWithDrawnMarkersAndDrawnPolygons();
  }

  private createAndAddPolygon(
    map: google.maps.Map,
    feature: Feature<Polygon>,
    bounds: google.maps.LatLngBounds,
    mapOptions: InputMapOptions,
  ) {
    const polygonPaths = this.googleMapService.mapPolygonGeometryToPaths(feature.geometry);
    const googleMapsPolygon = this.googleMapService.createPolygon(map, {
      paths: polygonPaths,
      editable: !this.isDisabled,
      fillColor: mapOptions.polygonFillStrokeColor || MAP_CONTOUR_COLOR,
      strokeColor: mapOptions.polygonFillStrokeColor || MAP_CONTOUR_COLOR,
      fillOpacity: 0.2,
    });

    const polygonId = uuid();
    googleMapsPolygon.set('id', polygonId);

    googleMapsPolygon.getPath().forEach(x => {
      bounds.extend(x);
    });

    const centerPoint = this.googleMapService.getCenterPoint(googleMapsPolygon);

    feature.properties['center'] = centerPoint;

    const drawnPolygonFeature = {
      id: polygonId,
      googleMapsPolygon: googleMapsPolygon,
      geoPolygon: feature,
    };

    this.drawnPolygons.push(drawnPolygonFeature);
    this.initializePolygonListeners(map, googleMapsPolygon, drawnPolygonFeature);
  }

  private createAndAddMarker(map: google.maps.Map, feature: Feature<Point>, bounds: google.maps.LatLngBounds) {
    const position = new google.maps.LatLng(feature.geometry.coordinates[1], feature.geometry.coordinates[0]);
    const googleMapsMarker = this.googleMapService.createMarker(map, {
      position: position,
      draggable: !this.isDisabled,
      icon: {
        url: './assets/custom-map-pin.svg',
        anchor: new google.maps.Point(AnchorPointX, AnchorPointY),
      },
    });

    const markerId = uuid();
    googleMapsMarker.set('id', markerId);

    bounds.extend(googleMapsMarker.getPosition());

    const centerPoint = this.googleMapService.getCenterPoint(googleMapsMarker);

    feature.properties['center'] = centerPoint;

    const drawnMarkerFeature = {
      id: markerId,
      googleMapsMarker: googleMapsMarker,
      geoPoint: feature,
    };

    this.drawnMarkers.push(drawnMarkerFeature);
    this.initializeMarkerListeners(map, googleMapsMarker, drawnMarkerFeature);
  }

  // LISTENERS ACTIONS

  private initializePolygonListeners(
    map: google.maps.Map,
    polygon: google.maps.Polygon,
    drawnPolygonFeature: { googleMapsPolygon: google.maps.Polygon; geoPolygon: Feature<Polygon> },
  ) {
    google.maps.event.addListener(polygon.getPath(), 'set_at', () => {
      this.updatePolygonFeature(drawnPolygonFeature);
    });
    google.maps.event.addListener(polygon.getPath(), 'insert_at', () => {
      this.updatePolygonFeature(drawnPolygonFeature);
    });

    if (this.isDisabled) {
      return;
    }

    this.setPopupForPolygon(map, polygon);
  }

  private updatePolygonFeature(drawnPolygonFeature: { googleMapsPolygon: google.maps.Polygon; geoPolygon: Feature<Polygon> }) {
    drawnPolygonFeature.geoPolygon = this.googleMapService.mapPolygonToFeature(drawnPolygonFeature.googleMapsPolygon);
    this.updateValueWithDrawnMarkersAndDrawnPolygons();
  }

  private initializeMarkerListeners(
    map: google.maps.Map,
    marker: google.maps.Marker,
    drawnMarkerFeature: { googleMapsMarker: google.maps.Marker; geoPoint: Feature<Point> },
  ) {
    google.maps.event.addListener(marker, 'dragend', () => {
      this.updateMarkerFeature(drawnMarkerFeature);
    });

    if (this.isDisabled) {
      return;
    }

    this.setPopupForMarker(map, marker);
  }

  private updateMarkerFeature(drawnMarkerFeature: { googleMapsMarker: google.maps.Marker; geoPoint: Feature<Point> }) {
    drawnMarkerFeature.geoPoint = this.googleMapService.mapMarkerToFeature(drawnMarkerFeature.googleMapsMarker);
    this.updateValueWithDrawnMarkersAndDrawnPolygons();
  }

  private clearExistingFeatures() {
    this.googleMapService.clearPolygons();
    this.googleMapService.clearMarkers();
    this.drawnPolygons = [];
    this.drawnMarkers = [];
  }

  private setPopupForMarker(map: google.maps.Map, marker: google.maps.Marker) {
    const infoWindow = new google.maps.InfoWindow({
      content: this.inputMapService.buildRemoveInfoWindow(marker.get('id')),
      pixelOffset: new google.maps.Size(0, -50, 'px', 'px'),
    });

    this.setListenerForInfoPopup(marker.get('id'), infoWindow);
    this.inputMapService.setOnClickMarkerListener(map, marker, infoWindow);
  }

  private setPopupForPolygon(map: google.maps.Map, polygon: google.maps.Polygon) {
    const infoWindow = new google.maps.InfoWindow({
      content: this.inputMapService.buildRemoveInfoWindow(polygon.get('id')),
      pixelOffset: new google.maps.Size(0, -50, 'px', 'px'),
    });

    this.setListenerForInfoPopup(polygon.get('id'), infoWindow);
    this.inputMapService.setOnClickPolygonListener(map, polygon, infoWindow);
  }

  private setListenerForInfoPopup(id: string, infowindow: google.maps.InfoWindow) {
    if (this.isDisabled) {
      return;
    }

    google.maps.event.addListener(infowindow, 'domready', () => {
      document.getElementById(`${id}_Remove`).addEventListener('click', () => {
        this.removeFeatureFromMap(id);
        infowindow.close();
      });
    });
  }

  private removeFeatureFromMap(id: string) {
    const markerToRemove = this.drawnMarkers.find(x => x.id === id);
    if (markerToRemove) {
      this.drawnMarkers = this.drawnMarkers.filter(x => x.id !== id);
      markerToRemove.googleMapsMarker.setMap(null);
      this.updateValueWithDrawnMarkersAndDrawnPolygons();
    }

    const polygonToRemove = this.drawnPolygons.find(x => x.id === id);
    if (polygonToRemove) {
      this.drawnPolygons = this.drawnPolygons.filter(x => x.id !== id);
      polygonToRemove.googleMapsPolygon.setMap(null);
      this.updateValueWithDrawnMarkersAndDrawnPolygons();
    }

    this.cdRef.detectChanges();
  }

  // HELPERS

  public isPolygonLimitReached(mapOptions: InputMapOptions): boolean {
    if (!mapOptions) {
      return true;
    }

    if (!mapOptions.isDrawingPolygonEnabled) {
      return true;
    }

    if (mapOptions.maxAcceptablePolygonsCount && this.drawnPolygons && this.drawnPolygons.length >= mapOptions.maxAcceptablePolygonsCount) {
      return true;
    }

    return false;
  }

  public isMarkersLimitReached(mapOptions: InputMapOptions): boolean {
    if (!mapOptions) {
      return true;
    }

    if (!mapOptions.isDrawingMarkersEnabled) {
      return true;
    }

    if (mapOptions.maxAcceptableMarkersCount && this.drawnMarkers && this.drawnMarkers.length >= mapOptions.maxAcceptableMarkersCount) {
      return true;
    }

    return false;
  }

  private clearFeatureProperties(feature: Array<Feature>) {
    feature.forEach(feature => (feature.properties = {}));
  }
}
