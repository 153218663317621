import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FullLayoutComponent } from '@farm-portal/core/layout';
import { SiteTitle } from './app-site-titles.enum';
import { IdpAuthGuard } from './core/auth/idp-auth.guard';
import { IdpOrganizationGuard } from './core/auth/idp-organization.guard';
import { LoadUserDataComponent } from './core/layout/load-user-data/load-user-data.component';
import { IdpRucipGuard } from '@farm-portal/core/auth/idp-rucip.guard';

const appRoutes: Routes = [
  {
    path: 'auth',
    loadChildren: './modules/authorization/authorization.module#AuthorizationModule',
  },
  {
    path: 'load-user-data',
    component: LoadUserDataComponent,
    canActivate: [IdpAuthGuard],
  },
  {
    path: '',
    component: FullLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'my-fields',
        pathMatch: 'full',
      },
      {
        path: 'contact',
        loadChildren: './modules/contact/contact.module#ContactModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.contact,
        },
      },
      {
        path: 'farm',
        loadChildren: './modules/farm/farm.module#FarmModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.farm,
        },
      },
      {
        path: 'carbon-assistant',
        loadChildren: './modules/carbon-assistant/carbon-assistant.module#CarbonAssistantModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.carbonAssistant,
        },
      },
      {
        path: 'contracts',
        loadChildren: './modules/contracts/contracts.module#ContractsModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.contracts,
        },
      },

      {
        path: 'my-fields',
        loadChildren: './modules/ground/ground.module#GroundModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.myFields,
        },
      },
      {
        path: 'cultivation-assistant',
        loadChildren: './modules/cultivation-assistant/cultivation-assistant.module#CultivationAssistantModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.cultivationAssistant,
        },
      },
      {
        path: 'buildings',
        loadChildren: './modules/buildings/buildings.module#BuildingsModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.buildings,
        },
      },
      {
        path: 'employees',
        loadChildren: './modules/employee/employee.module#EmployeeModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.employees,
        },
      },
      {
        path: 'machines',
        loadChildren: './modules/machine/machine.module#MachineModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.machines,
        },
      },
      {
        path: 'observations',
        loadChildren: './modules/observation/observation.module#ObservationModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.observations,
        },
      },
      {
        path: 'rucip',
        loadChildren: './modules/rucip-observation/rucip.module#RucipModule',
        canLoad: [IdpAuthGuard, IdpRucipGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.rucip,
        },
      },
      {
        path: 'fertilizer-stock-needs',
        loadChildren: './modules/fertilizer-stock-needs/fertilizer-stock-needs.module#FertilizerStockNeedsModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.fertilizerStockNeeds,
        },
      },
      {
        path: 'weatherstations',
        loadChildren: './modules/weather-station/weather-station.module#WeatherStationModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.weatherStations,
        },
      },
      {
        path: `storageSensors`,
        loadChildren: './modules/storage-sensors/storage-sensors.module#StorageSensorsModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.storageSensors,
        },
      },
      {
        path: `devices`,
        loadChildren: './modules/devices/devices.module#DevicesModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.devices,
        },
      },
      {
        path: 'messages',
        loadChildren: './modules/message/message.module#MessageModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.messages,
        },
      },
      {
        path: 'monitoring',
        loadChildren: './modules/monitoring/monitoring.module#MonitoringModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.monitoring,
        },
      },
      {
        path: 'vehtel-monitoring',
        loadChildren: './modules/vehtel-monitoring/vehtel-monitoring.module#VehtelMonitoringModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.monitoring,
        },
      },
      {
        path: 'report',
        loadChildren: './modules/report/report.module#ReportModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
      },
      {
        path: 'fertilizers-db',
        loadChildren: './modules/fertilizer-db/fertilizer-db.module#FertilizerDbModule',
        data: {
          title: SiteTitle.fertilizersDb,
        },
      },
      {
        path: 'pesticides',
        loadChildren: './modules/pesticide/pesticide.module#PesticideModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.pesticides,
        },
      },
      {
        path: 'documents',
        loadChildren: './modules/documents/documents.module#DocumentsModule',
        canActivate: [IdpAuthGuard, IdpOrganizationGuard],
        data: {
          title: SiteTitle.documents,
        },
      },
      {
        path: 'settings',
        loadChildren: './modules/settings/settings.module#SettingsModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.settings,
        },
      },
      {
        path: 'stock-offers',
        loadChildren: './modules/stock-offer/stock-offer.module#StockOfferModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.stockOffers,
        },
      },
      {
        path: 'suppliers',
        loadChildren: './modules/supplier/supplier.module#SupplierModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.suppliers,
        },
      },
      {
        path: 'emission-calculations',
        loadChildren: './modules/emission-calculation/emission-calculation.module#EmissionCalculationModule',
        data: {
          title: SiteTitle.emissionCalculations,
        },
      },
      {
        path: 'warehouses',
        loadChildren: './modules/warehouse/warehouse.module#WarehouseModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.warehousesProductStocks,
        },
      },
      {
        path: 'soil-analysis',
        loadChildren: './modules/soil-analysis/soil-analysis.module#SoilAnalysisModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.soilAnalysis,
        },
      },
      {
        path: 'satellite-images',
        loadChildren: './modules/satellite-images/satellite-images.module#SatelliteImagesModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.satelliteImages,
        },
      },
      {
        path: 'treatments',
        loadChildren: './modules/treatments/treatments.module#TreatmentsModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.treatments,
        },
      },
      {
        path: '**',
        redirectTo: 'my-fields',
      },
    ],
    canActivateChild: [IdpOrganizationGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
