import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { flatMap, map, takeUntil, tap } from 'rxjs/operators';

import { UserContextService } from './../../../auth/user-context.service';
import { IUserOrganizationDto } from '@farm-portal/core/auth/user-organization.dto';
import { FarmNavUserOrganization } from '../common/models/farm-nav-organization.model';
import { Router } from '@angular/router';
import { SpinnerService } from 'farmcloud-core';

@Component({
  selector: 'app-farm-nav-item',
  templateUrl: './farm-nav-item.component.html',
  styleUrls: ['./farm-nav-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FarmNavItemComponent implements OnInit, OnDestroy {
  public formGroup: FormGroup;
  public userOrganizations$: Observable<any[]>;
  public currentUserOrganization$: Observable<any>;

  private initVal: IUserOrganizationDto;

  private unsubscribed: Subject<void> = new Subject();
  private subscription: Subscription;
  private organizationsSub: Subscription;

  constructor(
    private userContextService: UserContextService,
    private readonly spinnerService: SpinnerService,
    private readonly router: Router,
    readonly formBuilder: FormBuilder,
  ) {
    this.formGroup = formBuilder.group({
      userOrganization: [null],
    });
  }

  ngOnInit(): void {
    this.userOrganizations$ = this.userContextService.userOrganizations$;
    this.currentUserOrganization$ = this.userContextService.currentUserOrganization$;

    this.userOrganizations$ = combineLatest([this.userContextService.userOrganizations$, this.currentUserOrganization$]).pipe(
      map(([userOrganisations, currentUserOrganization]: [IUserOrganizationDto[], IUserOrganizationDto]) => {
        if (currentUserOrganization == null) {
          return [];
        }
        const selectedOrganizationId = currentUserOrganization.id;
        return (userOrganisations || []).map(x => {
          return {
            ...x,
            active: x.id === selectedOrganizationId,
          };
        });
      }),
    );

    this.organizationsSub = this.userOrganizations$.pipe(takeUntil(this.unsubscribed)).subscribe(res => {
      this.initVal = res.filter(x => x.active === true)[0];
      this.getControl('userOrganization').setValue(res.filter(x => x.active === true)[0], { emitEvent: false });
    });
    this.onFarmChange();
  }

  private onFarmChange() {
    this.subscription = this.getControl('userOrganization')
      .valueChanges.pipe(
        tap(() => this.spinnerService.display()),
        map(selectedValue => selectedValue),
        flatMap((x: FarmNavUserOrganization) => {
          if (x != null && this.initVal !== x) {
            return this.userContextService.selectCurrentUserOrganization(x);
          } else {
            return null;
          }
        }),
      )
      .pipe(takeUntil(this.unsubscribed))
      .subscribe(() => {
        this.spinnerService.hide();
        return this.router.navigate(['/'], { skipLocationChange: false });
      });
  }

  ngOnDestroy(): void {
    this.unsubscribed.next();
    this.unsubscribed.complete();

    if (this.subscription != null) {
      this.subscription.unsubscribe();
    }
    if (this.organizationsSub) {
      this.organizationsSub.unsubscribe();
    }
  }

  public getControl(name: string): FormControl {
    return this.formGroup.get(name) as FormControl;
  }
}
