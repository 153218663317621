import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { StorageKey } from '../models/storage-key.enum';
import { AuthProxyService } from './auth-proxy.service';
import { AuthStorageService } from './auth-storage.service';
import { AuthStoreService } from './auth-store.service';
import * as i0 from "@angular/core";
import * as i1 from "./auth-storage.service";
import * as i2 from "./auth-proxy.service";
import * as i3 from "./auth-store.service";
import * as i4 from "@angular/router";
//NOTE This is a temporary solution, is should be replaced with import (see lines above)
var FARM_PORTAL_TOKEN = 'farmportal';
var AuthService = /** @class */ (function () {
    function AuthService(storageService, authProxyService, authStoreService, router) {
        this.storageService = storageService;
        this.authProxyService = authProxyService;
        this.authStoreService = authStoreService;
        this.router = router;
    }
    AuthService.prototype.login = function (userCredentials) {
        var _this = this;
        return this.authProxyService.token(userCredentials).pipe(switchMap(function (res) { return _this.handleTokenResponse(res.accessToken, res.refreshToken); }));
    };
    AuthService.prototype.loginAslToken = function (token) {
        var _this = this;
        return this.authProxyService.loginAsl(token).pipe(switchMap(function (res) { return _this.handleTokenResponse(res.accessToken, res.refreshToken); }));
    };
    AuthService.prototype.logout = function () {
        this.authStoreService.updateUserNotLogged();
    };
    AuthService.prototype.register = function (input) {
        return this.authProxyService.register(input);
    };
    AuthService.prototype.remindPassword = function (email) {
        return this.authProxyService.remindPassword({
            email: email,
            host: FARM_PORTAL_TOKEN,
        });
    };
    AuthService.prototype.resetPassword = function (data) {
        return this.authProxyService.resetPassword(data);
    };
    AuthService.prototype.refreshTokenOnOrganizationChange = function (selectedOrganizationId) {
        var _this = this;
        var getAccessToken = this.storageService.getItem(StorageKey.ACCESS_TOKEN_KEY);
        var getRefreshToken = this.storageService.getItem(StorageKey.REFRESH_TOKEN_KEY);
        return forkJoin([getAccessToken, getRefreshToken]).pipe(switchMap(function (_a) {
            var accessToken = _a[0], refreshToken = _a[1];
            var refreshTokenInput = {
                accessToken: accessToken,
                host: FARM_PORTAL_TOKEN,
                refreshToken: refreshToken,
                organizationId: selectedOrganizationId,
            };
            return _this.authProxyService.refreshToken(refreshTokenInput);
        }), switchMap(function (res) { return _this.handleTokenResponse(res.accessToken, res.refreshToken); }));
    };
    AuthService.prototype.confirmEmail = function (code) {
        return this.authProxyService.confirmEmail(code);
    };
    AuthService.prototype.sendEmailActivationLink$ = function (input) {
        return this.authProxyService.sendEmailActivationLink$({
            email: input.email,
            host: FARM_PORTAL_TOKEN,
        });
    };
    AuthService.prototype.clearLoginData = function () {
        this.authStoreService.updateUserNotLogged();
    };
    AuthService.prototype.navigateToLoginPage = function (partnerId) {
        return this.router.navigate(['auth', 'login'], { queryParams: { partnerId: partnerId } });
    };
    AuthService.prototype.handleTokenResponse = function (accessToken, refreshToken) {
        var _this = this;
        return this.authStoreService.updateAuthTokens(accessToken, refreshToken).pipe(switchMap(function () { return _this.authProxyService.getUserProfile(); }), switchMap(function (userProfile) { return _this.authStoreService.updateUserData(userProfile); }), map(function () { return null; }));
    };
    AuthService.prototype.acceptInvitation = function (acceptInvitation) {
        return this.authProxyService.acceptInvitation(acceptInvitation);
    };
    AuthService.prototype.getInvitation = function (invitationId) {
        return this.authProxyService.getInvitation(invitationId);
    };
    AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.AuthStorageService), i0.ɵɵinject(i2.AuthProxyService), i0.ɵɵinject(i3.AuthStoreService), i0.ɵɵinject(i4.Router)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
