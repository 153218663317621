/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./navbar-notifications.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "@angular/common";
import * as i4 from "farmcloud-core";
import * as i5 from "../../../../shared/modules/layout/empty-state/empty-state.component.ngfactory";
import * as i6 from "../../../../shared/modules/layout/empty-state/empty-state.component";
import * as i7 from "@ngx-translate/core";
import * as i8 from "./navbar-notifications.component";
import * as i9 from "./notification.service";
var styles_NavbarNotificationsComponent = [i0.styles];
var RenderType_NavbarNotificationsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavbarNotificationsComponent, data: {} });
export { RenderType_NavbarNotificationsComponent as RenderType_NavbarNotificationsComponent };
function View_NavbarNotificationsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "notification-badge badge badge-number"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_NavbarNotificationsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["aria-haspopup", "true"], ["class", "notification-btn btn btn-icon dropdown-toggle"], ["id", "dropdownNotification"], ["ngbDropdownToggle", ""]], [[1, "aria-expanded", 0]], [[null, "click"], [null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).dropdown.toggle() !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowUp" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.Home" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.End" === en)) {
        var pd_4 = (i1.ɵnov(_v, 1).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.NgbDropdownToggle, [i2.NgbDropdown, i1.ElementRef], null, null), i1.ɵprd(2048, [[3, 4]], i2.NgbDropdownAnchor, null, [i2.NgbDropdownToggle]), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "notification-btn-icon ph-duotone ph-bell-ringing color-primary"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavbarNotificationsComponent_2)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = (_v.context.$implicit != 0); _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).dropdown.isOpen(); _ck(_v, 0, 0, currVal_0); }); }
function View_NavbarNotificationsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "a", [["class", "notification-item"]], [[2, "notification-item-unread", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNotificationClick(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "d-flex align-items-center justify-content-between color-text-tetritary"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "notification-time"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵppd(4, 1), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h3", [["class", "notification-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [["class", "notification-details color-text-secondary"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = !_v.context.$implicit.isRead; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.creationDate)); _ck(_v, 3, 0, currVal_1); var currVal_2 = _v.context.$implicit.title; _ck(_v, 6, 0, currVal_2); var currVal_3 = _v.context.$implicit.body; _ck(_v, 8, 0, currVal_3); }); }
export function View_NavbarNotificationsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.LongDateTimePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 23, "div", [["ngbDropdown", ""]], [[2, "show", null]], null, null, null, null)), i1.ɵdid(2, 1720320, null, 3, i2.NgbDropdown, [i1.ChangeDetectorRef, i2.NgbDropdownConfig, i3.DOCUMENT, i1.NgZone, i1.ElementRef, i1.Renderer2, [2, i2.NgbNavbar]], { placement: [0, "placement"] }, null), i1.ɵqud(603979776, 1, { _menu: 0 }), i1.ɵqud(603979776, 2, { _menuElement: 0 }), i1.ɵqud(603979776, 3, { _anchor: 0 }), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_NavbarNotificationsComponent_1)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, [[2, 0]], null, 15, "div", [["aria-labelledby", "dropdownNotification"], ["class", "notification-modal"], ["ngbDropdownMenu", ""]], [[2, "dropdown-menu", null], [2, "show", null], [1, "x-placement", 0]], [[null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"], [null, "keydown.Enter"], [null, "keydown.Space"]], function (_v, en, $event) { var ad = true; if (("keydown.ArrowUp" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).dropdown.onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 10).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.Home" === en)) {
        var pd_2 = (i1.ɵnov(_v, 10).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.End" === en)) {
        var pd_3 = (i1.ɵnov(_v, 10).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.Enter" === en)) {
        var pd_4 = (i1.ɵnov(_v, 10).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } if (("keydown.Space" === en)) {
        var pd_5 = (i1.ɵnov(_v, 10).dropdown.onKeyDown($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 16384, [[1, 4]], 1, i2.NgbDropdownMenu, [i2.NgbDropdown], null, null), i1.ɵqud(603979776, 4, { menuItems: 1 }), (_l()(), i1.ɵeld(12, 0, null, null, 12, "app-empty-state", [], null, null, null, i5.View_EmptyStateComponent_0, i5.RenderType_EmptyStateComponent)), i1.ɵdid(13, 49152, null, 0, i6.EmptyStateComponent, [], { subtitle: [0, "subtitle"], assetSrc: [1, "assetSrc"], displayModel: [2, "displayModel"] }, null), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(15, 0, null, 0, 6, "div", [["class", "mx-2 mb-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "h5", [["class", "mt-2 mb-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(17, null, ["", ""])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(19, 0, null, null, 2, "button", [["class", "btn btn-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onMarkAllNotificationsAsReadClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(20, null, [" ", " "])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, 0, 2, null, View_NavbarNotificationsComponent_3)), i1.ɵdid(23, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = "bottom-right"; _ck(_v, 2, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform(_co.notificationService.unreadMessageCount$)); _ck(_v, 7, 0, currVal_2); var currVal_6 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform("APPLICATION.NOTIFICATIONS.noData")); var currVal_7 = "empty_state_notification_messages.svg"; var currVal_8 = _co.notificationService.emptyStateDisplayModel; _ck(_v, 13, 0, currVal_6, currVal_7, currVal_8); var currVal_11 = i1.ɵunv(_v, 23, 0, i1.ɵnov(_v, 24).transform(_co.notificationService.messages$)); _ck(_v, 23, 0, currVal_11); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).isOpen(); _ck(_v, 1, 0, currVal_0); var currVal_3 = true; var currVal_4 = i1.ɵnov(_v, 10).dropdown.isOpen(); var currVal_5 = i1.ɵnov(_v, 10).placement; _ck(_v, 9, 0, currVal_3, currVal_4, currVal_5); var currVal_9 = i1.ɵunv(_v, 17, 0, i1.ɵnov(_v, 18).transform("APPLICATION.NOTIFICATIONS.header")); _ck(_v, 17, 0, currVal_9); var currVal_10 = i1.ɵunv(_v, 20, 0, i1.ɵnov(_v, 21).transform("APPLICATION.NOTIFICATIONS.markAsRead")); _ck(_v, 20, 0, currVal_10); }); }
export function View_NavbarNotificationsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navbar-notifications", [], null, null, null, View_NavbarNotificationsComponent_0, RenderType_NavbarNotificationsComponent)), i1.ɵdid(1, 49152, null, 0, i8.NavbarNotificationsComponent, [i9.NotificationService], null, null)], null, null); }
var NavbarNotificationsComponentNgFactory = i1.ɵccf("app-navbar-notifications", i8.NavbarNotificationsComponent, View_NavbarNotificationsComponent_Host_0, {}, {}, []);
export { NavbarNotificationsComponentNgFactory as NavbarNotificationsComponentNgFactory };
