import { OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
var InputBaseComponent = /** @class */ (function () {
    function InputBaseComponent() {
        this.labelTranslateKey = '';
    }
    InputBaseComponent.prototype.ngOnInit = function () { };
    InputBaseComponent.prototype.isInputControlInvalid = function () {
        return this.inputControl.errors && (this.inputControl.dirty || this.inputControl.touched);
    };
    InputBaseComponent.prototype.isInputControlRequired = function () {
        if (!this.inputControl) {
            throw new Error('inputControl is not defined');
        }
        if (this.inputControl.validator) {
            var validator = this.inputControl.validator({});
            return !!(validator && validator.required);
        }
        return false;
    };
    return InputBaseComponent;
}());
export { InputBaseComponent };
