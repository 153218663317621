import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigurationService } from 'farmcloud-core';
import { ResetPasswordDto } from '../models/reset-password.dto';
import { TokenAuthResultDto } from '../models/token-auth-result.dto';
import { UserCredentials } from '../models/user-credentials.dto';
import { UserRegisterDto } from '../models/user-register.dto';
import { RefreshTokenInputDto } from '../models/refresh-token-input.dto';
import { RemindPasswordDto } from '../models/remind-password.dto';
import { ConfirmEmailInputDto } from '../models/confirm-email-input.model';
import { SendEmailActivationLinkInputDto } from '../models/send-email-activation-link-input.model';
import { ExternalLoginProviderType } from '../models/external-login-provider-type.enum';
import { LoginSocialDto } from '../models/login-social-dto';
import { UserProfileDto } from '@farm-portal/core/auth/models/user-profile-dto';
import { AcceptInvitationDto } from '../models/accept-invitation.dto';
import { InvitationFormDto } from '../models/invitation-form.dto';

@Injectable({
  providedIn: 'root',
})
export class AuthProxyService {
  private readonly baseTokenAuthUrl: string;
  private readonly baseUserAuthUrl: string;
  private readonly baseUserProfileUrl: string;
  private readonly baseInvitationUrl: string;

  constructor(
    private http: HttpClient,
    private httpBackend: HttpBackend,
    config: AppConfigurationService,
  ) {
    this.baseTokenAuthUrl = `${config.configuration.api_url}/api/TokenAuth`;
    this.baseUserAuthUrl = `${config.configuration.api_url}/api/UserAuth`;
    this.baseUserProfileUrl = `${config.configuration.api_url}/api/UserProfile`;
    this.baseInvitationUrl = `${config.configuration.api_url}/api/Invitation`;
  }

  public loginAsl(token: any) {
    const url = `${this.baseTokenAuthUrl}/AslAuthenticate`;
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.append('AslToken', JSON.stringify(token));

    const options = { headers };
    return this.http.post<TokenAuthResultDto>(
      url,
      {
        authProvider: ExternalLoginProviderType.MICROSOFT,
        host: 'farmportal',
      } as LoginSocialDto,
      options,
    );
  }

  public token(tokenRequestDto: UserCredentials): Observable<TokenAuthResultDto> {
    const url = `${this.baseTokenAuthUrl}/Authenticate`;
    return this.http.post<TokenAuthResultDto>(url, tokenRequestDto);
  }

  public getUserProfile(): Observable<UserProfileDto> {
    const url = `${this.baseUserProfileUrl}`;
    return this.http.get<UserProfileDto>(url);
  }

  public refreshToken(refreshTokenDto: RefreshTokenInputDto): Observable<TokenAuthResultDto> {
    const httpClient = new HttpClient(this.httpBackend);
    const url = `${this.baseTokenAuthUrl}/RefreshToken`;
    return httpClient.post<TokenAuthResultDto>(url, refreshTokenDto);
  }

  public register(input: UserRegisterDto): Observable<unknown> {
    const url = `${this.baseUserAuthUrl}/Register`;
    return this.http.post(url, input || {});
  }

  public remindPassword(data: RemindPasswordDto): Observable<unknown> {
    const url = `${this.baseUserAuthUrl}/RemindPassword`;
    return this.http.post(url, data || {});
  }

  public resetPassword(data: ResetPasswordDto) {
    const url = `${this.baseUserAuthUrl}/ResetPassword`;
    return this.http.post(
      url,
      {
        ...data,
      } || {},
    );
  }

  public confirmEmail(input: ConfirmEmailInputDto) {
    const url = `${this.baseUserAuthUrl}/ConfirmEmail`;
    return this.http.post(url, input || {});
  }

  public sendEmailActivationLink$(input: SendEmailActivationLinkInputDto) {
    const url = `${this.baseUserAuthUrl}/SendEmailActivationLink`;
    return this.http.post(url, input || {});
  }

  public acceptInvitation(data: AcceptInvitationDto): Observable<void> {
    const url = `${this.baseInvitationUrl}/AcceptInvitation`;
    return this.http.post<void>(url, data);
  }

  public getInvitation(invitationId: string): Observable<InvitationFormDto> {
    const url = `${this.baseInvitationUrl}/${invitationId}`;
    return this.http.get<InvitationFormDto>(url);
  }
}
