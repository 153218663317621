import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ContactCoreServicesModule } from '../contact-core-services.module';
import { IContactDto } from '../models/contact.dto';
import { ContactProxyService } from '@farm-portal/core/contact/services/contact-proxy.service';

@Injectable({
  providedIn: ContactCoreServicesModule,
})
export class ContactCoreService {
  constructor(private contactProxyService: ContactProxyService) {}

  getOwnerContact(): Observable<IContactDto> {
    return of({
      name: 'AgriSolutions Sp. z o. o.',
      street: undefined,
      buildingNumber: '34',
      roomNumber: undefined,
      city: 'Ligota Wielka',
      postCode: '56-400',
      commune: 'Oleśnica',
      state: 'Powiat oleśnicki',
      county: 'dolnośląskie',
      country: 'Polska',
      herdRegistrationNumber: undefined,
      farmRegistrationNumber: undefined,
      taxNumber: '9112024200',
      vatNumber: undefined,
      regon: undefined,
      hasFarmPortalAccount: undefined,
      locationsJSON: undefined,
      logoSmallImage: 'https://agrisolutions.eu/assets/img/logoagri.png ',
      logoMediumImage: 'https://agrisolutions.eu/assets/img/logoagri.png',
      contactMainEmail: 'kontakt@agrisolutions.eu',
      homepage: 'https://agrisolutions.eu',
    } as IContactDto);
  }

  public sendMessage(message: string): Observable<void> {
    return this.contactProxyService.sendMessage(message);
  }

  public removeAccount(): Observable<void> {
    return this.contactProxyService.removeAccount();
  }
}
