<a
  class="message-btn btn btn-icon"
  *ngIf="messageUnreadService.unreadMessageCount$ | async as unreadMessageCount; let count">
  <i class="message-btn-icon ph-duotone ph-envelope color-primary"></i>
  <span
    class="message-badge badge badge-number"
    *ngIf="count != 0"
    >{{ unreadMessageCount }}</span
  >
</a>
