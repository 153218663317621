import * as tslib_1 from "tslib";
import { AfterViewInit, ElementRef, EventEmitter } from '@angular/core';
import { PureMapService } from '../../common/services/pure-map.service';
var PureMapComponent = /** @class */ (function () {
    function PureMapComponent(mapService) {
        this.mapService = mapService;
        this.height = 500;
        this.tilt = 45;
        this.mapInitialized = new EventEmitter();
    }
    PureMapComponent.prototype.ngAfterViewInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.mapService.initMap(this.mapDiv, this.tilt)];
                    case 1:
                        _a.map = _b.sent();
                        this.mapService.clearMarkers();
                        this.mapService.clearPolygons();
                        this.mapService.clearMapTypes();
                        this.mapInitialized.emit(this.map);
                        return [2 /*return*/];
                }
            });
        });
    };
    return PureMapComponent;
}());
export { PureMapComponent };
