import { HttpClient } from '@angular/common/http';
import { AppConfigurationService } from 'farmcloud-core';
import { UserContextService } from '@farm-portal/core/auth/user-context.service';
import * as i0 from "@angular/core";
import * as i1 from "farmcloud-core";
import * as i2 from "../../auth/user-context.service";
import * as i3 from "@angular/common/http";
var SettingsCoreProxyService = /** @class */ (function () {
    function SettingsCoreProxyService(config, userContextService, httpClient) {
        this.userContextService = userContextService;
        this.httpClient = httpClient;
        this.baseApiUrl = config.configuration.api_url + "/api/PartnerOrganization";
    }
    SettingsCoreProxyService.prototype.getPartnerList = function () {
        var url = this.baseApiUrl + "/GetAllPartners/" + this.userContextService.currentOrganizationId;
        return this.httpClient.get(url);
    };
    SettingsCoreProxyService.prototype.getPartnerEmployees = function (partnerId) {
        //TODO Refactor to use IdP backend
        return this.httpClient.get(this.apiUrl + "/partner/" + partnerId + "/employees");
    };
    SettingsCoreProxyService.prototype.addPartner = function (partnerId) {
        var url = this.baseApiUrl + "/linkExistingOrganization";
        var payload = {
            organizationId: partnerId,
            partnerOrganizationId: this.userContextService.currentOrganizationId,
        };
        return this.httpClient.post(url, payload);
    };
    SettingsCoreProxyService.prototype.removePartner = function (partnerId) {
        var url = this.baseApiUrl + "/unlink";
        var payload = {
            organizationId: partnerId,
            partnerOrganizationId: this.userContextService.currentOrganizationId,
        };
        return this.httpClient.post(url, payload);
    };
    Object.defineProperty(SettingsCoreProxyService.prototype, "apiUrl", {
        //TODO Remove after refactoring `getPartnerEmployees`
        get: function () {
            return this.baseApiUrl + "/" + this.userContextService.currentOrganizationId;
        },
        enumerable: true,
        configurable: true
    });
    SettingsCoreProxyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SettingsCoreProxyService_Factory() { return new SettingsCoreProxyService(i0.ɵɵinject(i1.AppConfigurationService), i0.ɵɵinject(i2.UserContextService), i0.ɵɵinject(i3.HttpClient)); }, token: SettingsCoreProxyService, providedIn: "root" });
    return SettingsCoreProxyService;
}());
export { SettingsCoreProxyService };
