import { EventEmitter } from '@angular/core';
var CancelButtonComponent = /** @class */ (function () {
    function CancelButtonComponent() {
        this.cancelClick = new EventEmitter();
    }
    CancelButtonComponent.prototype.onClick = function () {
        this.cancelClick.emit();
    };
    return CancelButtonComponent;
}());
export { CancelButtonComponent };
