import { Component, Input } from '@angular/core';

import { InputBaseComponent } from '../input.base';

@Component({
  selector: 'app-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./../input.base.scss'],
})
export class InputNumberComponent extends InputBaseComponent {
  @Input() public min: number | null = null;
  @Input() public max: number | null = null;
  @Input() public step: number = 1;
  @Input() public allowDecimals: boolean = true;
  @Input() public onlyPositives: boolean = false;
}
