import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { AppConfigurationService, MessageType } from 'farmcloud-core';
import { UserContextService } from '@farm-portal/core/auth/user-context.service';
import { BaseProxyService } from '@farm-portal/core/common/base-proxy-service';
import * as i0 from "@angular/core";
import * as i1 from "farmcloud-core";
import * as i2 from "../../auth/user-context.service";
import * as i3 from "@angular/common/http";
var MessageCoreProxyService = /** @class */ (function (_super) {
    tslib_1.__extends(MessageCoreProxyService, _super);
    function MessageCoreProxyService(config, userContextService, httpClient) {
        var _this = _super.call(this) || this;
        _this.userContextService = userContextService;
        _this.httpClient = httpClient;
        _this.baseApiUrl = config.configuration.api_url + "/api/messages/v1/message";
        return _this;
    }
    MessageCoreProxyService.prototype.get = function (requestList, isAlertMode) {
        if (!requestList.filter) {
            requestList.filter = [];
        }
        if (isAlertMode) {
            requestList.filter.push({
                field: 'RecipientOrganizationId',
                value: this.userContextService.currentOrganizationId,
                condition: '=',
            });
            requestList.filter.push({
                field: 'Type',
                value: MessageType.Notification,
                condition: '!=',
            });
        }
        var params = _super.prototype.prepareQueryGridifyParams.call(this, requestList);
        return this.httpClient.get("" + this.baseApiUrl, { params: params });
    };
    MessageCoreProxyService.prototype.getAllUnreadMessages = function () {
        var requestList = {
            filter: [
                {
                    field: 'RecipientOrganizationId',
                    value: this.userContextService.currentOrganizationId,
                    condition: '=',
                },
                {
                    field: 'IsRead',
                    value: false,
                    condition: '=',
                },
                {
                    field: 'Type',
                    value: MessageType.Notification,
                    condition: '!=',
                },
            ],
            page: 1,
            pageSize: 20,
        };
        var params = _super.prototype.prepareQueryGridifyParams.call(this, requestList);
        return this.httpClient.get("" + this.baseApiUrl, { params: params });
    };
    MessageCoreProxyService.prototype.getAllNotifications = function (lang) {
        var requestList = {
            filter: [
                {
                    field: 'RecipientOrganizationId',
                    value: this.userContextService.currentOrganizationId,
                    condition: '=',
                },
                {
                    field: 'ThreadId',
                    value: null,
                    condition: '=',
                },
                {
                    field: 'Type',
                    value: MessageType.Notification,
                    condition: '=',
                },
            ],
            orderBy: [
                {
                    field: 'CreationDate',
                    sortOrder: 'desc',
                },
            ],
            page: 1,
            pageSize: 20,
        };
        var params = _super.prototype.prepareQueryGridifyParams.call(this, requestList);
        return this.httpClient.get(this.baseApiUrl + "/" + lang, { params: params });
    };
    MessageCoreProxyService.prototype.createMessage = function (messageCommand) {
        return this.httpClient.post(this.baseApiUrl, messageCommand);
    };
    MessageCoreProxyService.prototype.continueThread = function (messageCommand) {
        var url = "" + this.baseApiUrl + "/continueThread";
        return this.httpClient.post(url, messageCommand);
    };
    MessageCoreProxyService.prototype.markAsRead = function (id) {
        var url = "" + this.baseApiUrl;
        var body = { id: "" + id };
        return this.httpClient.patch(url, body);
    };
    MessageCoreProxyService.prototype.markAllAsRead = function () {
        var url = this.baseApiUrl + "/notifications/markAllAsRead";
        return this.httpClient.patch(url, {});
    };
    MessageCoreProxyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MessageCoreProxyService_Factory() { return new MessageCoreProxyService(i0.ɵɵinject(i1.AppConfigurationService), i0.ɵɵinject(i2.UserContextService), i0.ɵɵinject(i3.HttpClient)); }, token: MessageCoreProxyService, providedIn: "root" });
    return MessageCoreProxyService;
}(BaseProxyService));
export { MessageCoreProxyService };
