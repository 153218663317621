/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pure-map.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./pure-map.component";
import * as i3 from "../../common/services/pure-map.service";
var styles_PureMapComponent = [i0.styles];
var RenderType_PureMapComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PureMapComponent, data: {} });
export { RenderType_PureMapComponent as RenderType_PureMapComponent };
export function View_PureMapComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(671088640, 1, { mapDiv: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["mapDiv", 1]], null, 0, "div", [["id", "map"]], [[4, "height", "px"]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.height; _ck(_v, 1, 0, currVal_0); }); }
export function View_PureMapComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-pure-map", [], null, null, null, View_PureMapComponent_0, RenderType_PureMapComponent)), i1.ɵdid(1, 4243456, null, 0, i2.PureMapComponent, [i3.PureMapService], null, null)], null, null); }
var PureMapComponentNgFactory = i1.ɵccf("app-pure-map", i2.PureMapComponent, View_PureMapComponent_Host_0, { height: "height", tilt: "tilt" }, { mapInitialized: "mapInitialized" }, []);
export { PureMapComponentNgFactory as PureMapComponentNgFactory };
