import { ElementRef } from '@angular/core';
var OnlyPositiveDirective = /** @class */ (function () {
    function OnlyPositiveDirective(el) {
        this.el = el;
        this.onlyPositives = false;
    }
    OnlyPositiveDirective.prototype.onInputChange = function (event) {
        if (!this.onlyPositives) {
            return;
        }
        var inputValue = this.el.nativeElement.value.toString();
        if (inputValue.endsWith(',') || inputValue.endsWith('.')) {
            return;
        }
        var asNumber = Number(inputValue);
        this.el.nativeElement.value = asNumber < 0 ? '0' : inputValue;
        if (asNumber < 0) {
            event.stopPropagation();
        }
    };
    OnlyPositiveDirective.prototype.onKeyDown = function (event) {
        if (!this.onlyPositives) {
            return;
        }
        if (OnlyPositiveDirective.specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        if (event.key === '-') {
            event.preventDefault();
            return;
        }
        var current = this.el.nativeElement.value;
        var next = current.concat(event.key);
        if (next && String(next).match(OnlyPositiveDirective.regex)) {
            event.preventDefault();
            return;
        }
    };
    OnlyPositiveDirective.regex = new RegExp(/^[^0-9]/g);
    OnlyPositiveDirective.specialKeys = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight'];
    return OnlyPositiveDirective;
}());
export { OnlyPositiveDirective };
