import { BehaviorSubject, combineLatest, timer } from 'rxjs';
import { filter, finalize, map, shareReplay, startWith, switchMap, take, tap } from 'rxjs/operators';
import { AppConfigurationService, NotificationType, SpinnerService } from 'farmcloud-core';
import { MessageCoreProxyService } from '@farm-portal/core/message/services/message-core-proxy.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserContextService } from '@farm-portal/core/auth/user-context.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../message/services/message-core-proxy.service";
import * as i2 from "farmcloud-core";
import * as i3 from "@angular/router";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../../auth/user-context.service";
import * as i6 from "../../../message/message-core-services.module";
var NotificationService = /** @class */ (function () {
    function NotificationService(messageCoreProxyService, config, router, spinnerService, translateService, userContextService) {
        var _this = this;
        this.messageCoreProxyService = messageCoreProxyService;
        this.config = config;
        this.router = router;
        this.spinnerService = spinnerService;
        this.translateService = translateService;
        this.userContextService = userContextService;
        this.emptyStateDisplayModel = {
            initialDataLoading: false,
            filtersExists: false,
            anyDataReturned: false,
        };
        this.refresh$ = new BehaviorSubject(null);
        if (!this.config.configuration.messages_enabled) {
            return;
        }
        var langChange$ = this.translateService.onLangChange.pipe(map(function (x) {
            return x.lang;
        }), startWith(this.translateService.currentLang));
        this.messageData$ = combineLatest([
            timer(0, this.config.configuration.messages_refresh_rate),
            this.refresh$,
            langChange$,
            this.userContextService.currentUserOrganization$,
        ]).pipe(filter(function (_a) {
            var currentOrganization = _a[3];
            return !!currentOrganization;
        }), switchMap(function (_a) {
            var lang = _a[2];
            return _this.messageCoreProxyService.getAllNotifications(lang);
        }), shareReplay(1));
        this.messages$ = this.messageData$.pipe(map(function (x) { return x.items; }), tap(function (x) {
            _this.emptyStateDisplayModel = {
                anyDataReturned: x.length > 0,
                filtersExists: false,
                initialDataLoading: false,
            };
        }));
        this.unreadMessageCount$ = this.messageData$.pipe(map(function (x) { return x.items.filter(function (x) { return !x.isRead; }).length; }), map(function (x) {
            if (x > 9) {
                return '9+';
            }
            return x.toString();
        }));
    }
    NotificationService.prototype.markAsRead = function (notification) {
        var _this = this;
        if (notification.isRead) {
            return this.navigateToNotification(notification);
        }
        this.spinnerService.display();
        this.messageCoreProxyService
            .markAsRead(notification.id)
            .pipe(take(1), finalize(function () {
            _this.spinnerService.hide();
        }))
            .subscribe(function (x) {
            _this.refresh$.next(null);
            return _this.navigateToNotification(notification);
        });
    };
    NotificationService.prototype.markAllAsRead = function () {
        var _this = this;
        this.spinnerService.display();
        this.messageCoreProxyService
            .markAllAsRead()
            .pipe(take(1), finalize(function () {
            _this.spinnerService.hide();
        }))
            .subscribe(function () {
            _this.refresh$.next(null);
        });
    };
    NotificationService.prototype.navigateToNotification = function (notification) {
        if (!notification.notificationEntityId) {
            return;
        }
        if (!notification.notificationType) {
            return;
        }
        switch (notification.notificationType) {
            case NotificationType.WeatherStationAlarm:
                return this.router.navigate(['weatherstations', notification.notificationEntityId, 'alarms']);
            case NotificationType.StorageSensorAlarm:
                return this.router.navigate(['storageSensors', notification.notificationEntityId, 'alarms']);
            case NotificationType.DiseaseAlarm:
                return this.router.navigate(['cultivation-assistant', notification.notificationEntityId, 'disease']);
            case NotificationType.PartnerRecomendation:
                return this.router.navigate(['cultivation-assistant', notification.notificationEntityId, 'informations']);
            default:
                throw new Error(notification.notificationType + " is not supported");
        }
    };
    NotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.ɵɵinject(i1.MessageCoreProxyService), i0.ɵɵinject(i2.AppConfigurationService), i0.ɵɵinject(i3.Router), i0.ɵɵinject(i2.SpinnerService), i0.ɵɵinject(i4.TranslateService), i0.ɵɵinject(i5.UserContextService)); }, token: NotificationService, providedIn: i6.MessageCoreServicesModule });
    return NotificationService;
}());
export { NotificationService };
