/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "./cancel-button.component";
var styles_CancelButtonComponent = [];
var RenderType_CancelButtonComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CancelButtonComponent, data: {} });
export { RenderType_CancelButtonComponent as RenderType_CancelButtonComponent };
export function View_CancelButtonComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-secondary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, [" ", "\n"])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("APPLICATION.FORM.cancel")); _ck(_v, 1, 0, currVal_0); }); }
export function View_CancelButtonComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-cancel-button", [], null, null, null, View_CancelButtonComponent_0, RenderType_CancelButtonComponent)), i0.ɵdid(1, 49152, null, 0, i2.CancelButtonComponent, [], null, null)], null, null); }
var CancelButtonComponentNgFactory = i0.ɵccf("app-cancel-button", i2.CancelButtonComponent, View_CancelButtonComponent_Host_0, {}, { cancelClick: "cancelClick" }, []);
export { CancelButtonComponentNgFactory as CancelButtonComponentNgFactory };
