/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./lang-selector.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "farmcloud-core";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "./lang-selector.component";
var styles_LangSelectorComponent = [i0.styles];
var RenderType_LangSelectorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LangSelectorComponent, data: {} });
export { RenderType_LangSelectorComponent as RenderType_LangSelectorComponent };
function View_LangSelectorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "a", [["class", "dropdown-container dropdown-item py-1 lang"], ["href", "javascript:;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeLanguage(_v.context.$implicit.key) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "i", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpad(4, 3), (_l()(), i1.ɵeld(5, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵppd(7, 2), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = _ck(_v, 4, 0, "flag-icon", "flag-icon-item", ("flag-icon-" + _v.context.$implicit.key)); _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 8).transform(i1.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.key, "APPLICATION.LANGUAGES")))); _ck(_v, 6, 0, currVal_1); }); }
export function View_LangSelectorComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i4.EnumTranslateKeyPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 17, "div", [["ngbDropdown", ""]], [[2, "show", null]], null, null, null, null)), i1.ɵdid(2, 1720320, null, 3, i5.NgbDropdown, [i1.ChangeDetectorRef, i5.NgbDropdownConfig, i2.DOCUMENT, i1.NgZone, i1.ElementRef, i1.Renderer2, [2, i5.NgbNavbar]], { placement: [0, "placement"] }, null), i1.ɵqud(603979776, 1, { _menu: 0 }), i1.ɵqud(603979776, 2, { _menuElement: 0 }), i1.ɵqud(603979776, 3, { _anchor: 0 }), (_l()(), i1.ɵeld(6, 0, null, null, 7, "a", [["aria-haspopup", "true"], ["class", "nav-link position-relative dropdown-toggle"], ["id", "dropdownLang"], ["ngbDropdownToggle", ""]], [[1, "aria-expanded", 0]], [[null, "click"], [null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).dropdown.toggle() !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowUp" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 7).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.Home" === en)) {
        var pd_3 = (i1.ɵnov(_v, 7).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.End" === en)) {
        var pd_4 = (i1.ɵnov(_v, 7).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 16384, null, 0, i5.NgbDropdownToggle, [i5.NgbDropdown, i1.ElementRef], null, null), i1.ɵprd(2048, [[3, 4]], i5.NgbDropdownAnchor, null, [i5.NgbDropdownToggle]), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "icon-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "i", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(12, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpad(13, 2), (_l()(), i1.ɵeld(14, 0, [[2, 0]], null, 4, "div", [["aria-labelledby", "dropdownLang"], ["class", "dropdownLang text-left"], ["ngbDropdownMenu", ""]], [[2, "dropdown-menu", null], [2, "show", null], [1, "x-placement", 0]], [[null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"], [null, "keydown.Enter"], [null, "keydown.Space"]], function (_v, en, $event) { var ad = true; if (("keydown.ArrowUp" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).dropdown.onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 15).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.Home" === en)) {
        var pd_2 = (i1.ɵnov(_v, 15).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.End" === en)) {
        var pd_3 = (i1.ɵnov(_v, 15).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.Enter" === en)) {
        var pd_4 = (i1.ɵnov(_v, 15).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } if (("keydown.Space" === en)) {
        var pd_5 = (i1.ɵnov(_v, 15).dropdown.onKeyDown($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 16384, [[1, 4]], 1, i5.NgbDropdownMenu, [i5.NgbDropdown], null, null), i1.ɵqud(603979776, 4, { menuItems: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LangSelectorComponent_1)), i1.ɵdid(18, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "bottom-right"; _ck(_v, 2, 0, currVal_1); var currVal_3 = _ck(_v, 13, 0, "flag-icon", ("flag-icon-" + _co.selectedLanguage.key)); _ck(_v, 12, 0, currVal_3); var currVal_7 = _co.languages; var currVal_8 = _co.trackByFn; _ck(_v, 18, 0, currVal_7, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).isOpen(); _ck(_v, 1, 0, currVal_0); var currVal_2 = i1.ɵnov(_v, 7).dropdown.isOpen(); _ck(_v, 6, 0, currVal_2); var currVal_4 = true; var currVal_5 = i1.ɵnov(_v, 15).dropdown.isOpen(); var currVal_6 = i1.ɵnov(_v, 15).placement; _ck(_v, 14, 0, currVal_4, currVal_5, currVal_6); }); }
export function View_LangSelectorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-lang-selector", [], null, null, null, View_LangSelectorComponent_0, RenderType_LangSelectorComponent)), i1.ɵdid(1, 114688, null, 0, i6.LangSelectorComponent, [i4.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LangSelectorComponentNgFactory = i1.ɵccf("app-lang-selector", i6.LangSelectorComponent, View_LangSelectorComponent_Host_0, {}, {}, []);
export { LangSelectorComponentNgFactory as LangSelectorComponentNgFactory };
