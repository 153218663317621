import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthStorageService {
  protected prefix = 'farmcloud_';

  get size(): Observable<number> {
    return of(localStorage.length);
  }

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  public getItem<T = any>(key: string): Observable<T | null> {
    const data = this.getItemSync(key);
    return of(data);
  }

  public getItemSync<T = any>(key: string): T | null {
    if (!isPlatformBrowser(this.platformId)) {
      return null;
    }

    const unparsedData = localStorage.getItem(`${this.prefix}${key}`);
    if (unparsedData == null) {
      return null;
    }

    return JSON.parse(unparsedData);
  }

  public setItem<T>(key: string, data: T | undefined): Observable<T> {
    return of(this.setItemSync(key, data));
  }

  public setItemSync<T>(key: string, data: T | undefined): T {
    const internalKey = `${this.prefix}${key}`;
    if (data === undefined || data === null) {
      localStorage.removeItem(internalKey);
      return data;
    }

    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(internalKey, JSON.stringify(data));
    }

    return data;
  }

  public clearItem(key: string): Observable<boolean> {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem(`${this.prefix}${key}`);
    }
    return of(true);
  }

  public clearAll(): Observable<boolean> {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.clear();
    }
    return of(true);
  }
}
