import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { scrollToInvalidInput } from '@farm-portal/shared/functions/scroll-to-invalid-input';
import { BehaviorSubject } from 'rxjs';
var FormWideComponent = /** @class */ (function () {
    function FormWideComponent(document) {
        this.document = document;
        this.headerTranslationKey = null;
        this.displayFormButtons = true;
        this.isOneWayForm = false;
        this.navigateBack = new EventEmitter();
        this.onCancelForm = new EventEmitter();
        this.formSubmitted = new EventEmitter();
        this.editModeChanged = new EventEmitter(null);
        this.onInvalidForm = new EventEmitter();
        this.fieldsEnabled$ = new BehaviorSubject(false);
    }
    FormWideComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initialEditMode = this.isEditMode;
        this.fieldsEnabled$.next(this.initialEditMode);
        this.fieldsEnabledChangedSubscription = this.fieldsEnabled$.subscribe(function (fieldsEnabled) {
            _this.checkAndToggleForm(fieldsEnabled);
        });
    };
    FormWideComponent.prototype.ngOnDestroy = function () {
        if (this.fieldsEnabledChangedSubscription) {
            this.fieldsEnabledChangedSubscription.unsubscribe();
        }
    };
    FormWideComponent.prototype.isEditVisible = function () {
        if (this.isEditMode === null || this.isEditMode === undefined) {
            return false;
        }
        return !this.isEditMode;
    };
    FormWideComponent.prototype.checkFields = function () {
        if (this.isEditMode === null || this.isEditMode === undefined) {
            this.fieldsEnabled$.next(true);
            return;
        }
        this.fieldsEnabled$.next(this.isEditMode);
    };
    FormWideComponent.prototype.onEditClick = function () {
        this.isEditMode = !this.isEditMode;
        this.checkFields();
    };
    FormWideComponent.prototype.onCancel = function () {
        if (this.initialEditMode) {
            this.navigateBack.emit();
            return;
        }
        if (this.isEditMode) {
            this.isEditMode = false;
            this.fieldsEnabled$.next(this.isEditMode);
            this.onCancelForm.emit();
            return;
        }
        this.navigateBack.emit();
    };
    FormWideComponent.prototype.onSave = function () {
        if (!this.formGroup.valid) {
            this.formGroup.markAllAsTouched();
            this.onInvalidForm.emit();
            this.setScrollToInvalidInputTimeout();
            return;
        }
        if (this.isEditMode && !this.isOneWayForm) {
            this.isEditMode = false;
            this.fieldsEnabled$.next(this.isEditMode);
        }
        this.formSubmitted.next();
    };
    FormWideComponent.prototype.checkAndToggleForm = function (fieldsEnabled) {
        if (fieldsEnabled) {
            this.formGroup.enable({ emitEvent: false });
            this.editModeChanged.emit(true);
        }
        else {
            this.formGroup.disable({ emitEvent: false });
            this.editModeChanged.emit(false);
        }
    };
    FormWideComponent.prototype.setScrollToInvalidInputTimeout = function () {
        if (this.scrollToInvalidInputTimeout !== null) {
            clearTimeout(this.scrollToInvalidInputTimeout);
            this.scrollToInvalidInputTimeout = null;
        }
        this.scrollToInvalidInputTimeout = scrollToInvalidInput(this.document);
    };
    return FormWideComponent;
}());
export { FormWideComponent };
