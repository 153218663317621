<div class="sidebar d-flex flex-column justify-content-between">
  <div>
    <div class="sidebar-header">
      <div class="logo clearfix">
        <img
          [src]="logoUrl"
          alt="FarmPortal" />
        <a
          class="nav-close d-none"
          id="sidebarClose"
          href="javascript:;">
          <i class="ph ph-x"></i>
        </a>
      </div>
    </div>
    <div class="sidebar-content">
      <div class="nav-container mt-1">
        <div *ngFor="let menuGroup of menuGroups">
          <h2
            class="sidebar-group-title"
            *ngIf="menuGroup.groupTitle && isMenuGroupForComponent(menuGroup)">
            {{ menuGroup.groupTitle | translate }}
          </h2>
          <ul
            class="navigation"
            appSidebarList
            *ngIf="isMenuGroupForComponent(menuGroup)">
            <!-- First level menu -->
            <li
              appSidebarlink
              level="{{ depth + 1 }}"
              (toggleEmit)="handleToggle($event)"
              (click)="toggleSlideInOut()"
              [routePath]="menuItem.path"
              [classes]="menuItem.class"
              [title]="menuItem.title"
              [parent]=""
              *ngFor="let menuItem of menuGroup.routeGroup"
              [ngClass]="{
                'has-sub': menuItem.class === 'has-sub' ? true : false,
                open: activeTitles.includes(menuItem.title)
              }"
              [routerLinkActive]="menuItem.submenu.length != 0 ? '' : 'active'">
              <a
                class="1 hasSubmenuAndHasNotExternalLink"
                appSidebarAnchorToggle
                [routerLink]="menuItem.class === '' ? [menuItem.path] : null"
                *ngIf="hasSubmenuAndHasNotExternalLink(menuItem)">
                <i [ngClass]="[menuItem.icon]"></i>
                <span class="menu-title">{{ menuItem.title | translate }}</span>
                <span
                  *ngIf="menuItem.badge != ''"
                  [ngClass]="[menuItem.badgeClass]"
                  >{{ menuItem.badge }}</span
                >
              </a>
              <a
                [ngClass]="[menuItem.class]"
                class="1 hasNotSubmenuAndExternalLink"
                appSidebarAnchorToggle
                [routerLink]="menuItem.class === '' ? [menuItem.path] : null"
                *ngIf="hasNotSubmenuAndExternalLink(menuItem)">
                <i [ngClass]="[menuItem.icon]"></i>
                <span class="menu-title">{{ menuItem.title | translate }}</span>
                <span
                  *ngIf="menuItem.badge != ''"
                  [ngClass]="[menuItem.badgeClass]"
                  >{{ menuItem.badge }}</span
                >
              </a>
              <a
                class="1 hasMenuExternalLink"
                *ngIf="hasMenuExternalLink(menuItem)"
                [href]="[menuItem.path]"
                target="_blank">
                <i [ngClass]="[menuItem.icon]"></i>
                <span class="menu-title">{{ menuItem.title | translate }}</span>
                <span
                  *ngIf="menuItem.badge != ''"
                  [ngClass]="[menuItem.badgeClass]"
                  >{{ menuItem.badge }}</span
                >
              </a>
              <!-- Second level menu -->
              <ul
                class="menu-content"
                *ngIf="menuItem.submenu.length > 0"
                [@slideInOut]="activeTitles.includes(menuItem.title) ? true : false">
                <li
                  appSidebarlink
                  level="{{ depth + 2 }}"
                  (toggleEmit)="handleToggle($event)"
                  [routePath]="menuSubItem.path"
                  [classes]="menuSubItem.class"
                  [title]="menuSubItem.title"
                  [parent]="menuItem.title"
                  *ngFor="let menuSubItem of menuItem.submenu"
                  [routerLinkActive]="menuSubItem.submenu.length > 0 ? '' : 'active'"
                  [ngClass]="{
                    'has-sub': menuSubItem.class === 'has-sub' ? true : false,
                    open: activeTitles.includes(menuSubItem.title)
                  }">
                  <a
                    appSidebarAnchorToggle
                    class="2 hasSubmenuAndHasNotExternalLink"
                    [routerLink]="null"
                    *ngIf="hasSubmenuAndHasNotExternalLink(menuSubItem)">
                    <i [ngClass]="[menuSubItem.icon]"></i>
                    <span class="menu-title">{{ menuSubItem.title | translate }}</span>
                    <span
                      *ngIf="menuSubItem.badge != ''"
                      [ngClass]="[menuSubItem.badgeClass]"
                      >{{ menuSubItem.badge }}</span
                    >
                  </a>
                  <a
                    appSidebarAnchorToggle
                    [routerLink]="[menuSubItem.path]"
                    class="2 hasNotSubmenuAndExternalLink"
                    *ngIf="hasNotSubmenuAndExternalLink(menuSubItem)">
                    <i [ngClass]="[menuSubItem.icon]"></i>
                    <span class="menu-title">{{ menuSubItem.title | translate }}</span>
                    <span
                      *ngIf="menuSubItem.badge != ''"
                      [ngClass]="[menuSubItem.badgeClass]"
                      >{{ menuSubItem.badge }}</span
                    >
                  </a>
                  <a
                    class="2 hasMenuExternalLink"
                    *ngIf="hasMenuExternalLink(menuSubItem)"
                    [href]="[menuSubItem.path]">
                    <i [ngClass]="[menuSubItem.icon]"></i>
                    <span class="menu-title">{{ menuSubItem.title | translate }}</span>
                    <span
                      *ngIf="menuSubItem.badge != ''"
                      [ngClass]="[menuSubItem.badgeClass]"
                      >{{ menuSubItem.badge }}</span
                    >
                  </a>
                  <!-- Third level menu -->
                  <ul
                    class="menu-content"
                    *ngIf="menuSubItem.submenu.length > 0"
                    [@slideInOut]="activeTitles.includes(menuSubItem.title) ? true : false">
                    <li
                      appSidebarlink
                      level="{{ depth + 3 }}"
                      [routePath]="menuSubsubItem.path"
                      [classes]="menuSubsubItem.class"
                      [title]="menuSubsubItem.title"
                      [parent]="menuSubItem.title"
                      *ngFor="let menuSubsubItem of menuSubItem.submenu"
                      routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      [ngClass]="[menuSubsubItem.class]">
                      <a
                        class="3 not hasMenuExternalLink"
                        appSidebarAnchorToggle
                        [routerLink]="[menuSubsubItem.path]"
                        *ngIf="!hasMenuExternalLink(menuSubsubItem)">
                        <i [ngClass]="[menuSubsubItem.icon]"></i>
                        <span class="menu-title">{{ menuSubsubItem.title | translate }}</span>
                        <span
                          *ngIf="menuSubsubItem.badge != ''"
                          [ngClass]="[menuSubsubItem.badgeClass]"
                          >{{ menuSubsubItem.badge }}</span
                        >
                      </a>
                      <a
                        class="3 hasMenuExternalLink"
                        *ngIf="hasMenuExternalLink(menuSubsubItem)"
                        [href]="[menuSubsubItem.path]">
                        <i [ngClass]="[menuSubsubItem.icon]"></i>
                        <span class="menu-title">{{ menuSubsubItem.title | translate }}</span>
                        <span
                          *ngIf="menuSubsubItem.badge != ''"
                          [ngClass]="[menuSubsubItem.badgeClass]"
                          >{{ menuSubsubItem.badge }}</span
                        >
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="sidebar-footer">
    <div class="user-container">
      <i class="ph ph-user"></i>
    </div>
    <div class="text-container">
      <p class="m-0 sidebar-footer-text text-truncate">{{ user }}</p>
    </div>
    <app-user-nav-item (logout)="logout()"></app-user-nav-item>
  </div>
</div>
