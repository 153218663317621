import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { InputBaseComponent } from '../input.base';
import { FormControl, Validators } from '@angular/forms';
import { filter, take } from 'rxjs/operators';
var InputPhoneNumberComponent = /** @class */ (function (_super) {
    tslib_1.__extends(InputPhoneNumberComponent, _super);
    function InputPhoneNumberComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.phoneNoMask = ['+', '4', '8', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
        _this.phoneNoControl = new FormControl('');
        return _this;
    }
    InputPhoneNumberComponent.prototype.ngOnInit = function () {
        this.setControlValidation();
        this.setPhoneNoValue();
        this.syncControlsValues();
    };
    InputPhoneNumberComponent.prototype.setControlValidation = function () {
        var regex = '^\\+48 [0-9][0-9][0-9]-[0-9][0-9][0-9]-[0-9][0-9][0-9]';
        var phoneValidator = Validators.pattern(regex);
        var validator = this.inputControl.validator;
        if (validator === null) {
            this.phoneNoControl.setValidators([phoneValidator]);
            return;
        }
        this.phoneNoControl.setValidators([validator, phoneValidator]);
    };
    InputPhoneNumberComponent.prototype.setPhoneNoValue = function () {
        var _this = this;
        this.inputControl.valueChanges.pipe(take(1)).subscribe(function (value) {
            _this.phoneNoControl.setValue(value, { emitEvent: false });
        });
    };
    InputPhoneNumberComponent.prototype.syncControlsValues = function () {
        var _this = this;
        this.phoneNoControl.valueChanges.pipe(filter(function () { return _this.phoneNoControl.valid; })).subscribe(function (value) {
            var unformattedValue = _this.formatPhoneNo(value);
            _this.inputControl.setValue(unformattedValue, { emitEvent: false });
        });
    };
    InputPhoneNumberComponent.prototype.formatPhoneNo = function (phoneNumber) {
        return phoneNumber.replace(/\s+/g, ' ');
    };
    InputPhoneNumberComponent.prototype.isInputControlInvalid = function () {
        return this.phoneNoControl.errors && (this.phoneNoControl.dirty || this.phoneNoControl.touched);
    };
    return InputPhoneNumberComponent;
}(InputBaseComponent));
export { InputPhoneNumberComponent };
