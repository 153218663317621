import { AfterViewInit, ChangeDetectorRef, Component, Input } from '@angular/core';

import { InputBaseComponent } from '../input.base';

@Component({
  selector: 'app-input-html',
  templateUrl: './input-html.component.html',
})
export class InputHtmlComponent extends InputBaseComponent implements AfterViewInit {
  constructor(private readonly changeDetRef: ChangeDetectorRef) {
    super();
  }

  public editorModules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: [] }],
      ['clean'],
    ],
  };

  public ngAfterViewInit(): void {
    this.inputControl.valueChanges.subscribe(data => {
      this.changeDetRef.detectChanges();
    });
  }
}
