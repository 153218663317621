import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule, OnDestroy } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutCoreModule } from '@farm-portal/core/layout';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ActivationStart, Router } from '@angular/router';
import { TranslationsLoader } from '@farm-portal/translations-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
  AppConfigurationService,
  AppInitService,
  CommonModule,
  ConfigurationModule,
  DictionariesModule,
  ErrorsHandler,
  LoggerModule,
  NotificationModule,
  SpinnerModule,
  SupportedLanguages,
  TRANSLATE_MODULE_OPTIONS_CONFIG,
  TranslateModuleOptions,
  TranslationModule,
  initAppConfiguration,
  NanDecimalPipe,
  DictionaryPipe,
} from 'farmcloud-core';
import { Subscription } from 'rxjs';
import { AuthModule } from './core/auth/auth.module';
import { ClarityModule } from './core/clarity/clarity.module';
import { TranslationInitializationService } from './translation-initialization.service';

const DEFAULT_TRANSLATE_MODULE_OPTIONS_CONFIG: TranslateModuleOptions = {
  defaultLanguage: SupportedLanguages.PL,
};

export function initializeTranslations(translationInitService: TranslationInitializationService) {
  return (): Promise<any> => {
    return translationInitService.initializeTranslations();
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    AuthModule.forRoot(),
    LayoutCoreModule,
    NotificationModule,
    LoggerModule,
    ConfigurationModule,
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslationsLoader,
        deps: [HttpClient, AppConfigurationService],
      },
    }),
    TranslationModule,
    ClarityModule,
    DictionariesModule,
    SpinnerModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initAppConfiguration,
      deps: [AppInitService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeTranslations,
      deps: [TranslationInitializationService],
      multi: true,
    },
    {
      provide: TRANSLATE_MODULE_OPTIONS_CONFIG,
      useValue: DEFAULT_TRANSLATE_MODULE_OPTIONS_CONFIG,
    },
    {
      provide: ErrorHandler,
      useClass: ErrorsHandler,
    },
    NanDecimalPipe,
    DictionaryPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule implements OnDestroy {
  private routerSub: Subscription;

  constructor(
    private router: Router,
    private readonly titleService: Title,
  ) {
    //set title of each route that has data title provided
    this.routerSub = this.router.events.subscribe(data => {
      if (data instanceof ActivationStart && data.snapshot.data['title']) {
        const title = data.snapshot.data['title'];
        this.titleService.setTitle(`${title} - FarmPortal`);
      }
    });
  }

  ngOnDestroy() {
    if (this.routerSub) {
      this.routerSub.unsubscribe();
    }
  }
}
