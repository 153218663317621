import { Component, Input } from '@angular/core';

import { InputBaseComponent } from '../input.base';

@Component({
  selector: 'app-input-email',
  templateUrl: './input-email.component.html',
  styleUrls: ['./../input.base.scss'],
})
export class InputEmailComponent extends InputBaseComponent {
  @Input() IsReadOnly: boolean = false;
}
