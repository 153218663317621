/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ngbd-modal-confirm.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "farmcloud-core";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./ngbd-modal-confirm.component";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
var styles_NgbdModalConfirmComponent = [i0.styles];
var RenderType_NgbdModalConfirmComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NgbdModalConfirmComponent, data: {} });
export { RenderType_NgbdModalConfirmComponent as RenderType_NgbdModalConfirmComponent };
export function View_NgbdModalConfirmComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i2.FormatPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h4", [["class", "modal-title"], ["id", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "button", [["aria-describedby", "modal-title"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modal.dismiss(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(11, 2), (_l()(), i1.ɵeld(12, 0, null, null, 6, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "button", [["class", "btn btn-secondary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modal.dismiss(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(14, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(16, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modal.close(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(17, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.titleTranslateKey)); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 9, 0, _ck(_v, 11, 0, i1.ɵnov(_v, 0), i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform(_co.messageTranslateKey)), _co.values)); _ck(_v, 9, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform("APPLICATION.MODAL.cancel")); _ck(_v, 14, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 17, 0, i1.ɵnov(_v, 18).transform("APPLICATION.MODAL.confirm")); _ck(_v, 17, 0, currVal_3); }); }
export function View_NgbdModalConfirmComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ngbd-modal-confirm", [], null, null, null, View_NgbdModalConfirmComponent_0, RenderType_NgbdModalConfirmComponent)), i1.ɵdid(1, 49152, null, 0, i4.NgbdModalConfirmComponent, [i5.NgbActiveModal], null, null)], null, null); }
var NgbdModalConfirmComponentNgFactory = i1.ɵccf("app-ngbd-modal-confirm", i4.NgbdModalConfirmComponent, View_NgbdModalConfirmComponent_Host_0, { titleTranslateKey: "titleTranslateKey", messageTranslateKey: "messageTranslateKey", values: "values" }, {}, []);
export { NgbdModalConfirmComponentNgFactory as NgbdModalConfirmComponentNgFactory };
