import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SettingsCoreProxyService } from '@farm-portal/core/settings/services/settings-core-proxy.service';
import { finalize, map, tap } from 'rxjs/operators';
import { SpinnerService } from 'farmcloud-core';
import { FormBuilder, Validators } from '@angular/forms';
var NgbdModalShareComponent = /** @class */ (function () {
    function NgbdModalShareComponent(modal, settingsService, spinner, fb) {
        var _this = this;
        this.modal = modal;
        this.settingsService = settingsService;
        this.spinner = spinner;
        this.fb = fb;
        this.partnerForm = this.fb.group({
            partners: [null, Validators.required],
        });
        this.partnerOptions$ = this.settingsService.getPartnerList().pipe(tap(function () { return _this.spinner.display(); }), map(function (options) {
            return options.items.map(function (option) { return ({ value: option.id, label: option.name }); });
        }), finalize(function () { return _this.spinner.hide(); }));
    }
    Object.defineProperty(NgbdModalShareComponent.prototype, "canConfirm", {
        get: function () {
            return this.getFromControl('partners').valid;
        },
        enumerable: true,
        configurable: true
    });
    NgbdModalShareComponent.prototype.getFromControl = function (name) {
        return this.partnerForm.get(name);
    };
    NgbdModalShareComponent.prototype.onClose = function () {
        this.modal.close(this.getFromControl('partners').value);
    };
    return NgbdModalShareComponent;
}());
export { NgbdModalShareComponent };
