import * as tslib_1 from "tslib";
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { ShortDatePipe } from 'farmcloud-core';
var CustomDateParserFormatter = /** @class */ (function (_super) {
    tslib_1.__extends(CustomDateParserFormatter, _super);
    function CustomDateParserFormatter(shortDatePipe) {
        var _this = _super.call(this) || this;
        _this.shortDatePipe = shortDatePipe;
        return _this;
    }
    CustomDateParserFormatter.prototype.parse = function (value) {
        if (!value) {
            return null;
        }
        var dateParts = value.trim().split('.');
        if (dateParts.length !== 3) {
            return null;
        }
        return { year: +dateParts[2], month: +dateParts[1], day: +dateParts[0] };
    };
    CustomDateParserFormatter.prototype.format = function (date) {
        if (date === null) {
            return '';
        }
        var dateObject = new Date(date.year, date.month - 1, date.day);
        return this.shortDatePipe.transform(dateObject);
    };
    return CustomDateParserFormatter;
}(NgbDateParserFormatter));
export { CustomDateParserFormatter };
