import * as tslib_1 from "tslib";
import { InputBaseComponent } from '../input.base';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
var InputDatepickerComponent = /** @class */ (function (_super) {
    tslib_1.__extends(InputDatepickerComponent, _super);
    function InputDatepickerComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    InputDatepickerComponent.ngbDateToString = function (date) {
        if (!date) {
            return null;
        }
        return InputDatepickerComponent.ngbDateToDate(date).toUTCString();
    };
    InputDatepickerComponent.ngbDateToDate = function (date) {
        if (!date) {
            return null;
        }
        // NgbDate starts the month index  from 1
        return new Date(date.year, date.month - 1, date.day, 0, 0);
    };
    InputDatepickerComponent.stringToNgbDate = function (value) {
        if (!value) {
            return null;
        }
        if (value.length === 0) {
            return null;
        }
        // Date's getMonth() method returns the month index starting from 0 (January)
        // NgbDate expects the month index starting from 1
        var date = new Date(value);
        return new NgbDate(date.getUTCFullYear(), date.getUTCMonth() + 1, date.getUTCDate());
    };
    return InputDatepickerComponent;
}(InputBaseComponent));
export { InputDatepickerComponent };
