import { Routes } from '@angular/router';
import { FullLayoutComponent } from '@farm-portal/core/layout';
import { SiteTitle } from './app-site-titles.enum';
import { IdpAuthGuard } from './core/auth/idp-auth.guard';
import { IdpOrganizationGuard } from './core/auth/idp-organization.guard';
import { LoadUserDataComponent } from './core/layout/load-user-data/load-user-data.component';
import { IdpRucipGuard } from '@farm-portal/core/auth/idp-rucip.guard';
var ɵ0 = {
    title: SiteTitle.contact,
}, ɵ1 = {
    title: SiteTitle.farm,
}, ɵ2 = {
    title: SiteTitle.carbonAssistant,
}, ɵ3 = {
    title: SiteTitle.contracts,
}, ɵ4 = {
    title: SiteTitle.myFields,
}, ɵ5 = {
    title: SiteTitle.cultivationAssistant,
}, ɵ6 = {
    title: SiteTitle.buildings,
}, ɵ7 = {
    title: SiteTitle.employees,
}, ɵ8 = {
    title: SiteTitle.machines,
}, ɵ9 = {
    title: SiteTitle.observations,
}, ɵ10 = {
    title: SiteTitle.rucip,
}, ɵ11 = {
    title: SiteTitle.fertilizerStockNeeds,
}, ɵ12 = {
    title: SiteTitle.weatherStations,
}, ɵ13 = {
    title: SiteTitle.storageSensors,
}, ɵ14 = {
    title: SiteTitle.devices,
}, ɵ15 = {
    title: SiteTitle.messages,
}, ɵ16 = {
    title: SiteTitle.monitoring,
}, ɵ17 = {
    title: SiteTitle.monitoring,
}, ɵ18 = {
    title: SiteTitle.fertilizersDb,
}, ɵ19 = {
    title: SiteTitle.pesticides,
}, ɵ20 = {
    title: SiteTitle.documents,
}, ɵ21 = {
    title: SiteTitle.settings,
}, ɵ22 = {
    title: SiteTitle.stockOffers,
}, ɵ23 = {
    title: SiteTitle.suppliers,
}, ɵ24 = {
    title: SiteTitle.emissionCalculations,
}, ɵ25 = {
    title: SiteTitle.warehousesProductStocks,
}, ɵ26 = {
    title: SiteTitle.soilAnalysis,
}, ɵ27 = {
    title: SiteTitle.satelliteImages,
}, ɵ28 = {
    title: SiteTitle.treatments,
};
var appRoutes = [
    {
        path: 'auth',
        loadChildren: './modules/authorization/authorization.module#AuthorizationModule',
    },
    {
        path: 'load-user-data',
        component: LoadUserDataComponent,
        canActivate: [IdpAuthGuard],
    },
    {
        path: '',
        component: FullLayoutComponent,
        children: [
            {
                path: '',
                redirectTo: 'my-fields',
                pathMatch: 'full',
            },
            {
                path: 'contact',
                loadChildren: './modules/contact/contact.module#ContactModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
                data: ɵ0,
            },
            {
                path: 'farm',
                loadChildren: './modules/farm/farm.module#FarmModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
                data: ɵ1,
            },
            {
                path: 'carbon-assistant',
                loadChildren: './modules/carbon-assistant/carbon-assistant.module#CarbonAssistantModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
                data: ɵ2,
            },
            {
                path: 'contracts',
                loadChildren: './modules/contracts/contracts.module#ContractsModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
                data: ɵ3,
            },
            {
                path: 'my-fields',
                loadChildren: './modules/ground/ground.module#GroundModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
                data: ɵ4,
            },
            {
                path: 'cultivation-assistant',
                loadChildren: './modules/cultivation-assistant/cultivation-assistant.module#CultivationAssistantModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
                data: ɵ5,
            },
            {
                path: 'buildings',
                loadChildren: './modules/buildings/buildings.module#BuildingsModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
                data: ɵ6,
            },
            {
                path: 'employees',
                loadChildren: './modules/employee/employee.module#EmployeeModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
                data: ɵ7,
            },
            {
                path: 'machines',
                loadChildren: './modules/machine/machine.module#MachineModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
                data: ɵ8,
            },
            {
                path: 'observations',
                loadChildren: './modules/observation/observation.module#ObservationModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
                data: ɵ9,
            },
            {
                path: 'rucip',
                loadChildren: './modules/rucip-observation/rucip.module#RucipModule',
                canLoad: [IdpAuthGuard, IdpRucipGuard],
                canActivateChild: [IdpOrganizationGuard],
                data: ɵ10,
            },
            {
                path: 'fertilizer-stock-needs',
                loadChildren: './modules/fertilizer-stock-needs/fertilizer-stock-needs.module#FertilizerStockNeedsModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
                data: ɵ11,
            },
            {
                path: 'weatherstations',
                loadChildren: './modules/weather-station/weather-station.module#WeatherStationModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
                data: ɵ12,
            },
            {
                path: "storageSensors",
                loadChildren: './modules/storage-sensors/storage-sensors.module#StorageSensorsModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
                data: ɵ13,
            },
            {
                path: "devices",
                loadChildren: './modules/devices/devices.module#DevicesModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
                data: ɵ14,
            },
            {
                path: 'messages',
                loadChildren: './modules/message/message.module#MessageModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
                data: ɵ15,
            },
            {
                path: 'monitoring',
                loadChildren: './modules/monitoring/monitoring.module#MonitoringModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
                data: ɵ16,
            },
            {
                path: 'vehtel-monitoring',
                loadChildren: './modules/vehtel-monitoring/vehtel-monitoring.module#VehtelMonitoringModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
                data: ɵ17,
            },
            {
                path: 'report',
                loadChildren: './modules/report/report.module#ReportModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
            },
            {
                path: 'fertilizers-db',
                loadChildren: './modules/fertilizer-db/fertilizer-db.module#FertilizerDbModule',
                data: ɵ18,
            },
            {
                path: 'pesticides',
                loadChildren: './modules/pesticide/pesticide.module#PesticideModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
                data: ɵ19,
            },
            {
                path: 'documents',
                loadChildren: './modules/documents/documents.module#DocumentsModule',
                canActivate: [IdpAuthGuard, IdpOrganizationGuard],
                data: ɵ20,
            },
            {
                path: 'settings',
                loadChildren: './modules/settings/settings.module#SettingsModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
                data: ɵ21,
            },
            {
                path: 'stock-offers',
                loadChildren: './modules/stock-offer/stock-offer.module#StockOfferModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
                data: ɵ22,
            },
            {
                path: 'suppliers',
                loadChildren: './modules/supplier/supplier.module#SupplierModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
                data: ɵ23,
            },
            {
                path: 'emission-calculations',
                loadChildren: './modules/emission-calculation/emission-calculation.module#EmissionCalculationModule',
                data: ɵ24,
            },
            {
                path: 'warehouses',
                loadChildren: './modules/warehouse/warehouse.module#WarehouseModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
                data: ɵ25,
            },
            {
                path: 'soil-analysis',
                loadChildren: './modules/soil-analysis/soil-analysis.module#SoilAnalysisModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
                data: ɵ26,
            },
            {
                path: 'satellite-images',
                loadChildren: './modules/satellite-images/satellite-images.module#SatelliteImagesModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
                data: ɵ27,
            },
            {
                path: 'treatments',
                loadChildren: './modules/treatments/treatments.module#TreatmentsModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
                data: ɵ28,
            },
            {
                path: '**',
                redirectTo: 'my-fields',
            },
        ],
        canActivateChild: [IdpOrganizationGuard],
    },
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22, ɵ23, ɵ24, ɵ25, ɵ26, ɵ27, ɵ28 };
