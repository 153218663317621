import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot, CanLoad, Route, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthStoreService } from './services/auth-store.service';

@Injectable()
export class IdpRucipGuard implements CanActivate, CanLoad {
  constructor(
    private router: Router,
    private authStore: AuthStoreService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> | Observable<boolean> {
    return this.getRucipGuardResult();
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean | Observable<boolean> | Promise<boolean> {
    return this.getRucipGuardResult();
  }

  private getRucipGuardResult(): boolean {
    const claims = this.authStore.getUserClaims;

    const canEnterRucip = claims.some(claim => claim.startsWith('Organization.Partners.PFZ'));
    if (!canEnterRucip) {
      this.router.navigate(['my-fields']);
    }

    return true;
  }
}
