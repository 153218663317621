import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormComponent } from '@farm-portal/shared/modules/form-inputs/form/form.component';
import { FileFormControlComponent } from '@farm-portal/shared/modules/form-inputs/inputs/file-form-control/file-form-control.component';
import { InputHtmlComponent } from '@farm-portal/shared/modules/form-inputs/inputs/input-html/input-html.component';
import { FieldsetDisabledDirective } from '@farm-portal/shared/modules/form-inputs/inputs/input-ng-select/fieldset-disabled.directive';
import { OnlyDecimalDirective } from '@farm-portal/shared/modules/form-inputs/inputs/input-number/only-decimal.directive';
import { InputPhoneNumberComponent } from '@farm-portal/shared/modules/form-inputs/inputs/input-phone-number/input-phone-number.component';
import { ValidationMessageComponent } from '@farm-portal/shared/modules/form-inputs/inputs/validation-message/validation-message.component';
import { PureGoogleMapsSharedModule } from '@farm-portal/shared/modules/layout/pure-google-maps/pure-google-maps-shared.module';
import { NgbDateParserFormatter, NgbDatepickerModule, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { LightboxModule } from '@ngx-gallery/lightbox';
import { TranslateModule } from '@ngx-translate/core';
import { TextMaskModule } from 'angular2-text-mask';
import { PipesModule, ShortDatePipe } from 'farmcloud-core';
import { NgxImageCompressService } from 'ngx-image-compress';
import { QuillModule } from 'ngx-quill';
import { CancelButtonComponent } from './buttons/cancel-button/cancel-button.component';
import { FormButtonsComponent } from './buttons/form-buttons/form-buttons.component';
import { SubmitButtonComponent } from './buttons/submit-button/submit-button.component';
import { FormWideComponent } from './form-wide/form-wide.component';
import { InputCheckboxComponent } from './inputs/input-checkbox/input-checkbox.component';
import { CustomDateParserFormatter } from './inputs/input-datepicker/customDateParserFormatter';
import { InputDatepickerComponent } from './inputs/input-datepicker/input-datepicker.component';
import { DateTimePickerControlComponent } from './inputs/input-datetime-picker/component/datetime-picker-control.component';
import { InputDateTimePickerComponent } from './inputs/input-datetime-picker/input-datetime-picker.component';
import { InputEmailComponent } from './inputs/input-email/input-email.component';
import { InputMapComponent } from './inputs/input-map/input-map.component';
import { InputNgSelectComponent } from './inputs/input-ng-select/input-ng-select.component';
import { InputNumberComponent } from './inputs/input-number/input-number.component';
import { OnlyPositiveDirective } from './inputs/input-number/only-positive.directive';
import { InputPasswordComponent } from './inputs/input-password/input-password.component';
import { InputRadioButtonGroupComponent } from './inputs/input-radio-button-group/input-radio-button-group.component';
import { InpuRangeComponent } from './inputs/input-range/input-range.component';
import { InputSelectComponent } from './inputs/input-select/input-select.component';
import { InputTextComponent } from './inputs/input-text/input-text.component';
import { InputTextareaComponent } from './inputs/input-textarea/input-textarea.component';
import { InputTimepickerComponent } from './inputs/input-timepicker/input-timepicker.component';
import { InputBaseComponent } from './inputs/input.base';

const inputs = [
  InputDatepickerComponent,
  InputCheckboxComponent,
  InputEmailComponent,
  InputNgSelectComponent,
  FieldsetDisabledDirective,
  InputNumberComponent,
  InputPasswordComponent,
  InputSelectComponent,
  InputTextComponent,
  InputTextareaComponent,
  InputHtmlComponent,
  InputPhoneNumberComponent,
  InpuRangeComponent,
  InputTimepickerComponent,
  InputMapComponent,
  InputDateTimePickerComponent,
  InputRadioButtonGroupComponent,
  FileFormControlComponent,
];

const buttons = [SubmitButtonComponent, CancelButtonComponent, FormButtonsComponent];
const directives = [OnlyDecimalDirective, OnlyPositiveDirective];

@NgModule({
  declarations: [
    InputBaseComponent,
    FormComponent,
    FormWideComponent,
    DateTimePickerControlComponent,
    ValidationMessageComponent,
    ...inputs,
    ...buttons,
    ...directives,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgSelectModule,
    ReactiveFormsModule,
    TextMaskModule,
    QuillModule,
    TranslateModule.forChild(),
    PureGoogleMapsSharedModule,
    PipesModule,
    LightboxModule.withConfig({
      keyboardShortcuts: false,
      panelClass: 'fullscreen',
    }),
    QuillModule.forRoot(),
  ],
  exports: [...inputs, ...buttons, ValidationMessageComponent, FormComponent, FormWideComponent, OnlyDecimalDirective],
  providers: [{ provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }, ShortDatePipe, NgxImageCompressService],
})
export class FormInputsModule {}
