import { HttpClient } from '@angular/common/http';
import { AppConfigurationService } from 'farmcloud-core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "farmcloud-core";
var ContactProxyService = /** @class */ (function () {
    function ContactProxyService(httpClient, config) {
        this.httpClient = httpClient;
        this.config = config;
        this.url = this.config.configuration.api_url + "/api/ContactForm";
    }
    ContactProxyService.prototype.sendMessage = function (message) {
        return this.httpClient.post(this.url + "/message", { message: message });
    };
    ContactProxyService.prototype.removeAccount = function () {
        return this.httpClient.post(this.url + "/removeAccount", {});
    };
    ContactProxyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ContactProxyService_Factory() { return new ContactProxyService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AppConfigurationService)); }, token: ContactProxyService, providedIn: "root" });
    return ContactProxyService;
}());
export { ContactProxyService };
