import { ChangeDetectorRef, ElementRef, EventEmitter, OnInit, ViewRef, OnDestroy, } from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, FormControl, FormGroup } from '@angular/forms';
import { catchError, map } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { Gallery, ImageItem } from '@ngx-gallery/core';
import { Lightbox } from '@ngx-gallery/lightbox';
import { FileUtilService } from 'farmcloud-core';
import { FileFormService } from './services/file-form.service';
import { ImageCompressionLogicService } from './services/image-compression-logic.service';
import { createImage, resizeImage } from './image.utils';
var FileFormControlComponent = /** @class */ (function () {
    function FileFormControlComponent(changeDetector, fileUtilService, fileFormService, imageCompressionService, lightbox, gallery, controlContainer) {
        this.changeDetector = changeDetector;
        this.fileUtilService = fileUtilService;
        this.fileFormService = fileFormService;
        this.imageCompressionService = imageCompressionService;
        this.lightbox = lightbox;
        this.gallery = gallery;
        this.controlContainer = controlContainer;
        this.isDraggingOverDropZone = false;
        this.isFile = false;
        this.isRequired = false;
        this.blur = new EventEmitter();
        this.change = new EventEmitter();
        this.focus = new EventEmitter();
        this.thumbnailWidth = 195;
        this.thumbnailHeight = 195;
        this.noImageFile = false;
        this.isDisabled = false;
        this.allowedImageTypes = ['jpg', 'jpeg', 'png', 'svg', 'jfif', 'gif', 'bmp'];
        this.imageCompressionOptions = {
            width: 1920,
            height: 1080,
            quality: 85,
        };
        this.onChange = function (_) { };
        this.onTouched = function () { };
    }
    Object.defineProperty(FileFormControlComponent.prototype, "label", {
        get: function () {
            return this._label;
        },
        set: function (value) {
            this._label = value || 'APPLICATION.FORM.addImage';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FileFormControlComponent.prototype, "formControlName", {
        get: function () {
            return this._formControlName;
        },
        set: function (value) {
            this._formControlName = value;
            this.checkIsRequired();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FileFormControlComponent.prototype, "formControl", {
        get: function () {
            var control = this.formGroup.get(this.formControlName);
            if (!control)
                return null;
            return control;
        },
        enumerable: true,
        configurable: true
    });
    FileFormControlComponent.prototype.ngOnDestroy = function () {
        if (this.compressImageSubscription) {
            this.compressImageSubscription.unsubscribe();
        }
    };
    FileFormControlComponent.prototype.ngOnInit = function () { };
    Object.defineProperty(FileFormControlComponent.prototype, "imageThumbnail", {
        get: function () {
            return this._imageThumbnail;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FileFormControlComponent.prototype, "imageName", {
        get: function () {
            return this._imageFile.rootFileName ? this._imageFile.rootFileName : this._imageFile.name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FileFormControlComponent.prototype, "imageFile", {
        set: function (value) {
            this._imageFile = value;
            this.onChange(this._imageFile);
            this.onTouched();
            this.initImageThumbnail();
        },
        enumerable: true,
        configurable: true
    });
    FileFormControlComponent.prototype.writeValue = function (value) {
        this.imageFile = value;
        this.initImageThumbnail();
    };
    FileFormControlComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    FileFormControlComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    FileFormControlComponent.prototype.onImageClicked = function () {
        if (!this.readonly) {
            this.fileInputElement.nativeElement.click();
        }
    };
    FileFormControlComponent.prototype.onFileChanged = function () {
        this.dismissError();
        var file = this.fileInputElement.nativeElement.files[0];
        if (!file) {
            return;
        }
        this.setFile(file);
    };
    FileFormControlComponent.prototype.openLightbox = function () {
        this.initGallery();
        this.lightbox.open(0, this.galleryId);
    };
    FileFormControlComponent.prototype.validate = function (file) {
        if (this.accept && this.accept.length > 0 && !this.accept.some(function (allowedType) { return file.type === allowedType; })) {
            this.errorMessageTranslationKey = 'APPLICATION.VALIDATORS.fileAcceptFormat';
            this.errorMessageInfo = '';
            return false;
        }
        if (this.maxSize && file.size > this.maxSize * 1024 * 1024) {
            this.errorMessageTranslationKey = 'APPLICATION.VALIDATORS.fileMaxSize';
            this.errorMessageInfo = this.maxSize + " MB";
            return false;
        }
        return true;
    };
    FileFormControlComponent.prototype.checkIsRequired = function () {
        this.isRequired = this.isFormControlRequired(this.formControlName);
    };
    FileFormControlComponent.prototype.isFormControlRequired = function (controlName) {
        var formControl = this.controlContainer.control.get(controlName);
        if (!formControl || !formControl.validator)
            return false;
        var validator = formControl.validator({});
        if (validator && validator.required)
            return true;
        return false;
    };
    Object.defineProperty(FileFormControlComponent.prototype, "formGroup", {
        get: function () {
            return this.controlContainer.control;
        },
        enumerable: true,
        configurable: true
    });
    FileFormControlComponent.prototype.setFile = function (file) {
        var _this = this;
        if (this.validate(file)) {
            if (this.fileFormService.isImageFile(file, this.allowedImageTypes)) {
                this.compressImageSubscription = this.imageCompressionService
                    .compressImageToRatio(file, this.imageCompressionOptions)
                    .pipe(map(function (resultFile) {
                    if (!resultFile) {
                        return;
                    }
                    _this.imageFile = {
                        name: file.name,
                        file: resultFile,
                    };
                }), catchError(function (err) {
                    _this.imageFile = undefined;
                    return EMPTY;
                }))
                    .subscribe();
            }
            else {
                this.imageFile = {
                    name: file.name,
                    file: file,
                };
            }
        }
        else {
            this.imageFile = undefined;
        }
    };
    FileFormControlComponent.prototype.setImageThumbnailFromFile = function (file) {
        var _this = this;
        var result = null;
        var res = this.fileFormService.setImageThumbnailFromFile(file, this.allowedImageTypes);
        this.noImageFile = res.noImageFile;
        result = {
            file: res.file,
            url: res.url,
        };
        this.resize(result).then(function (r) {
            _this._imageThumbnail = r.resized.dataURL;
            _this.origImageWidth = r.width;
            _this.orgiImageHeight = r.height;
            _this.isFile = true;
            if (_this.changeDetector && !_this.changeDetector.destroyed) {
                _this.changeDetector.detectChanges();
            }
        });
    };
    FileFormControlComponent.prototype.setImageThumbnailFromUrl = function (file) {
        var result = this.fileFormService.setImageThumbnailFromUrl(file, this.allowedImageTypes);
        this._imageThumbnail = result.url;
        this.noImageFile = result.noImageFile;
        this.isFile = true;
    };
    FileFormControlComponent.prototype.initImageThumbnail = function () {
        if (this._imageFile) {
            if (this._imageFile.link != null) {
                this.setImageThumbnailFromUrl(this._imageFile);
            }
            else if (this._imageFile.file != null) {
                this.setImageThumbnailFromFile(this._imageFile.file);
            }
        }
        else {
            this._imageThumbnail = undefined;
            this.isFile = false;
        }
    };
    FileFormControlComponent.prototype.downloadFile = function () {
        if (this._imageFile.link) {
            this.fileUtilService.downloadFileWithNameAsync(this._imageFile.link, this.imageName);
        }
    };
    FileFormControlComponent.prototype.removeImage = function () {
        this.fileInputElement.nativeElement.value = null;
        this.imageFile = undefined;
    };
    FileFormControlComponent.prototype.drop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        this.dismissError();
        if (!e.dataTransfer || !e.dataTransfer.files.length) {
            return;
        }
        this.setFile(e.dataTransfer.files[0]);
        this.updateDragOverlayStyles(false);
    };
    FileFormControlComponent.prototype.dragenter = function (e) {
        e.preventDefault();
        e.stopPropagation();
    };
    FileFormControlComponent.prototype.dragover = function (e) {
        e.preventDefault();
        e.stopPropagation();
        this.updateDragOverlayStyles(true);
    };
    FileFormControlComponent.prototype.dragleave = function (e) {
        e.preventDefault();
        e.stopPropagation();
        this.updateDragOverlayStyles(false);
    };
    FileFormControlComponent.prototype.updateDragOverlayStyles = function (isDragOver) {
        this.isDraggingOverDropZone = isDragOver;
    };
    FileFormControlComponent.prototype.resize = function (result) {
        var _this = this;
        var resizeOptions = {
            resizeHeight: this.thumbnailHeight,
            resizeWidth: this.thumbnailWidth,
            resizeType: result.file.type,
            resizeMode: 'fit',
        };
        return new Promise(function (resolve) {
            createImage(result.url, function (image) {
                var dataUrl = resizeImage(image, resizeOptions);
                result.width = image.width;
                result.height = image.height;
                result.resized = {
                    dataURL: dataUrl,
                    type: _this.getType(dataUrl),
                };
                resolve(result);
            });
        });
    };
    FileFormControlComponent.prototype.dismissError = function () {
        this.errorMessageInfo = undefined;
        this.errorMessageTranslationKey = undefined;
    };
    FileFormControlComponent.prototype.getType = function (dataUrl) {
        return dataUrl.match(/:(.+\/.+;)/)[1];
    };
    FileFormControlComponent.prototype.initGallery = function () {
        this.galleryId = "" + this.imageName;
        var items = [];
        var item = new ImageItem({
            src: this._imageFile.link ? this._imageFile.link : URL.createObjectURL(this._imageFile.file),
            thumb: this._imageThumbnail,
        });
        items.push(item);
        var galleryRef = this.gallery.ref(this.galleryId);
        galleryRef.load(items);
    };
    FileFormControlComponent.prototype.setDisabledState = function (isDisabled) {
        this.isDisabled = isDisabled;
    };
    return FileFormControlComponent;
}());
export { FileFormControlComponent };
